import { useState } from 'react';
import { useSelector } from 'react-redux';
import http from 'app/core/engine-service';
import { selectUserId } from 'app/state/user/selectors';
import { nullMeta, parseMetaFromResponse } from 'app/helpers/meta';
import { groupItemsByDate } from 'app/helpers/utils';

export default function useMissedCalls() {
  const userId = useSelector(selectUserId);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [missedCalls, setMissedCalls] = useState([]);
  const [meta, setMeta] = useState(nullMeta);
  const [params, setParams] = useState({
    page: 1,
    per_page: 20,
    user_id: userId,
    interaction_type: 'missed_call',
  });

  const fetchMissedCalls = (requestParams = {}) => {
    const newParams = { ...params, ...requestParams };
    setIsLoading(true);

    return http.get('/interactions', newParams).then((response) => {
      setIsLoading(false);
      setParams(newParams);
      setMeta(parseMetaFromResponse(response.meta));

      const newMissedCalls = response.interactions;

      setMissedCalls((prevMissedCalls) =>
        newParams.page > 1 ? [...prevMissedCalls, ...newMissedCalls] : newMissedCalls
      );

      return response.interactions;
    });
  };

  const clearTexts = () => setMissedCalls([]);

  const confirmMissedCall = (id) => {
    return http.patch(`/activities/${id}`, { activity: { confirmed: true } });
  };

  const confirmAllMissedCalls = () => {
    setIsConfirming(true);

    return http.post('/clear_call_notifications').then(() => setIsConfirming(false));
  };

  return {
    isLoading,
    isConfirming,
    missedCalls: groupItemsByDate(missedCalls),
    meta,
    params,
    fetchMissedCalls,
    confirmMissedCall,
    confirmAllMissedCalls,
    clearTexts,
  };
}
