import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Day from 'dayjs';
import http from 'app/core/engine-service';
import { nullMeta, parseMetaFromResponse } from 'app/helpers/meta';
import { groupItemsByDate } from 'app/helpers/utils';
import { error } from 'state/notifications/actions';
import { readAlertSuccess, readAllAlertsSuccess } from 'state/alerts/actions';
import { forEachError } from '../../../../helpers/errorHelper';

export default function useAlerts() {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [meta, setMeta] = useState(nullMeta);
  const [params, setParams] = useState({
    page: 1,
    per_page: 20,
    include_all: true,
  });

  const dispatch = useDispatch();

  const fetchAlerts = (requestParams = {}) => {
    const newParams = { ...params, ...requestParams };
    setIsLoading(true);

    return http.get('/alerts', newParams).then((response) => {
      setIsLoading(false);
      setParams(newParams);
      setMeta(parseMetaFromResponse(response.meta));

      const newAlerts = response.alerts;

      setAlerts((prevAlerts) => (newParams.page > 1 ? [...prevAlerts, ...newAlerts] : newAlerts));

      return response.alerts;
    });
  };

  const fetchOwner = (alert) => {
    const ownerType = alert.resource_type.toLowerCase();
    const ownerEndpoint = {
      Reminder: '/reminders',
      Note: '/notes',
      Activity: '/interactions',
    }[alert.resource_type];

    return http
      .get(`${ownerEndpoint}/${alert.resource_id}`)
      .then((response) => {
        return ownerType === 'activity' ? response.interaction : response[ownerType];
      })
      .catch((err) => forEachError(err.data, (e) => dispatch(error(e))));
  };

  const confirmAlert = (alert) => {
    return http
      .patch(`/alerts/${alert.id}`, { alert: { read_at: new Day().format() } })
      .then(() => {
        dispatch(readAlertSuccess(alert.id));
      });
  };

  const confirmAllAlerts = () => {
    setIsConfirming(true);

    return http
      .patch('/alerts/batch_update', { alert: { read_at: new Day().format() } })
      .then(() => {
        dispatch(readAllAlertsSuccess());
        setIsConfirming(false);
      });
  };

  const clearAlerts = () => setAlerts([]);

  return {
    isLoading,
    isConfirming,
    alerts: groupItemsByDate(alerts),
    meta,
    params,
    fetchAlerts,
    confirmAlert,
    confirmAllAlerts,
    clearAlerts,
    fetchOwner,
  };
}
