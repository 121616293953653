import {
  STORE_TWILIO_PROFILE,
  SET_TWILIO_VERIFIED_STATUS,
  CHANGE_TWILIO_PROFILE_AVAILABILITY,
  FETCHING_ORG_TWILIO_PROFILES,
  STORE_ORG_TWILIO_PROFILES,
  TOGGLE_ORG_CALL_RECORDING_AVILABILITY,
} from './constants';
import { parseTwilioProfileFromEngine } from '../../helpers/twilio-profile';

const initialState = {
  ...parseTwilioProfileFromEngine(),
  fetching: false,
  fetched: false,
  phoneVerified: null,
  availableTwilioProfiles: [],
  fetchingProfiles: false,
};

function storeTwilioProfileHandler(state, action) {
  return {
    ...state,
    ...parseTwilioProfileFromEngine(action.data),
    fetching: false,
    fetched: true,
  };
}

function setTwilioVerifiedStatusHandler(state, action) {
  return {
    ...state,
    phoneVerified: action.data,
  };
}

function changeTwilioProfileAvailabilityHandler(state, action) {
  return {
    ...state,
    available: action.available,
  };
}

function storeOrgTwilioProfilesHandler(state, action) {
  return {
    ...state,
    availableTwilioProfiles: action.twilioProfiles,
    fetchingProfiles: false,
  };
}

function fetchingOrgTwilioProfilesHandler(state) {
  return {
    ...state,
    fetchingProfiles: true,
  };
}

function toggleIsCallRecordingEnabled(state, action) {
  return {
    ...state,
    callRecordingEnabled: action.data,
  };
}

const ACTION_HANDLERS = {
  [STORE_TWILIO_PROFILE]: storeTwilioProfileHandler,
  [SET_TWILIO_VERIFIED_STATUS]: setTwilioVerifiedStatusHandler,
  [CHANGE_TWILIO_PROFILE_AVAILABILITY]: changeTwilioProfileAvailabilityHandler,
  [STORE_ORG_TWILIO_PROFILES]: storeOrgTwilioProfilesHandler,
  [FETCHING_ORG_TWILIO_PROFILES]: fetchingOrgTwilioProfilesHandler,
  [TOGGLE_ORG_CALL_RECORDING_AVILABILITY]: toggleIsCallRecordingEnabled,
};

export default function twilioProfileReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
