import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Phone from 'app/lib/Phone';
import Dialpad from '../Dialpad';
import {
  toggleCallModal,
  setPhoneNumber as setPhone,
  setActiveCallModalOpen,
} from '../state/actions';
import styles from './MakeACallDialog.module.scss';

export default function MakeACallDialog() {
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const dispatch = useDispatch();

  const onChange = (number) => {
    setPhoneNumber(number);
  };

  const onBackspaceClick = () => {
    if (phoneNumber) {
      setPhoneNumber(phoneNumber.slice(0, -1));
    }
  };

  const onKeyClick = (value) => {
    setPhoneNumber(`${phoneNumber}${value}`);
  };

  const closeCallModal = () => {
    dispatch(toggleCallModal(false));
    dispatch(setActiveCallModalOpen(false));
  };

  useEffect(() => {
    dispatch(setPhone(phoneNumber));
  }, [phoneNumber]);

  return (
    <Draggable zIndex={100} enableUserSelectHack={false}>
      <Box className={styles['make-call-dialog']}>
        <Grid container direction="column" alignItems="center" justifyContent="flex-end">
          <Grid item>
            <CloseOutlinedIcon className={styles['close-button']} onClick={closeCallModal} />
          </Grid>
          <Grid item className={styles['phone-input']}>
            <Phone secondary value={phoneNumber} defaultCountry="us" onChange={onChange} />
          </Grid>
          <Grid item className={styles.icon}>
            <Dialpad onKeyClick={onKeyClick} handleDelete={onBackspaceClick} />
          </Grid>
        </Grid>
      </Box>
    </Draggable>
  );
}
