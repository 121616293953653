export const resourceTypes = {
  OPPORTUNITY: 'Opportunity',
  CONTACT: 'Contact',
  ACCOUNT: 'Account',
  ACTIVITY: 'Activity',
  NOTIFICATION: 'Notification',
  INTERACTION: 'Interaction',
  USER: 'User',
  REMINDER: 'Reminder',
  TICKET: 'Ticket',
  LIST: 'List',
  LINK: 'Link',
};
