import constants from './constants';

const nullMeta = {
  current_page: null,
  next_page: null,
  previous_page: null,
};

const initialState = {
  data: [],
  meta: nullMeta,
  params: {
    per_page: 10,
  },
  errors: [],
  updatingID: null,
  isFetching: false,
  isUpdating: false,
  isBatchUpdating: false,
  isPopupVisible: false,
};

function getAlertsHandler(state) {
  return {
    ...state,
    isFetching: true,
  };
}

function getAlertsSuccessHandler(state, action) {
  return {
    ...state,
    data: [...state.data, ...action.data],
    meta: action.meta,
    isFetching: false,
  };
}

function getAlertsFailureHandler(state) {
  return {
    ...state,
    errors: [],
    isFetching: false,
  };
}

function addAlertHandler(state, action) {
  return {
    ...state,
    data: [action.alert, ...state.data],
  };
}

function readAlertRequestHandler(state, action) {
  return {
    ...state,
    isUpdating: true,
    updatingID: action.alertID,
  };
}

function readAlertSuccessHandler(state, action) {
  return {
    ...state,
    isUpdating: false,
    updatingID: null,
    data: state.data.filter((a) => a.id !== action.alertID),
    isPopupVisible: false,
  };
}

function readAlertFailureHandler(state) {
  return {
    ...state,
    isUpdating: false,
  };
}

function readAllAlertsRequestHandler(state) {
  return {
    ...state,
    isBatchUpdating: true,
  };
}

function readAllAlertsSuccessHandler(state) {
  return {
    ...state,
    data: [],
    isBatchUpdating: false,
    isPopupVisible: false,
  };
}

function readAllAlertsFailureHandler(state) {
  return {
    ...state,
    isBatchUpdating: false,
  };
}

function toggleAlertsPopupHandler(state) {
  return {
    ...state,
    isPopupVisible: !state.isPopupVisible,
  };
}

const ACTION_HANDLERS = {
  [constants.GET_ALERTS_REQUEST]: getAlertsHandler,
  [constants.GET_ALERTS_SUCCESS]: getAlertsSuccessHandler,
  [constants.GET_ALERTS_FAILURE]: getAlertsFailureHandler,
  [constants.ADD_ALERT]: addAlertHandler,
  [constants.UPDATE_ALERT_REQUEST]: readAlertRequestHandler,
  [constants.UPDATE_ALERT_SUCCESS]: readAlertSuccessHandler,
  [constants.UPDATE_ALERT_FAILURE]: readAlertFailureHandler,
  [constants.BATCH_UPDATE_ALERTS_REQUEST]: readAllAlertsRequestHandler,
  [constants.BATCH_UPDATE_ALERTS_SUCCESS]: readAllAlertsSuccessHandler,
  [constants.BATCH_UPDATE_ALERTS_FAILURE]: readAllAlertsFailureHandler,
  [constants.TOGGLE_ALERTS_POPUP]: toggleAlertsPopupHandler,
};

export default function alertsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
