import cs from 'classnames';
import PropTypes from 'prop-types';
import { FormControl, MenuItem } from '@mui/material';
import { Mention, MentionsInput } from 'react-mentions';
import {
  defaultMentionStyle,
  floatingLabelClassName,
  mentionInputClassName,
  useStyles,
} from './defaultStyles';

const renderSuggestionItem = (dataTestId) => {
  return (suggestion) => {
    const itemContent = suggestion.display;
    return (
      <MenuItem
        component="div"
        style={{ height: '100%', width: '100%' }}
        data-testid={`${dataTestId}-menu-item-${itemContent}`}
      >
        {itemContent}
      </MenuItem>
    );
  };
};

const MentionInput = ({
  mentionData,
  onMentionAdd,
  multiline,
  className,
  label,
  onBlur,
  ...props
}) => {
  const classes = useStyles(props);
  const dataTestId = 'test-id-spiro-mention-input';

  const handleBlur = (e, boolean = false) => {
    if (boolean === false && onBlur) onBlur(e);
  };

  return (
    <FormControl className={cs(classes.root)}>
      <label className={floatingLabelClassName}>{label}</label>
      <MentionsInput
        {...props}
        onBlur={handleBlur}
        data-testid={dataTestId}
        className={mentionInputClassName}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        singleLine={!multiline}
        placeholder="Type @ to mention someone"
      >
        <Mention
          trigger="@"
          data={mentionData}
          onAdd={onMentionAdd}
          appendSpaceOnAdd
          renderSuggestion={renderSuggestionItem(dataTestId)}
          style={defaultMentionStyle}
        />
      </MentionsInput>
    </FormControl>
  );
};

MentionInput.defaultProps = {
  className: '',
  multiline: false,
};

MentionInput.propTypes = {
  mentionData: PropTypes.array.isRequired,
  onMentionAdd: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
};

export default MentionInput;
