import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectTwilioProfile } from 'state/user/selectors';
import { Chat, LoadMoreButton, UnreadMessagesButton } from '../../Chat';
import MessageForm from '../MessageForm';
import { getMessages, confirmMessage, clearUnreadMessages } from '../state/actions';
import {
  selectMessages,
  selectNextPage,
  selectParentInteraction,
  selectUnreadMessagesCount,
} from '../state/selectors';
import styles from './ConversationChat.module.scss';

export default function ConversationChat({ contact }) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const parent = useSelector(selectParentInteraction);
  const messages = useSelector(selectMessages);
  const hasUnreadMessages = !!useSelector(selectUnreadMessagesCount);
  const nextPage = useSelector(selectNextPage);
  const twilioProfile = useSelector(selectTwilioProfile);
  const params = { sort: 'created_at', order: 'desc', per_page: 50 };
  let prevScrollHeight = '';

  useEffect(() => {
    if (parent) {
      dispatch(getMessages(parent.id, params)).then(handleGetMessagesSucces);
    }
  }, []);

  function handleGetMessagesSucces() {
    dispatch(confirmMessage(parent.id));
    scrollToBottom();
  }

  function handleUnreadMessages() {
    dispatch(clearUnreadMessages());
    scrollToBottom();
  }

  // useEffect(() => {
  //   ref.current.addEventListener('scroll', handleScroll);

  //   return () => {
  //     ref.current.removeEventListener('scroll', handleScroll);
  //   };
  // });

  // function handleScroll() {
  //   const scrolledToTop = ref.current.scrollTop === 0;

  //   if (scrolledToTop && nextPage) {
  //     setPrevScrollHeight();

  //     dispatch(getMessages(parent.id, { ...params, page: nextPage }))
  //       .then(setNewScrollTop);
  //   }
  // }

  const handleLoadMore = () => {
    setPrevScrollHeight();

    dispatch(getMessages(parent.id, { ...params, page: nextPage })).then(setNewScrollTop);
  };

  function scrollToBottom() {
    if (ref.current) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight - ref.current.clientHeight,
        behavior: 'smooth',
      });
    }
  }

  function setPrevScrollHeight() {
    prevScrollHeight = ref.current.scrollHeight;
  }

  function setNewScrollTop() {
    ref.current.scrollTop = ref.current.scrollHeight - prevScrollHeight;
  }

  return (
    <div className={styles.container}>
      <div className={styles.chat} ref={ref}>
        <LoadMoreButton visible={!!nextPage} onClick={handleLoadMore} />
        <Chat messages={messages} onLoad={scrollToBottom} />
        <UnreadMessagesButton visible={hasUnreadMessages} onClick={handleUnreadMessages} />
        {Object.keys(messages).length === 0 && twilioProfile.profile_type === 'verified_number' && (
          <p className={styles.message}>There are no messages in this conversation.</p>
        )}
      </div>
      {twilioProfile && twilioProfile.profile_type !== 'verified_number' && (
        <MessageForm contact={contact} onSuccess={scrollToBottom} />
      )}
    </div>
  );
}

ConversationChat.propTypes = {
  contact: PropTypes.object.isRequired,
};
