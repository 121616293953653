import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from 'lib/TextField';

const ListSelect = ({ lists, handleSelect, loading, loadingText }) => {
  return (
    <Autocomplete
      disableClearable
      options={lists}
      onChange={(_, item) => handleSelect(item)}
      getOptionLabel={(option) => option.title}
      renderOption={(inputProps, option) => {
        return (
          <li
            {...inputProps}
            key={`${option.id}-${Math.random()}`}
            style={{ backgroundColor: 'white' }}
          >
            <div>{option.title}</div>
          </li>
        );
      }}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      loading={loading}
      loadingText={loadingText}
      renderInput={(params) => <TextField {...params} label="Campaigns" />}
    />
  );
};

ListSelect.defaultProps = {
  loadingText: 'Loading...',
  loading: false,
};

ListSelect.propTypes = {
  lists: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  loadingText: PropTypes.string,
  loading: PropTypes.bool,
};

export default ListSelect;
