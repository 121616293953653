import { connect } from 'react-redux';
import { toggleModal, getConversation, getMessages, clearConversation } from './state/actions';
import Conversation from './Conversation';

function mapStateToProps(state) {
  return {
    visible: state.conversation.visible,
    contact: state.conversation.contact,
    parentInteraction: state.conversation.parentInteraction,
    parentLoading: state.conversation.parentLoading,
  };
}

export default connect(mapStateToProps, {
  clearConversation,
  getMessages,
  getConversation,
  toggleModal,
})(Conversation);
