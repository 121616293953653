import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import { fetchEngineRules, handleCreateEngineRule } from './actions';

const initialState = {
  fetching: false,
  data: [],
  meta: nullMeta,
  createInProgress: false,
  addNewRuleModalVisible: false,
  params: {
    page: 1,
    per_page: 15,
  },
};

export const engineRulesSlice = createSlice({
  name: 'engine-rules',
  initialState,
  reducers: {
    setAddNewRuleModalVisible: (state, { payload }) => {
      return { ...state, addNewRuleModalVisible: payload };
    },
    setEngineRules: (state, { payload }) => {
      return { ...state, data: payload };
    },
  },
  extraReducers: {
    [fetchEngineRules.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [fetchEngineRules.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: payload.assistant_rules,
        fetching: false,
        meta: {
          ...state.meta,
          ...parseMetaFromResponse(payload.meta),
        },
      };
    },
    [fetchEngineRules.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [handleCreateEngineRule.pending]: (state) => {
      return { ...state, createInProgress: true };
    },
    [handleCreateEngineRule.fulfilled]: (state) => {
      return { ...state, createInProgress: false, addNewRuleModalVisible: false };
    },
    [handleCreateEngineRule.rejected]: (state) => {
      return { ...state, createInProgress: false };
    },
  },
});

export const { setAddNewRuleModalVisible, setEngineRules } = engineRulesSlice.actions;

export default engineRulesSlice.reducer;
