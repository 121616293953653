import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationSystem from 'react-notification-system';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styles from './Notifications.module.scss';

const Notifications = ({ notifications, removeByUid }) => {
  const history = useHistory();
  const ref = useRef();

  const displayNotificationText = (type, text, data) => {
    return (
      <div className={styles.notification}>
        {type === 'error' ? (
          <ErrorOutlineIcon fontSize="small" className={styles.error} />
        ) : (
          <CheckCircleOutlineIcon fontSize="small" className={styles.success} />
        )}
        {data ? (
          <div className={styles.wrapper}>
            <span className={styles.text}>{text}</span>
            <span
              className="spiro-link bold-500"
              onClick={() => history.push(`/contacts/${data.id}`)}
              role="button"
              tabIndex="0"
            >
              {data.first_name} {data.last_name}
            </span>
          </div>
        ) : (
          <span className={styles.span}>{text}</span>
        )}
      </div>
    );
  };

  const removeNotification = (uid) => {
    removeByUid(uid);
  };

  const drawNotifications = (data) => {
    data.forEach((n) => {
      ref.current.addNotification({
        level: n.type,
        onRemove: () => {
          removeNotification(n.uid);
        },
        uid: n.uid,
        autoDismiss: 6,
        position: 'br',
        dismissible: 'button',
        children: displayNotificationText(n.type, n.text, n.data),
      });
    });
  };

  const getStyle = () => ({
    Containers: {
      DefaultStyle: {
        width: 400,
      },
      br: {
        right: '40px',
        bottom: '30px',
      },
    },
    NotificationItem: {
      DefaultStyle: {
        borderStyle: 'none',
        fontSize: '14px',
        padding: '14px 8px 14px 18px',
        borderRadius: '4px',
      },
      success: {
        backgroundColor: '#EDF7ED',
        color: '#1E4620',
      },
      error: {
        backgroundColor: '#FEECEB',
        color: '#621b16',
      },
    },
    Dismiss: {
      DefaultStyle: {
        cursor: 'pointer',
        fontFamily: 'Roboto',
        fontSize: '26px',
        position: 'absolute',
        top: '17px',
        right: '10px',
        lineHeight: '15px',
        backgroundColor: '#dededf',
        color: '#ffffff',
        borderRadius: '50%',
        width: '14px',
        height: '14px',
        fontWeight: 'normal',
        textAlign: 'center',
      },
      success: {
        backgroundColor: '#EDF7ED',
        color: '#1E4620',
      },
      error: {
        backgroundColor: '#FEECEB',
        color: '#621b16',
      },
    },
  });

  useEffect(() => {
    if (notifications.length) {
      drawNotifications(notifications);
    }
  }, [notifications]);

  return (
    <NotificationSystem
      ref={ref}
      style={getStyle()}
      data-cy="notification-message"
      dataCy="notification-message"
    />
  );
};

Notifications.defaultProps = {
  notifications: [],
};

Notifications.propTypes = {
  notifications: PropTypes.array,
  removeByUid: PropTypes.func.isRequired,
};

export default Notifications;
