import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmailClient } from 'state/user/selectors';
import { showModal } from 'modals/state/actions';
import { getContacts } from 'services/search';
import Contact from 'models/Contact';

export default function SpiroLinkify({ children, stopPropagation }) {
  const emailClient = useSelector(selectEmailClient);
  const dispatch = useDispatch();

  const defaultProperties = {
    target: '_blank',
    rel: 'noopener noreferrer',
    style: { color: '#f06223' },

    onClick: (e) => {
      if (stopPropagation) e.stopPropagation();
      if (e.target.href.includes('mailto:')) {
        const email = e.target.href.substring(7);
        e.preventDefault();
        if (emailClient === 'gmail') {
          window.open(
            `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(email)}`,
            '_blank'
          );
        }
        if (emailClient === 'spiro') {
          getContacts({
            query: email,
            sort: 'email',
          }).then((res) => {
            if (res.contacts.length > 0) {
              dispatch(
                showModal('SEND_EMAIL_MODAL', {
                  show: true,
                  contact: new Contact(res.contacts[0]),
                })
              );
            } else {
              dispatch(
                showModal('SEND_EMAIL_MODAL', {
                  show: true,
                  contact: { name: '', email, contactId: undefined, invalid: true },
                })
              );
            }
          });
        } else {
          window.open(`mailto:${encodeURIComponent(email)}`, '_blank');
        }
      }
    },
  };

  return <Linkify properties={defaultProperties}>{children}</Linkify>;
}

SpiroLinkify.defaultProps = {
  stopPropagation: false,
};

SpiroLinkify.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  stopPropagation: PropTypes.bool,
};
