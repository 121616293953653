import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/quotes/actions';
import { getQuotes, updateQuote as updateQouteRemote } from 'services/qoutes';
import { error } from 'state/notifications/actions';
import { serializeFiltersToString } from 'helpers/filters';
import { forEachError } from '../../../../../helpers/errorHelper';

function getParamsFromState(state) {
  const params = state.contacts.params;
  const currentFilters = state.filters.quotes.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.Quote;

  const paramsObj = params.sort
    ? params
    : { ...params, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export const fetchQuotes = createAsyncThunk(
  'quotes-get',
  async (params = {}, { dispatch, getState }) => {
    dispatch(fetchFilterProperties());
    return dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      return getQuotes(payload)
        .then((json) => {
          return Promise.resolve({ ...json, params: { ...stateParams, ...params } });
        })
        .catch((err) => Promise.reject(err));
    });
  }
);

export const updateQuote = createAsyncThunk('quote-update', async (data, { dispatch }) => {
  try {
    const res = await updateQouteRemote(data.id, data.payload);
    return res.quote;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});
