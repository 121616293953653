import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  STORE_CUSTOM_OBJECT,
  SET_PARAMS,
  ADD_CONTACT_TO_LIST,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  CUSTOM_OBJECTS_SHOW_MODAL,
  CUSTOM_OBJECTS_HIDE_MODAL,
  CUSTOM_OBJECTS_SET_SELECTED_ENTITY,
  CUSTOM_OBJECTS_CLEAR_DATA,
} from './constants';
import { nullMeta } from '../../../helpers/meta';
import { immutableSplice } from '../../../../helpers/immutableHelper';

const initialState = {
  requestStarted: false,
  data: [],
  createInProgress: false,
  meta: nullMeta,
  params: {
    page: 1,
    per_page: 10,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    query: undefined,
  },
  modalVisible: false,
  selectedCustomObject: null,
  selectedEntity: null,
};

function requestStartedHandler(state) {
  return {
    ...state,
    requestStarted: true,
    requestSuccess: false,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestSuccess: true,
    data: action.data,
    meta: action.meta,
  };
}

function storeCustomObjectHandler(state, action) {
  const index = state.data.findIndex((o) => o.id === action.customObject.id);

  return {
    ...state,
    data: immutableSplice(state.data, index, 1, action.customObject),
  };
}

function addContactToListHandler(state, action) {
  return { ...state, data: [action.contact, ...state.data] };
}

function showModalHandler(state, action) {
  return {
    ...state,
    modalVisible: true,
    selectedCustomObject: action.payload.customObject,
  };
}

function hideModalHandler(state) {
  return {
    ...state,
    modalVisible: false,
    selectedCustomObject: null,
  };
}

export function setParamsHandler(state, action) {
  return { ...state, params: { ...state.params, ...action.data } };
}

export function createInProgressHandler(state) {
  return { ...state, createInProgress: true };
}

export function createSuccessHandler(state) {
  return { ...state, createInProgress: false };
}

export function createFailureHandler(state) {
  return { ...state, createInProgress: false };
}

export function setSelectedEntityHandler(state, action) {
  return { ...state, selectedEntity: action.entity };
}

export function clearDataHandler(state) {
  return {
    ...state,
    data: [],
    selectedEntity: null,
    selectedCustomObject: null,
    params: initialState.params,
  };
}

const ACTION_HANDLERS = {
  [REQUEST_STARTED]: requestStartedHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [STORE_CUSTOM_OBJECT]: storeCustomObjectHandler,
  [SET_PARAMS]: setParamsHandler,
  [CREATE_IN_PROGRESS]: createInProgressHandler,
  [CREATE_SUCCESS]: createSuccessHandler,
  [ADD_CONTACT_TO_LIST]: addContactToListHandler,
  [CUSTOM_OBJECTS_SHOW_MODAL]: showModalHandler,
  [CUSTOM_OBJECTS_HIDE_MODAL]: hideModalHandler,
  [CUSTOM_OBJECTS_SET_SELECTED_ENTITY]: setSelectedEntityHandler,
  [CUSTOM_OBJECTS_CLEAR_DATA]: clearDataHandler,
};

export default function customObjectsDataReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
