import PropTypes from 'prop-types';
import { ReactComponent as InboundCallIcon } from './inbound-call.svg';
import { ReactComponent as OutboundCallIcon } from './outbound-call.svg';
import { ReactComponent as InboundEmailIcon } from './inbound-email.svg';
import { ReactComponent as OutboundEmailIcon } from './outbound-email.svg';
import { ReactComponent as MissedCallIcon } from './missed-call.svg';
import { ReactComponent as ReceivedVoicemailIcon } from './received-voicemail.svg';
import { ReactComponent as MetInPersonIcon } from './met-in-person.svg';
import { ReactComponent as AttachmentIcon } from './attachment.svg';
import { ReactComponent as CampaignIcon } from './campaign.svg';
import { ReactComponent as ReminderIcon } from './reminder.svg';
import { ReactComponent as CompletedReminderIcon } from './completed-reminder.svg';
import { ReactComponent as DismissedReminderIcon } from './dismissed-reminder.svg';
import { ReactComponent as ForwardCallIcon } from './forward-call.svg';
import { ReactComponent as CallRecordingIcon } from './call-recording.svg';
import { ReactComponent as CallDidntConnectIcon } from './call-didnt-connect.svg';
import { ReactComponent as LeftVoicemailIcon } from './left-voicemail.svg';
import { ReactComponent as InboundTextIcon } from './inbound-text.svg';
import { ReactComponent as OutboundTextIcon } from './outbound-text.svg';
import { ReactComponent as NoteIcon } from './note.svg';
import { ReactComponent as MeetingIcon } from './meeting.svg';
import { ReactComponent as DefaultIcon } from './default.svg';

export default function ActivityIcon({ kind }) {
  const iconsLookup = {
    inbound_call: <InboundCallIcon />,
    outbound_call: <OutboundCallIcon />,
    inbound_email: <InboundEmailIcon />,
    outbound_email: <OutboundEmailIcon />,
    missed_call: <MissedCallIcon />,
    received_voicemail: <ReceivedVoicemailIcon />,
    attachment: <AttachmentIcon />,
    campaign: <CampaignIcon />,
    reminder: <ReminderIcon />,
    completed_reminder: <CompletedReminderIcon />,
    dismissed_reminder: <DismissedReminderIcon />,
    call_didnt_connect: <CallDidntConnectIcon />,
    left_voicemail: <LeftVoicemailIcon />,
    forward_call: <ForwardCallIcon />,
    call_recording: <CallRecordingIcon />,
    inbound_text: <InboundTextIcon />,
    outbound_text: <OutboundTextIcon />,
    sent_text: <OutboundTextIcon />,
    note: <NoteIcon />,
    met_in_person: <MetInPersonIcon />,
    meeting: <MeetingIcon />,
    other: <DefaultIcon />,
  };

  return iconsLookup[kind] || <DefaultIcon />;
}

ActivityIcon.propTypes = {
  kind: PropTypes.string.isRequired,
};
