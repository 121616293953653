import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EventIcon from '@mui/icons-material/Event';
import { List, ListItem, Tooltip, Divider, IconButton } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SearchProvider from 'components/SearchModal/SearchProvider';
import { showNotificationsSlider } from 'state/app-notifications/actions';
import { selectHasNewNotifications } from 'state/app-notifications/selectors';
import { selectAlerts } from 'state/alerts/selectors';
import NavSearch from '../NavSearch/NavSearch';
import UserWidget from '../UserWidget';
import NotificationIndicator from './NotificationIndicator';
import { useStyles } from './useStyles';
import styles from './TopBarActions.module.scss';
import EmailIndicator from './EmailIndicator';

export default function TopBarActions() {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasNewNotifications = useSelector(selectHasNewNotifications);
  const hasUnreadText = useSelector((state) => state.counters.unreadSMS);
  const hasMissedCalls = useSelector((state) => state.counters.missedCalls);
  const alerts = useSelector(selectAlerts);

  const openNotifications = () => {
    dispatch(showNotificationsSlider());
  };

  const isShown =
    hasNewNotifications || hasUnreadText > 0 || hasMissedCalls > 0 || alerts.length > 0;

  return (
    <div className={styles.actions}>
      <div className={styles.icons}>
        <List component="nav" aria-labelledby="top bar actions" className={classes.navDisplayFlex}>
          <ListItem className={classes.listItems}>
            <SearchProvider>
              <NavSearch />
            </SearchProvider>
          </ListItem>
          <Divider orientation="vertical" className={classes.divider} flexItem />
          <ListItem className={classes.listItems}>
            <EmailIndicator />
          </ListItem>
          <ListItem className={classes.listItems}>
            <Tooltip
              title={t('localization.navigation.calendar')}
              arrow
              enterDelay={300}
              enterNextDelay={300}
            >
              <IconButton
                aria-label="Calendar"
                color="inherit"
                onClick={() => history.push('/calendar')}
                size="large"
              >
                <EventIcon data-cy="navbar-calendar-icon" />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.listItems}>
            {isShown && <NotificationIndicator />}
            <NotificationsNoneIcon
              onClick={openNotifications}
              data-cy="navbar-notifications-icon"
            />
          </ListItem>
        </List>
      </div>
      <UserWidget />
    </div>
  );
}
