import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import {
  fetchSmartProducts,
  handleCreateSmartProduct,
  updateSmartProduct,
  fetchConfigurations,
} from './actions';

const initialState = {
  fetching: false,
  data: [],
  isCreateInProgress: false,
  createModalOpen: false,
  configurations: {
    billing_frequencies: [],
    categories: [],
    unit_types: [],
  },
  meta: nullMeta,
  params: {
    page: 1,
    per_page: 25,
    sort: undefined,
    order: undefined,
    query: undefined,
  },
};

export const smartProductsSlice = createSlice({
  name: 'smart-products',
  initialState,
  reducers: {
    clearState: () => {
      return { ...initialState };
    },
    toogleNewProductModal: (state) => {
      return { ...state, createModalOpen: !state.createModalOpen };
    },
  },
  extraReducers: {
    [fetchSmartProducts.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [fetchSmartProducts.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: payload.products,
        meta: parseMetaFromResponse(payload.meta),
        params: payload.params,
        fetching: false,
      };
    },
    [fetchSmartProducts.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [handleCreateSmartProduct.pending]: (state) => {
      return { ...state, isCreateInProgress: true };
    },
    [handleCreateSmartProduct.fulfilled]: (state) => {
      return { ...state, isCreateInProgress: false, createModalOpen: false };
    },
    [fetchConfigurations.fulfilled]: (state, { payload }) => {
      return { ...state, configurations: payload };
    },
    [handleCreateSmartProduct.rejected]: (state) => {
      return { ...state, isCreateInProgress: false };
    },
    [updateSmartProduct.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: state.data.map((product) => {
          if (product.id === payload.product.id) return payload.product;
          return product;
        }),
      };
    },
  },
});

export const { clearState, toogleNewProductModal } = smartProductsSlice.actions;

export default smartProductsSlice.reducer;
