import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, userLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      userLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: {
                pathname: props.location.pathname,
                search: props.location.search,
              },
            },
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    userLoggedIn: state.session.loggedIn,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
