import EngineService from '../core/engine-service';

const ENDPOINT = '/notes';

// GET: /notes/:id
export function get(noteID) {
  return EngineService.get(`${ENDPOINT}/${noteID}`).then((json) => json);
}

// POST: /notes
export function create(payload) {
  return EngineService.post(ENDPOINT, { note: payload }).then((json) => json);
}

// PATCH: /notes/123
export function update(noteID, payload) {
  return EngineService.patch(`${ENDPOINT}/${noteID}`, { note: payload }).then((json) => json);
}

// DELETE: /notes/123
export function destroy(noteID) {
  return EngineService.delete(`${ENDPOINT}/${noteID}`).then((json) => json);
}

export default {
  get,
  create,
  update,
  delete: destroy,
};
