import { SHOW_MODAL, HIDE_MODAL, NOTE_CREATE_STARTED, NOTE_CREATED } from './constants';

const initialState = {
  isSubmitting: false,
  isOpen: false,
};

const showModal = (state) => ({ ...state, isOpen: true });
const hideModal = (state) => ({ ...state, isOpen: false });
const noteCreateStarted = (state) => ({ ...state, isSubmitting: true });
const noteCreated = (state) => ({ ...state, isSubmitting: false });

const ACTION_HANDLERS = {
  [SHOW_MODAL]: showModal,
  [HIDE_MODAL]: hideModal,
  [NOTE_CREATE_STARTED]: noteCreateStarted,
  [NOTE_CREATED]: noteCreated,
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
