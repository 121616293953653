import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import Day from 'dayjs';

export function selectMessages(state) {
  const messages = state.conversation.data;

  // FIXME: this is just a temp solution to prevent crashing
  const notNullMessages = messages
    .filter((m) => m !== null)
    .map(({ created_at: createdAt, ...rest }) => ({
      createdAt: Day(createdAt).format('D MMMM YYYY'),
      ...rest,
    }));

  const uniqueMessages = uniqBy(notNullMessages, 'id');

  const groupedMessages = groupBy(uniqueMessages, 'createdAt');

  return groupedMessages;
}

export function selectIsLoading(state) {
  return state.conversation.isLoading;
}

export function selectNextPage(state) {
  return state.conversation.meta.nextPage;
}

export function selectParentInteraction(state) {
  return state.conversation.parentInteraction;
}

export function selectUnreadMessagesCount(state) {
  return state.conversation.unreadMessages.length;
}

export const selectConversationModalOpen = (state) => state.conversation.visible;
