export const REQUEST_STARTED = 'contacts_fetch_all_request_started';
export const REQUEST_SUCCESS = 'contacts_fetch_all_request_success';
export const REQUEST_FAILED = 'contacts_fetch_all_request_failed';
export const SORT_CONTACTS = 'contacts_sort';
export const REPLACE_CONTACT = 'contacts_replace_contact';
export const STORE_CONTACTS = 'contacts_store_contacts';
export const SET_PARAMS = 'contacts_set_params';
export const RESET_DATA = 'contacts_reset_contacts_data';
export const ADD_CONTACT_TO_LIST = 'contacts_add_contact_to_list';
export const CREATE_IN_PROGRESS = 'contacts_create_in_progress';
export const CREATE_SUCCESS = 'contacts_create_success';
export const CREATE_FAILURE = 'contacts_create_failure';
export const RESET_CONTACTS_LOADING_PROGRESS = 'reset_contacts_loading_progress';
