import { createSlice } from '@reduxjs/toolkit';
import { fetchQuote, updateQuote } from './actions';

const initialState = {
  data: null,
  fetching: true,
};

export const quoteSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    resetQuoteState: () => ({ ...initialState }),
  },
  extraReducers: {
    [fetchQuote.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: false,
      };
    },
    [fetchQuote.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [updateQuote.fulfilled]: (state, { payload }) => {
      return { ...state, data: payload };
    },
  },
});

export const { resetQuoteState } = quoteSlice.actions;

export default quoteSlice.reducer;
