import { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export default function useListSearch(
  fetchData,
  defaultValue = '',
  param = 'query',
  entity = null
) {
  const [searchQuery, setSearchQuery] = useState(defaultValue);
  const location = useLocation();

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleSearchEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        fetchData({ [param]: searchQuery, page: 1 });
      }
    },
    [searchQuery, entity]
  );

  const handleSearchClear = useCallback(() => {
    setSearchQuery('');
    fetchData({ [param]: '', page: 1 });
  }, [location.pathname]);

  return {
    searchQuery,
    handleSearchChange,
    handleSearchEnterPress,
    handleSearchClear,
  };
}
