import { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import ImportAllowedRoute from 'components/ImportAllowedRoute';
import retry from 'helpers/retry';
import SupportDeskRoute from 'components/SupportDeskRoute';
import SmartProductsRoute from 'components/SmartProductsRoute';

const Home = lazy(() => retry(() => import(/* webpackChunkName: "Home" */ 'routes/Home')));

const Activities = lazy(() =>
  retry(() => import(/* webpackChunkName: "Activities" */ '../../../activities'))
);
const Assistant = lazy(() =>
  retry(() => import(/* webpackChunkName: "Assistant" */ '../../../assistant'))
);
const Import = lazy(() => retry(() => import(/* webpackChunkName: "Import" */ 'routes/Import')));
const Settings = lazy(() =>
  retry(() => import(/* webpackChunkName: "Settings" */ 'routes/Settings'))
);
const Calendar = lazy(() =>
  retry(() => import(/* webpackChunkName: "Calendar" */ 'routes/CalendarView'))
);
const OnboardingLayout = lazy(() =>
  retry(() => import(/* webpackChunkName: "OnboardingLayout" */ 'routes/Onboarding'))
);

const Companies = lazy(() =>
  retry(() => import(/* webpackChunkName: "Companies" */ '../../../companies'))
);

const Contacts = lazy(() =>
  retry(() => import(/* webpackChunkName: "Contacts" */ '../../../contacts'))
);

const Opportunities = lazy(() =>
  retry(() => import(/* webpackChunkName: "Opportunities" */ '../../../opportunities'))
);

const Campaigns = lazy(() =>
  retry(() => import(/* webpackChunkName: "Campaigns" */ '../../../campaigns'))
);
const Lists = lazy(() => retry(() => import(/* webpackChunkName: "Lists" */ '../../../lists')));

const EngineRules = lazy(() =>
  retry(() => import(/* webpackChunkName: "EngineRules" */ '../../../engine-rules'))
);

const Tickets = lazy(() =>
  retry(() => import(/* webpackChunkName: "Tickets" */ '../../../tickets'))
);

const Quotes = lazy(() => retry(() => import(/* webpackChunkName: "Quotes" */ '../../../quotes')));

const Reminders = lazy(() =>
  retry(() => import(/* webpackChunkName: "Quotes" */ '../../../reminders'))
);

const Routes = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/reminders" exact component={Reminders} />
    <Route path="/companies" component={Companies} />
    <Route path="/contacts" component={Contacts} />
    <Route path="/opportunities" component={Opportunities} />
    <Route path="/campaigns" component={Campaigns} />
    <Route path="/activities" component={Activities} />
    <Route path="/assistant" component={Assistant} />
    <Route path="/calendar" component={Calendar} />
    <Route path="/lists" component={Lists} />
    <Route path="/assistant-rules" component={EngineRules} />
    <Route path="/settings" component={Settings} />
    <Route path="/onboarding" component={OnboardingLayout} />
    <Route path="/onboarding" component={OnboardingLayout} />
    <SmartProductsRoute path="/quotes" component={Quotes} />
    <SupportDeskRoute path="/tickets" component={Tickets} />
    <ImportAllowedRoute path="/import" component={Import} />
  </Switch>
);

export default Routes;
