import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PhoneDisabledOutlinedIcon from '@mui/icons-material/PhoneDisabledOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { destroyUserSession } from 'state/session/actions';
import {
  setTwilioDeviceDisabled,
  setupTwilioDevice,
  toggleCallModal,
} from 'components/CallDialog/state/actions';
import TwilioOnly from 'components/TwilioOnly';
import ConnectedUsers from '../ConnectedUsers';
import styles from './UserDropdown.module.scss';

export default function UserDropdown({ onHide, twilioEnabled }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const goToSettings = () => {
    history.push('/settings/user-profile');
    onHide();
  };

  const handleLogoutClick = () => {
    setLoading(true);
    dispatch(destroyUserSession()).then(() => {
      history.push('/login', { from: { pathname: '/' } });
      setLoading(false);
    });
  };

  const toggleTwilioDevice = () => {
    if (twilioEnabled) {
      dispatch(setTwilioDeviceDisabled());
      dispatch(toggleCallModal(false));
    } else {
      dispatch(setupTwilioDevice());
    }
  };

  return (
    <div className={styles.dropdown}>
      <TwilioOnly>
        <MenuItem onClick={toggleTwilioDevice} className={styles['menu-item']}>
          {twilioEnabled ? (
            <PhoneDisabledOutlinedIcon data-cy="navbar-phone-icon-disabled" />
          ) : (
            <PhoneOutlinedIcon data-cy="navbar-phone-icon" />
          )}
          <span data-cy="navbar-toggle-twilio-dropdown-item">
            {twilioEnabled ? 'Disable' : 'Enable'} Calls
          </span>
        </MenuItem>
      </TwilioOnly>
      <ConnectedUsers />
      <MenuItem onClick={goToSettings} className={styles['menu-item']}>
        <SettingsIcon data-cy="navbar-settings-icon" />
        <span data-cy="navbar-settings-dropdown-item">{t('localization.navigation.settings')}</span>
      </MenuItem>
      <MenuItem onClick={handleLogoutClick} className={styles['menu-item']}>
        <ExitToAppIcon data-cy="navbar-logout-icon" />
        <span data-cy="navbar-logout-dropdown-item">{t('localization.navigation.logout')}</span>
      </MenuItem>
      {loading && <LinearProgress />}
    </div>
  );
}

UserDropdown.propTypes = {
  onHide: PropTypes.func.isRequired,
  twilioEnabled: PropTypes.bool.isRequired,
};
