import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { success as successAlert, error as errorAlert } from 'state/notifications/actions';
import {
  minimizeCallModal,
  setCallInfo,
  setTwilioCaller,
  setVerticalAlignmentTop,
  toggleAddContactModal,
  toggleAddExistingContactModal,
} from 'components/CallDialog/state/actions';
import { updateContact } from 'services/contacts';
import { selectConversationModalOpen } from 'components/Conversation/state/selectors';
import { selectNameOfOpenModal } from 'modals/state/selectors';
import {
  selectAddContactModalVisible,
  selectAddExistingContactModalVisible,
  selectCallModalMinimized,
  selectIsTransferCallVisible,
  selectPhoneNumber,
  selectTwilioCallers,
} from 'components/CallDialog/state/selectors';
import { forEachError } from '../../../../../helpers/errorHelper';
import { createContact } from '../../../../contacts/Contacts/state/actions';

export default function useActiveCall() {
  const dispatch = useDispatch();
  const callers = useSelector(selectTwilioCallers);
  const phoneNumber = useSelector(selectPhoneNumber);
  const conversationModalOpen = useSelector(selectConversationModalOpen);
  const modalName = useSelector(selectNameOfOpenModal);
  const isMinimized = useSelector(selectCallModalMinimized);
  const newContactModalVisible = useSelector(selectAddContactModalVisible);
  const addExistingContactModalVisible = useSelector(selectAddExistingContactModalVisible);
  const isTransferCallVisible = useSelector(selectIsTransferCallVisible);
  const [sendEmailClicked, setSendEmailClicked] = useState(false);

  const expandModal = () => {
    dispatch(minimizeCallModal(false));
    dispatch(setVerticalAlignmentTop(false));
  };

  useEffect(() => {
    if (!conversationModalOpen) expandModal();
  }, [conversationModalOpen]);

  useEffect(() => {
    if (modalName === 'SEND_EMAIL_MODAL') {
      setSendEmailClicked(true);
    } else if (modalName === '' && sendEmailClicked) {
      expandModal();
      setSendEmailClicked(false);
    }
  }, [modalName]);

  const hideAddContactModal = () => {
    dispatch(toggleAddContactModal(false));
    expandModal();
  };

  const addContact = (payload) => {
    return dispatch(createContact(payload)).then((res) => {
      const caller = {
        account_name: res.account_name,
        name: `${res.first_name} ${res.last_name}`,
        resource_id: res.id,
        resource_type: 'Contact',
      };
      dispatch(setCallInfo([...callers, caller]));
      if (callers.length > 1) {
        dispatch(setTwilioCaller(caller));
      }

      hideAddContactModal();
      dispatch(successAlert('New contact created.'));
    });
  };

  const hideAddExistingContactModal = () => {
    dispatch(toggleAddExistingContactModal(false));
    expandModal();
  };

  const selectExistingContact = async (contact) => {
    try {
      const res = await updateContact(contact, { mobile_phone: phoneNumber });
      const caller = {
        account_name: res.contact.account_name,
        name: `${res.contact.first_name} ${res.contact.last_name}`,
        resource_id: res.contact.id,
        resource_type: 'Contact',
      };
      dispatch(setCallInfo([...callers, caller], caller));

      hideAddExistingContactModal();
      dispatch(successAlert('Contact updated.'));
    } catch (error) {
      forEachError(error.data, (e) => dispatch(errorAlert(e)));
    }
  };

  return {
    selectExistingContact,
    hideAddExistingContactModal,
    addContact,
    hideAddContactModal,
    isMinimized,
    newContactModalVisible,
    addExistingContactModalVisible,
    phoneNumber,
    isTransferCallVisible,
  };
}
