import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import isObject from 'lodash/isObject';
import groupBy from 'lodash/groupBy';
import accounting from 'accounting';
import htmlToText from 'html2plaintext';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Day from 'dayjs';
import Bowser from 'bowser';

Day.extend(advancedFormat);

export const FORBIDDEN_FILE_TYPES = [
  '.acm',
  '.asa',
  '.asp',
  '.aspx',
  '.ax',
  '.bat',
  '.cer',
  '.chm',
  '.clb',
  '.cmd',
  '.cnt',
  '.cnv',
  '.com',
  '.cpl',
  '.cpx',
  '.crt',
  '.dll',
  '.drv',
  '.exe',
  '.fon',
  '.grp',
  '.hlp',
  '.hta',
  '.ime',
  '.inf',
  '.ins',
  '.isp',
  '.its',
  '.js',
  '.jse',
  '.lnk',
  '.msc',
  '.msi',
  '.msp',
  '.mst',
  '.mui',
  '.nls',
  '.ocx',
  '.pal',
  '.pcd',
  '.pif',
  '.reg',
  '.scf',
  '.scr',
  '.sct',
  '.shb',
  '.shs',
  '.sys',
  '.tlb',
  '.tsp',
  '.url',
  '.vb',
  '.vbe',
  '.vbs',
  '.vsmacros',
  '.ws',
  '.wsc',
  '.wsf',
  '.wsh',
  '.swf',
  '.php',
  '.htaccess',
  '.rb',
  '.py',
  '.key',
  '.pl',
  '.ps1',
  '.svg',
  '.sh',
];

export const resourceTypes = {
  OPPORTUNITY: 'Opportunity',
  CONTACT: 'Contact',
  ACCOUNT: 'Account',
  ACTIVITY: 'Activity',
  NOTIFICATION: 'Notification',
  INTERACTION: 'Interaction',
  USER: 'User',
  REMINDER: 'Reminder',
  TICKET: 'Ticket',
  LIST: 'List',
  PRODUCT: 'Product',
  QUOTE: 'Quote',
};

export const decimalNumbersFormatByLocale = {
  // es: '0,0.[0000000000]',
  es: '0.0,[0000000000]',
  en: '0.0,[0000000000]',
};

export function joinClassNames(classNames) {
  return classNames.length > 1 ? classNames.join(' ') : classNames.toString();
}

// TODO: Should be removed in the future
export function getBrowser() {
  const { browser } = Bowser.parse(window.navigator.userAgent);

  return {
    name: browser.name,
    version: browser.version,
    isChrome: browser.name === 'Chrome',
    isSafari: browser.name === 'Safari',
  };
}

// TODO: Should be removed in the future
export function getBrowserInfoBy(userAgent) {
  const { browser } = Bowser.parse(userAgent);

  return {
    name: browser.name,
    version: browser.version,
    isChrome: browser.name === 'Chrome',
    isSafari: browser.name === 'Safari',
  };
}

// Mozilla rounds subpixes, but Chrome just truncates
// TODO: Should be removed and replaced with a better solution
export function roundPixelValue(value, browser) {
  return browser.isChrome ? parseInt(value, 10) : Math.round(value);
}

// TODO: Should be moved to a separate, money formatter object
export function formatCurrency(amount, currency, locale = 'en') {
  accounting.settings.currency.format = { pos: '%s %v', neg: '-%s %v', zero: '%s %v ' };
  if (locale === 'es') {
    return accounting.formatMoney(amount, currency, 2, '.', ',');
  }
  return accounting.formatMoney(amount, currency);
}

export function isCustomDisplayField(displayField) {
  return displayField.startsWith('custom__');
}

export function toCamelCase(string) {
  return camelCase(string);
}

export function capitalizeEveryWord(string) {
  return string.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

export function isFieldEditable(field) {
  return field.edit === '1';
}

export function replaceNewLinesWithBreakRules(string) {
  if (!string) return '';
  return string.replace(/(?:\r\n|\r|\n)/g, '<br />') || string;
}

export function generateTooltipId(text) {
  return `${snakeCase(text)}-tooltip`;
}

export function optionsChanged(options, value) {
  return options.length > 0 && !options.find((o) => o.value === value);
}

export function optionsChangedInMultipleSelect(options, value) {
  const newOptions = options.map((o) => String(o.value));
  const currentValue = value || '';
  const newVal = currentValue.split(',');

  return !newVal.every((r) => newOptions.includes(r));
}

export function arrayToObjectWithEmptyValues(array, key) {
  return Object.assign({}, ...array.map((item) => ({ [item[key]]: '' })));
}

export function getParams(params, mergeParams = {}, searchProp = 'subject') {
  const mergedParams = {
    ...params,
    ...mergeParams,
  };

  if (params.q) {
    mergedParams.q = `${searchProp}:*${params.q}*`;
  }

  return mergedParams;
}

export function removeArrayElementAtIndex(array, index) {
  return array.filter((element, i) => i !== index);
}

export const stringCutoff = (string, length) => {
  return string.length > length ? `${string.substring(0, length)}...` : string;
};

export function getMentionsIDsFromText(mentions, text) {
  const mentionsIDs = [];

  mentions.forEach((m) => {
    if (text.includes(m.markup)) {
      mentionsIDs.push(m.id);
    }
  });

  return mentionsIDs;
}

export function replaceMentionWithUser(mentionedUserIDs, users, text, { isListView }) {
  if (!mentionedUserIDs.length) return isListView ? htmlToText(text) : text;

  let txt = text;
  const names = [];

  mentionedUserIDs.forEach((mentionedUserID) => {
    const user = users.find((u) => u.id === mentionedUserID);

    if (user) {
      txt = txt.replaceAll(`@[${user.fullName}](${user.id})`, user.fullName);
      names.push(user.fullName);
    }
  });

  const regex = new RegExp(`(${names.toString().replace(/,/g, '|')})`);

  const parts = txt.split(regex);

  return (
    <div className={isListView ? 'text-truncate' : ''}>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <span
              style={{ backgroundColor: 'rgb(0, 169, 224, 0.3)', borderRadius: 4, padding: 1 }}
              key={i}
            >
              {part}
            </span>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </div>
  );
}

export function createMentionsArrayFromMentionsUserIDs(mentionedUserIDs, users) {
  const mentions = [];

  mentionedUserIDs.forEach((id) => {
    const user = users.find((u) => u.id === id);

    if (user) {
      mentions.push({
        id,
        markup: `@[${user.fullName}](${user.id})`,
      });
    }
  });

  return mentions;
}

export function stripDialCodeForEmptyNumber(number, dialCodes = []) {
  if (!number) return null;
  if (number === '+') return null;
  if (dialCodes.includes(number.substring(1))) return null;

  return number;
}

export function snakeToCamel(string) {
  return string.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );
}

export function convertSnakeCaseToCamelCaseObject(responseData) {
  const propertiesArray = Object.keys(responseData);

  return propertiesArray.reduce((model, property) => {
    const newModel = model;
    const camelCaseProperty = toCamelCase(property);

    if (isObject(responseData[property])) {
      const nestedModel = convertSnakeCaseToCamelCaseObject(responseData[property]);
      newModel[camelCaseProperty] = nestedModel;
      return newModel;
    }
    newModel[camelCaseProperty] = responseData[property];
    return newModel;
  }, {});
}

export function isInt(value) {
  return parseFloat(value) === parseInt(value, 10) && !Number.isNaN(value);
}

export function mapAttachments(attachments) {
  return attachments.map((attachment) => ({
    link: attachment,
    title: attachment.substring(attachment.lastIndexOf('/') + 1),
  }));
}

export function convertNameToInitials(fullName) {
  if (!fullName) return '';

  return fullName
    .split(' ')
    .slice(0, 2)
    .map((i) => i[0]);
}

export const isScrolledToTheBottom = (target = {}) => {
  const diff = Math.round(target.scrollHeight - target.scrollTop);
  return diff - 35 <= target.clientHeight;
};

export default {
  FORBIDDEN_FILE_TYPES,
  formatCurrency,
  stringCutoff,
};

export function groupItemsByDate(items, key = 'created_at') {
  return groupBy(items, (item) => {
    return new Day(item[key]).format('ddd[, ]MMM Do');
  });
}

export function getIdsFromValue(string) {
  let array = new Set();
  if (string.indexOf('@[') > -1) {
    array = Array.from(string.matchAll(/\(([^)]+)\)/g), (x) => Number(x[1]));
    return [...new Set(array)];
  }
  return [];
}

export function compareAndKeepCommonIds(first, second) {
  const spreaded = [...first, ...second];
  return spreaded.filter((e, i, a) => a.indexOf(e) !== i);
}

export function flattenObject(obj) {
  const flattenedObj = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      Object.assign(flattenedObj, value);
    } else {
      flattenedObj[key] = value;
    }
  });

  return flattenedObj;
}
