import PropTypes from 'prop-types';
import ChatHead from './ChatHead';
import ChatContent from './ChatContent';
import styles from './ChatItem.module.scss';

export default function ChatItem({
  contactName,
  userName,
  text,
  mediaUrls,
  inbound,
  time,
  ...props
}) {
  return (
    <div className={styles.item}>
      <ChatHead name={contactName} position="left" hidden={!inbound} />
      <ChatContent
        text={text}
        position={inbound ? 'left' : 'right'}
        mediaUrls={mediaUrls}
        time={time}
        {...props}
      />
      <ChatHead name={userName} position="right" hidden={inbound} />
    </div>
  );
}

ChatItem.defaultProps = {
  mediaUrls: [],
};

ChatItem.propTypes = {
  contactName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  inbound: PropTypes.bool.isRequired,
  mediaUrls: PropTypes.array,
  time: PropTypes.string.isRequired,
};
