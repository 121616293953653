import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { selectIsTransferCallVisible, selectTwilioConnection } from '../state/selectors';
import { toggleTransferCall } from '../state/actions';
import styles from './TransferCallButton.module.scss';

const TransferCallButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isTransferCallVisible = useSelector(selectIsTransferCallVisible);
  const connection = useSelector(selectTwilioConnection);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const toggleStartTransferCall = () => {
    dispatch(toggleTransferCall(!isTransferCallVisible));
    setAnchorEl(null);
  };

  const toggleDropdown = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!connection) return null;

  return (
    <div className={styles['transfer-call-button']}>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={toggleDropdown}
        size="large"
      >
        <MoreVertIcon />
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              marginTop: '50px',
              width: '300px',
              boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
              border: '1px solid #E6E6E6',
            },
          }}
        >
          <MenuItem onClick={toggleStartTransferCall}>
            <ListItemIcon>
              <PhoneForwardedOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Transfer Call</ListItemText>
          </MenuItem>
        </Menu>
      </IconButton>
    </div>
  );
};

export default TransferCallButton;
