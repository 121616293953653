export const STORE_ACCOUNT = 'account: store_account';
export const FETCH_ACCOUNT_STARTED = 'account: fetch_account_started';
export const RESET_STATE = 'account: reset_state';
export const SHOW_ACCOUNT_DELETE_MODAL = 'account: show_delete_modal';
export const HIDE_ACCOUNT_DELETE_MODAL = 'account: hide_delete_modal';
export const ACCOUNT_DELETE_STARTED = 'account: delete_started';
export const ACCOUNT_DELETE_SUCCESS = 'account: delete_success';

export default {
  STORE_ACCOUNT,
  FETCH_ACCOUNT_STARTED,
  RESET_STATE,
  SHOW_ACCOUNT_DELETE_MODAL,
  HIDE_ACCOUNT_DELETE_MODAL,
  ACCOUNT_DELETE_STARTED,
  ACCOUNT_DELETE_SUCCESS,
};
