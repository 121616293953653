import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import { fetchTickets, handleCreateTicket } from './actions';

const initialState = {
  data: [],
  fetching: false,
  isCreating: false,
  isModalVisible: false,
  isCreateInProgress: false,
  meta: nullMeta,
  params: {
    page: 1,
    per_page: 25,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    query: undefined,
  },
};

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setNewTicketModalVisible: (state, { payload }) => {
      return { ...state, isModalVisible: payload };
    },
    replaceTicket: (state, { payload }) => {
      const tickets = state.data;
      const index = tickets.map((ticket) => ticket.id).indexOf(payload.ticket.id);

      const newState = tickets
        .slice(0, index)
        .concat(payload.ticket)
        .concat(tickets.slice(index + 1));
      return { ...state, data: newState };
    },
    clearState: () => initialState,
  },
  extraReducers: {
    [fetchTickets.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [fetchTickets.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: payload.tickets,
        fetching: false,
        meta: {
          ...state.meta,
          ...parseMetaFromResponse(payload.meta),
        },
        params: {
          ...state.params,
          ...payload.params,
        },
      };
    },
    [fetchTickets.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [handleCreateTicket.pending]: (state) => {
      return { ...state, isCreateInProgress: true };
    },
    [handleCreateTicket.fulfilled]: (state) => {
      return { ...state, isCreateInProgress: false, isModalVisible: false };
    },
    [handleCreateTicket.rejected]: (state) => {
      return { ...state, isCreateInProgress: false };
    },
  },
});

export const { setNewTicketModalVisible, replaceTicket, clearState } = ticketsSlice.actions;

export default ticketsSlice.reducer;
