import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import SupportDeskProductOnly from 'components/SupportDeskProductOnly';
import UnauthorizedAccess from '../UnauthorizedAccess';

function SupportDeskRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <SupportDeskProductOnly
          fallback={<UnauthorizedAccess message="You don't have permission to visit this page." />}
        >
          <Component {...props} />
        </SupportDeskProductOnly>
      )}
    />
  );
}

SupportDeskRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default SupportDeskRoute;
