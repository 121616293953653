import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AlarmIcon from '@mui/icons-material/Alarm';
import SupportDeskProductOnly from 'components/SupportDeskProductOnly';
import SmartProductsOnly from 'components/SmartProductsOnly';
import { getPathname, isLinkActive } from '../helpers';
import ReportsLink from '../ReportsLink/ReportsLink';
import { ReactComponent as Activities } from '../icons/activities-icon.svg';
import { ReactComponent as Assistant } from '../icons/assistant-icon.svg';
import { ReactComponent as TicketsIcon } from '../icons/tickets-icon.svg';
import { useStyles } from './useStyles';

const SidebarItem = ({ field }) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const pathname = getPathname(field.name);
  const linkActive = isLinkActive(pathname, location.pathname);

  const icons = {
    opportunities: <StarBorderOutlinedIcon data-cy="sidebar-opportunities-icon" />,
    contacts: <PersonOutlineIcon data-cy="sidebar-contacts-icon" />,
    companies: <HomeWorkOutlinedIcon data-cy="sidebar-companies-icon" />,
    campaigns: <FlagOutlinedIcon data-cy="sidebar-campaigns-icon" />,
    reports: <BarChartIcon data-cy="sidebar-reports-icon" />,
    lists: <ListAltIcon data-cy="sidebar-lists-icon" />,
    reminders: <AlarmIcon data-cy="sidebar-reminders-icon" />,
    assistant: <Assistant data-cy="sidebar-assistant-icon" />,
    activities: <Activities data-cy="sidebar-activities-icon" />,
    tickets: <TicketsIcon data-cy="sidebar-tickets-icon" />,
    quotes: <WysiwygIcon data-cy="sidebar-quotes-icon" />,
  };

  if (field.name === 'reports') {
    return <ReportsLink label="Analytics" name={field?.name} />;
  }

  if (field.name === 'tickets') {
    return (
      <SupportDeskProductOnly>
        <Link
          key={field.name}
          to={pathname}
          className={linkActive ? classes.sidebarItemActive : classes.sidebarItem}
          data-cy="sidebar-menu-tickets-link"
        >
          <span className={classes.icon}>{icons[field.name]}</span>
          <Typography className={classes.subheading} data-cy="sidebar-menu-tickets-label">
            Tickets
          </Typography>
        </Link>
      </SupportDeskProductOnly>
    );
  }

  if (field.name === 'quotes') {
    return (
      <SmartProductsOnly>
        <Link
          key={field.name}
          to={pathname}
          className={linkActive ? classes.sidebarItemActive : classes.sidebarItem}
          data-cy="sidebar-menu-tickets-link"
        >
          <span className={classes.icon}>{icons[field.name]}</span>
          <Typography className={classes.subheading} data-cy="sidebar-menu-tickets-label">
            Quotes
          </Typography>
        </Link>
      </SmartProductsOnly>
    );
  }

  return (
    <Link
      key={field.name}
      to={pathname}
      className={linkActive ? classes.sidebarItemActive : classes.sidebarItem}
      data-cy="sidebar-menu-item-link"
    >
      <span className={classes.icon}>{icons[field.name]}</span>
      <Typography className={classes.subheading} data-cy="sidebar-menu-item-label">
        {field.name === 'quotes' ? 'Quotes' : t(`localization.navigation.${field.name}`)}
      </Typography>
    </Link>
  );
};

SidebarItem.propTypes = {
  field: PropTypes.object.isRequired,
};

export default SidebarItem;
