export const FETCHING_USER = 'FETCHING_USER';
export const STORE_USER = 'STORE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_NOTIFICATIONS_STATUS = 'SET_NOTIFICATIONS_STATUS';
export const UPDATE_IN_PROGRESS = 'user: update in progress';
export const UPDATE_SUCCESS = 'user: update success';
export const UPDATE_FAIL = 'user: update fail';
export const UPDATE_ENABLE_PARENT_ACCOUNT = 'UPDATE_ENABLE_PARENT_ACCOUNT';

export default {
  FETCHING_USER,
  STORE_USER,
  UPDATE_USER,
  SET_NOTIFICATIONS_STATUS,
  UPDATE_IN_PROGRESS,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  UPDATE_ENABLE_PARENT_ACCOUNT,
};
