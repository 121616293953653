import moment from 'moment';
import Day from 'dayjs';
import groupBy from 'lodash/groupBy';

export function formatDate(date) {
  return date.format('MMM Do YYYY, hh:mm:ss A');
}

export function formatCallDuration(stringSeconds) {
  const callDuration = Number(stringSeconds);
  const a24Hours = 86399;

  if (callDuration <= a24Hours) {
    const time = moment.duration(callDuration, 'seconds');
    const hours = time.hours();
    const minutes = time.minutes();
    const seconds = time.seconds();

    return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds}s`;
  }

  return '24h+';
}

// export function groupInteractionsByDate(interactions) {
//   const groupedByDate = groupBy(interactions, (interaction) => {
//     const day = interaction.interactedAt.format('ddd[, ]MMM Do');
//     if (!isDayInCurrentMonthAndYear(day)) {
//       return interaction.interactedAt.format('MMMM, YYYY');
//     }
//     return day;
//   });
//   return groupedByDate;
// }

// function isDayInCurrentMonthAndYear(day) {
//   const currentMonth = moment.monthsShort(new Date().getMonth());
//   const currentYear = new Date().getFullYear();
//   if (!day.includes(currentMonth) && !day.includes(currentYear)) {
//     return false;
//   }
//   return true;
// }

export function groupInteractionsByDate(interactions) {
  return groupBy(interactions, (interaction) => {
    return new Day(interaction.interacted_at).format('ddd[, ]MMM Do');
  });
}

export function isSmsMessage(interaction) {
  return interaction.interactionType === 'SmsMessage';
}

export function isMissedCall(interaction) {
  return interaction.kind === 'missed_call';
}

export function isEvent(interaction) {
  return interaction.interactionType === 'Event';
}

export function isNote(interaction) {
  return interaction.interactionType === 'Note';
}

export function isAttachment(interaction) {
  return interaction.interactionType === 'Attachment';
}

export function isEmail(interaction) {
  return interaction.interactionType === 'Email';
}

export function isActivity(interaction) {
  return interaction.interactionType === 'Activity';
}

export function getTextColorByKind(kind) {
  return (
    {
      inbound_email: 'inbound-activity-text-color',
      meeting: 'meeting-text-color',
      inbound_call: 'inbound-activity-text-color',
      received_voicemail: 'inbound-activity-text-color',
      missed_call: 'missed-call-text-color',
      forward_call: 'forward-call-text-color',
      met_in_person: 'met-in-person-text-color',
      call_didnt_connect: 'did-not-connect-text-color',
      attachment: 'attachment-text-color',
      note: 'note-text-color',
      campaign: 'campaign-text-color',
      reminder: 'reminders-text-color',
      dismissed_reminder: 'reminders-text-color',
      completed_reminder: 'reminders-text-color',
    }[kind] || 'default-text-color'
  );
}

export function getIconByKind(kind) {
  return (
    {
      outbound_email: 'timeline-outbound-email-icon',
      inbound_email: 'timeline-inbound-email-icon',
      outbound_text: 'timeline-text-icon',
      inbound_text: 'timeline-text-icon',
      meeting: 'timeline-event-icon',
      outbound_call: 'timeline-outbound-call-icon',
      inbound_call: 'timeline-inbound-call-icon',
      received_voicemail: 'timeline-voicemail-icon',
      call_recording: '',
      missed_call: 'timeline-missed-call-icon',
      forward_call: 'timeline-forward-call-icon',
      sent_text: 'timeline-outbound-sms-icon',
      met_in_person: 'timeline-met-in-person-icon',
      left_voicemail: 'timeline-left-voicemail-icon',
      call_didnt_connect: 'timeline-didnt-connect-icon',
      attachment: 'timeline-attachment-icon',
      note: 'timeline-note-icon',
      campaign: 'campaign-v2-icon',
      reminder: 'timeline-reminder-icon',
      dismissed_reminder: 'timeline-reminder-icon',
      completed_reminder: 'timeline-reminder-icon',
      other: 'timeline-task-icon',
    }[kind] || 'timeline-task-icon'
  );
}

export function getOwnerProps(parentType, parent) {
  return {
    Account: {
      ownerType: 'Account',
      ownerId: parent?.id,
      allowEmpty: 'Assign to company',
      parent,
    },
    Opportunity: {
      ownerType: 'Opportunity',
      ownerId: parent?.id,
      allowEmpty: 'Assign to opportunity',
      parent,
    },
    Ticket: {
      ownerType: 'Ticket',
      ownerId: parent?.id,
      allowEmpty: 'Assign to ticket',
      parent,
    },
    Contact: {},
  }[parentType];
}

export function isValidUrl(string) {
  try {
    return Boolean(new URL(string));
  } catch (e) {
    return false;
  }
}

export function isImageAttachment(url) {
  return /(https?:\/\/.*\.(?:png|jpg))/i.test(url);
}

export function mapInteraction(interaction, dispositionValues) {
  const dispositionValue = dispositionValues.find((item) => item.value === interaction.kind);
  const name = dispositionValue ? dispositionValue.label : interaction.displayName;

  return {
    ...interaction,
    displayName: name,
  };
}

export function isDescriptionExpanded(type) {
  return [
    'inbound_call',
    'outbound_call',
    'missed_call',
    'forward_call',
    'call_didnt_connect',
    'received_voicemail',
    'left_voicemail',
    'note',
  ].includes(type);
}
