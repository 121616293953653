import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FileUploadIcon from '@mui/icons-material/CropOriginal';
import ReactS3Uploader from 'react-s3-uploader';
import Button from 'lib/Button';
import TextField from 'lib/TextField';
import { getPhoneTypeFromContact } from 'helpers/contacts';
import { getAll as getTemplates } from 'services/sms-templates';
import Select from 'lib/Select';
import UploadFilesPreview from './UploadFilesPreview';
import { getPayload } from './helpers';
import useFileUpload from './useFileUpload';
import styles from './MessageForm.module.scss';

const MessageForm = ({ contact, className, onSuccess, createMessage }) => {
  const uploaderRef = useRef(null);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    body: '',
    contact_id: contact.id,
    phone_type: getPhoneTypeFromContact(contact),
    message_type: 'sms',
  });
  const {
    files,
    fileUrls,
    uploading,
    progress,
    getSignedUrl,
    onUploadStart,
    onUploadProgress,
    onUploadError,
    onUploadFinish,
    removeFile,
    resetUploader,
  } = useFileUpload(uploaderRef);

  useEffect(() => {
    getTemplates({ contact_id: contact.id }).then((json) => {
      setTemplates(json.sms_templates);
    });
  }, []);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearState = () => {
    setIsLoading(false);
    setSelectedTemplate('');
    setFormData({
      body: '',
      contact_id: contact.id,
      phone_type: getPhoneTypeFromContact(contact),
      message_type: 'sms',
    });
    resetUploader();
  };

  const onSendClick = () => {
    const payload = getPayload(formData, fileUrls);

    setIsLoading(true);

    return createMessage(payload)
      .then(clearState)
      .then(onSuccess)
      .catch(() => setIsLoading(false));
  };

  const onTemplateSelect = (e) => {
    setSelectedTemplate(e.target.value);
    setFormData((prevState) => ({
      ...prevState,
      body: prevState.body ? `${prevState.body}\n${e.target.value}` : e.target.value,
    }));
  };

  return (
    <div className={classNames(styles.form, className)}>
      <FormGroup>
        <Select
          name="selectedTemplate"
          label="Select template"
          value={selectedTemplate}
          onChange={onTemplateSelect}
          options={templates.map((t) => ({ label: t.title, value: t.body }))}
        />
      </FormGroup>
      <br />
      <FormGroup />
      <FormGroup>
        <TextField
          name="body"
          value={formData.body}
          disabled={isLoading}
          onChange={onChange}
          placeholder="Type a new message"
          multiline
        />
      </FormGroup>
      <footer className={styles.footer}>
        <div className={styles.row}>
          <label htmlFor="file" className={styles['file-input']}>
            <div className="flex-container align-items-center">
              <FileUploadIcon />
              {uploading && progress < 100 && (
                <span className={styles['upload-progress']}>Uploading image {progress} %</span>
              )}
            </div>
            <ReactS3Uploader
              ref={uploaderRef}
              id="file"
              name="file"
              className={styles.uploader}
              disabled={isLoading}
              getSignedUrl={getSignedUrl}
              preprocess={onUploadStart}
              onProgress={onUploadProgress}
              onError={onUploadError}
              onFinish={onUploadFinish}
              // this is to prevent sending 'x-amz-acl': 'public-read' for security reasons
              uploadRequestHeaders={{}}
            />
          </label>
          <Button color="primary" variant="contained" onClick={onSendClick} disabled={isLoading}>
            Send
          </Button>
        </div>
        <div>
          {/* {uploading && (
            <p className="message-form__upload-progress">Uploading image {progress} %</p>
          )} */}
          {files.length > 0 && <UploadFilesPreview files={files} onRemove={removeFile} />}
        </div>
      </footer>
    </div>
  );
};

MessageForm.defaultProps = {
  className: '',
};

MessageForm.propTypes = {
  contact: PropTypes.object.isRequired,
  createMessage: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default MessageForm;
