import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUsers, updateUser as updateUserRemote } from 'services/users';
import { serialize } from 'helpers/user';
import { error as notifyError } from 'state/notifications/actions';
import { parse } from 'helpers/users';
import { serializeFiltersToString } from 'helpers/filters';
import { fetchFilterProperties, fetchSavedFilters } from '../filters/users/actions';
import { forEachError } from '../../../helpers/errorHelper';

function getParamsFromState(state) {
  const params = state.usersAdministration.params;
  const currentFilters = state.filters.users.currentFilters;

  return {
    ...params,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export const fetchUsers = createAsyncThunk(
  'users-get',
  async (params = {}, { dispatch, getState }) => {
    try {
      await dispatch(fetchFilterProperties());
      await dispatch(fetchSavedFilters());
      const stateParams = await getParamsFromState(getState());

      const payload = { ...stateParams, ...params };
      const res = await getUsers(payload);

      return Promise.resolve({ ...res, params: payload });
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

export const updateUser = createAsyncThunk(
  'user-update',
  async (payload, { dispatch, getState }) => {
    try {
      const res = await updateUserRemote(payload.userId, serialize(payload.payload));
      const users = getState().usersAdministration.users;
      const index = users.map((u) => u.id).indexOf(res.id);

      const data = users
        .slice(0, index)
        .concat(parse(res))
        .concat(users.slice(index + 1));
      return Promise.resolve({ data });
    } catch (err) {
      forEachError(err.data, (e) => dispatch(notifyError(e)));
      return Promise.reject(err);
    }
  }
);
