import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Tabs from 'lib/Tabs';
import Tab from 'lib/Tab';
import Drawer from 'lib/Drawer';
import useTabs from 'hooks/useTabs';
import { selectIsOpen } from 'state/app-notifications/selectors';
import { hideNotificationsSlider } from 'state/app-notifications/actions';
import { selectAlerts } from 'state/alerts/selectors';
import { ReactComponent as Icon } from './notifications-icon.svg';
import Texts from './Texts';
import MissedCalls from './MissedCalls';
import Alerts from './Alerts';
import styles from './AppNotifications.module.scss';

function AppNotifications() {
  const dispatch = useDispatch();
  const [selectedTab, selectTab] = useTabs('1');
  const isOpen = useSelector(selectIsOpen);
  const alerts = useSelector(selectAlerts);
  const hasUnreadText = useSelector((state) => state.counters.unreadSMS);
  const hasMissedCalls = useSelector((state) => state.counters.missedCalls);

  const closeSlider = () => {
    dispatch(hideNotificationsSlider());
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={closeSlider} className={styles.drawer}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Icon />
          <Typography variant="h5">Notifications</Typography>
        </div>
        <IconButton aria-label="close" onClick={closeSlider} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <TabContext value={selectedTab}>
        <Tabs value={selectedTab} onChange={selectTab} className={styles.tabs}>
          <Tab
            value="1"
            label={
              hasUnreadText > 0 ? (
                <Badge badgeContent="" classes={{ badge: styles.badge }}>
                  Texts
                </Badge>
              ) : (
                'Texts'
              )
            }
          />
          <Tab
            value="2"
            label={
              hasMissedCalls > 0 ? (
                <Badge badgeContent="" classes={{ badge: styles.badge }}>
                  Calls
                </Badge>
              ) : (
                'Calls'
              )
            }
          />
          <Tab
            value="3"
            label={
              alerts.length > 0 ? (
                <Badge badgeContent="" classes={{ badge: styles.badge }}>
                  Alerts
                </Badge>
              ) : (
                'Alerts'
              )
            }
          />
        </Tabs>
        <div className={styles.body}>
          <TabPanel value="1" className={styles.tabPanel}>
            <Texts />
          </TabPanel>
          <TabPanel value="2" className={styles.tabPanel}>
            <MissedCalls />
          </TabPanel>
          <TabPanel value="3" className={styles.tabPanel}>
            <Alerts />
          </TabPanel>
        </div>
      </TabContext>
    </Drawer>
  );
}

export default AppNotifications;
