class Account {
  constructor(result) {
    this.id = result.id;
    this.name = result.highlight.name || result.highlight.raw_name || result.name;
    this.type = result.type;
    this.address = result.highlight.address || result.address;
    this.country = result.highlight.country || result.country;
    this.city = result.highlight.city || result.city;
    this.state = result.highlight.state || result.state;
    this.zipcode = result.highlight.zipcode || result.zipcode;
    this.domain = result.highlight.domain || result.domain || '';
    this.phone = result.highlight.phone;
    this.externalID = result.highlight.external_id;
    this.customText = result.highlight.custom_text_part || '';
  }

  getName() {
    return this.name.toString();
  }

  getDomain() {
    return this.domain.toString();
  }
}

export default Account;
