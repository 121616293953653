import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/contacts/actions';
import { parseMetaFromResponse } from '../../../helpers/meta';
import {
  getContacts as getContactsRemote,
  createContact as createContactRemote,
  updateContact as updateContactRemote,
} from '../../../services/contacts';
import { forEachError } from '../../../../helpers/errorHelper';
import { serializeForm } from '../../../../helpers/formHelper';
import { error as errorAlert } from '../../../state/notifications/actions';
import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  SORT_CONTACTS,
  REPLACE_CONTACT,
  SET_PARAMS,
  RESET_DATA,
  STORE_CONTACTS,
  ADD_CONTACT_TO_LIST,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  RESET_CONTACTS_LOADING_PROGRESS,
} from './constants';
import { serializeFiltersToString } from '../../../helpers/filters';

export function requestStarted() {
  return { type: REQUEST_STARTED };
}

export function requestSuccess(json) {
  return {
    type: REQUEST_SUCCESS,
    data: json.contacts,
    meta: parseMetaFromResponse(json.meta),
  };
}

export function requestFailed(json) {
  return {
    type: REQUEST_FAILED,
    errors: json.errors,
  };
}

export function createInProgress() {
  return {
    type: CREATE_IN_PROGRESS,
  };
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  };
}

export function createFailure() {
  return {
    type: CREATE_FAILURE,
  };
}

export function sortList(sortColumn, sortOrder) {
  return {
    type: SORT_CONTACTS,
    sortColumn,
    sortOrder,
  };
}

export function replaceContact(contact) {
  return { type: REPLACE_CONTACT, contact };
}

export function storeContacts(contacts) {
  return {
    type: STORE_CONTACTS,
    contacts,
  };
}

export function setParams(data) {
  return {
    type: SET_PARAMS,
    data,
  };
}

export function resetData() {
  return {
    type: RESET_DATA,
  };
}

export function addContactToList(contact) {
  return {
    type: ADD_CONTACT_TO_LIST,
    contact,
  };
}

export function resetLoadingProgress() {
  return {
    type: RESET_CONTACTS_LOADING_PROGRESS,
  };
}

function getParamsFromState(state) {
  const params = state.contacts.params;
  const currentFilters = state.filters.contacts.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.Contact;

  const paramsObj = params.sort
    ? params
    : { ...params, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export function getContacts(params = {}) {
  return (dispatch, getState) => {
    dispatch(requestStarted());

    dispatch(fetchFilterProperties());
    return dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      return getContactsRemote(payload)
        .then((json) => {
          dispatch(requestSuccess(json));
          dispatch(setParams(payload));
          return Promise.resolve(json);
        })
        .catch((err) => Promise.reject(err));
    });
  };
}

export function createContact(payload) {
  return (dispatch) => {
    dispatch(createInProgress());

    return createContactRemote(payload)
      .then((json) => {
        if (json.contact.matched) {
          dispatch(createFailure());
          dispatch(
            errorAlert(`This contact with the same email address already exists:`, json.contact)
          );
          return Promise.reject(json);
        }

        dispatch(createSuccess());
        return json.contact;
      })
      .catch((err) => {
        dispatch(createFailure());
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
  };
}

export function updateContact(contactId, payload = {}) {
  return (dispatch) =>
    updateContactRemote(contactId, serializeForm(payload))
      .then((json) => {
        dispatch(replaceContact(json.contact));
        return json;
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
}
