import PropTypes from 'prop-types';
import cs from 'classnames';
import './BouncingDots.scss';

const BouncingDots = ({ className, white, grey, black, sm, lg }) => (
  <div
    className={cs('loader-bouncing-dots', className, {
      'loader-bouncing-dots--white': white,
      'loader-bouncing-dots--grey': grey,
      'loader-bouncing-dots--black': black,
      'loader-bouncing-dots--sm': sm,
      'loader-bouncing-dots--lg': lg,
    })}
  >
    <div className="loader-bouncing-dots__child loader-bouncing-dots__child--bounce1" />
    <div className="loader-bouncing-dots__child loader-bouncing-dots__child--bounce2" />
    <div className="loader-bouncing-dots__child loader-bouncing-dots__child--bounce3" />
  </div>
);

BouncingDots.defaultProps = {
  className: '',
  white: true,
  grey: false,
  black: false,
  sm: false,
  lg: false,
};

BouncingDots.propTypes = {
  className: PropTypes.string,
  white: PropTypes.bool,
  grey: PropTypes.bool,
  black: PropTypes.bool,
  sm: PropTypes.bool,
  lg: PropTypes.bool,
};

export default BouncingDots;
