import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ActiveCall from './ActiveCall';
import IncomingCallModal from './IncomingCallModal';
import useCallDialogModal from './useCallDialogModal';
import {
  selectActiveCallModalOpen,
  selectIsIncomingCall,
  selectToggleCallModal,
  selectTwilioConnection,
} from '../state/selectors';

export default function CallDialogModal() {
  const connection = useSelector(selectTwilioConnection);
  const isActiveCallModalOpen = useSelector(selectActiveCallModalOpen);
  const isCallModalOpen = useSelector(selectToggleCallModal);
  const incomingCall = useSelector(selectIsIncomingCall);
  useCallDialogModal();

  if ((!connection && !isActiveCallModalOpen) || isCallModalOpen) {
    return null;
  }

  return (
    <Box>
      {isActiveCallModalOpen && !incomingCall && <ActiveCall />}
      {incomingCall && connection && <IncomingCallModal />}
    </Box>
  );
}
