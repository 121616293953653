import PropTypes from 'prop-types';
import classNames from 'classnames';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: '10px',
    marginTop: '15px',
  },
}));

function ModalHeader({ children, divider, className, ...props }) {
  const classes = useStyles();
  return (
    <DialogTitle {...props} className={classNames([className])}>
      {children}
      {divider && <Divider className={classes.divider} />}
    </DialogTitle>
  );
}

ModalHeader.defaultProps = {
  className: '',
  divider: false,
};

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  divider: PropTypes.bool,
};

export default ModalHeader;
