import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import Box from '@mui/material/Box';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import Button from 'lib/Button';
import { displayDialpad } from '../state/actions';
import RejectCall from '../RejectCall/RejectCall';
import AcceptCall from '../AcceptCall/AcceptCall';
import styles from './Dialpad.module.scss';
import { selectActiveCallModalOpen, selectTwilioConnection } from '../state/selectors';

const KEYS = [
  [
    { label: '1', value: '1', alt: '' },
    { label: '2', value: '2', alt: 'A B C' },
    { label: '3', value: '3', alt: 'D E F' },
  ],
  [
    { label: '4', value: '4', alt: 'G H I' },
    { label: '5', value: '5', alt: 'J K L' },
    { label: '6', value: '6', alt: 'M N O' },
  ],
  [
    { label: '7', value: '7', alt: 'P Q R S' },
    { label: '8', value: '8', alt: 'T U V' },
    { label: '9', value: '9', alt: 'W X Y Z' },
  ],
  [
    { label: '*', value: '*', alt: '' },
    { label: '0', value: '0', alt: '+' },
    { label: '#', value: '#', alt: '' },
  ],
];

export default function Dialpad({ onKeyClick, children, handleDelete }) {
  const dispatch = useDispatch();
  const isActiveCallModalOpen = useSelector(selectActiveCallModalOpen);
  const connection = useSelector(selectTwilioConnection);

  const renderKey = (key) => (
    <Button
      key={key.value}
      variant="outlined"
      className={styles['dialpad-key']}
      onClick={() => onKeyClick(key.value)}
    >
      <div className={styles.content}>
        <span className={styles.primary}>{key.label}</span>
        <span className={styles.alt}>{key.alt}</span>
      </div>
    </Button>
  );

  const renderGroup = (group) => (
    <div key={uuid.v4()} className={styles['dialpad-keys-row']}>
      {group.map((key) => renderKey(key))}
    </div>
  );

  const renderKeypad = () => KEYS.map((group) => renderGroup(group));

  return (
    <div className={styles['dialpad-wrapper']}>
      {renderKeypad()}
      {children}
      <Box className={styles['call-actions']}>
        {connection ? <RejectCall /> : <AcceptCall />}
        {isActiveCallModalOpen && connection && (
          <Button
            className={styles['hide-button']}
            onClick={() => {
              dispatch(displayDialpad(false));
            }}
          >
            HIDE
          </Button>
        )}
        {!connection && (
          <BackspaceOutlinedIcon className={styles['backspace-button']} onClick={handleDelete} />
        )}
      </Box>
    </div>
  );
}

Dialpad.defaultProps = {
  children: null,
  handleDelete: () => {},
};

Dialpad.propTypes = {
  children: PropTypes.node,
  onKeyClick: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
};
