import { nullMeta } from 'helpers/meta';
import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  SORT_CONTACTS,
  REPLACE_CONTACT,
  SET_PARAMS,
  RESET_DATA,
  STORE_CONTACTS,
  ADD_CONTACT_TO_LIST,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  RESET_CONTACTS_LOADING_PROGRESS,
} from './constants';

const initialState = {
  requestStarted: false,
  data: [],
  errors: [],
  requestFailed: false,
  requestSuccess: false,
  createInProgress: false,
  loadingProgress: 0,
  meta: nullMeta,
  params: {
    page: 1,
    per_page: 25,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    query: undefined,
  },
};

function requestStartedHandler(state) {
  return {
    ...state,
    requestStarted: true,
    requestFailed: false,
    requestSuccess: false,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: false,
    requestSuccess: true,
    data: action.data,
    meta: action.meta,
    loadingProgress: 100,
  };
}

function requestFailedHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: true,
    requestSuccess: false,
    errors: action.errors,
  };
}

function sortContactsHandler(state, action) {
  return {
    ...state,
    sortColumn: action.sortColumn,
    sortOrder: action.sortOrder,
  };
}

function replaceContactHandler(state, action) {
  return {
    ...state,
    data: state.data.map((contact) => {
      if (contact.id === action.contact.id) return action.contact;
      return contact;
    }),
  };
}

function storeContactsHandler(state, action) {
  return { ...state, data: action.contacts };
}

function addContactToListHandler(state, action) {
  return { ...state, data: [action.contact, ...state.data] };
}

export function setParamsHandler(state, action) {
  return { ...state, params: { ...state.params, ...action.data } };
}

export function resetDataHandler(state) {
  return { ...state, data: [] };
}

export function createInProgressHandler(state) {
  return { ...state, createInProgress: true };
}

export function createSuccessHandler(state) {
  return { ...state, createInProgress: false };
}

export function createFailureHandler(state) {
  return { ...state, createInProgress: false };
}

function resetLoadingProgressHandler(state) {
  return { ...state, loadingProgress: 0 };
}

const ACTION_HANDLERS = {
  [REQUEST_STARTED]: requestStartedHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REQUEST_FAILED]: requestFailedHandler,
  [SORT_CONTACTS]: sortContactsHandler,
  [REPLACE_CONTACT]: replaceContactHandler,
  [STORE_CONTACTS]: storeContactsHandler,
  [SET_PARAMS]: setParamsHandler,
  [RESET_DATA]: resetDataHandler,
  [CREATE_IN_PROGRESS]: createInProgressHandler,
  [CREATE_SUCCESS]: createSuccessHandler,
  [CREATE_FAILURE]: createFailureHandler,
  [ADD_CONTACT_TO_LIST]: addContactToListHandler,
  [RESET_CONTACTS_LOADING_PROGRESS]: resetLoadingProgressHandler,
};

export default function contactsDataReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
