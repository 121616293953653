import { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ImageLink.module.scss';

export default function ImageLink({ url, onLoad }) {
  const [error, setError] = useState('');

  if (error) return <i className={styles.error}>{error}</i>;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={styles['image-link']}>
      <img
        src={url}
        alt="media url"
        onError={() => {
          setError('There was an error loading an image');
        }}
        onLoad={onLoad}
      />
    </a>
  );
}

ImageLink.defaultProps = {
  onLoad: () => {},
};

ImageLink.propTypes = {
  url: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};
