import { useDispatch, useSelector } from 'react-redux';
import Day from 'dayjs';
import { createActivity as createTwilioActivity } from 'services/twilioService';
import { getCallStatus, serializeData } from 'helpers/activities';
import { getPhoneTypeFromNumber } from 'helpers/contacts';
import {
  selectFormValues,
  selectIsRecordingEnabled,
  selectPhoneNumber,
  selectPreviousCall,
  selectTicketId,
  selectTwilioAccount,
  selectTwilioContact,
} from 'components/CallDialog/state/selectors';
import { selectUserId } from 'state/user/selectors';
import { resetTwilioState } from 'components/CallDialog/state/actions';
import { error as errorAlert } from 'state/notifications/actions';
import { getActivityDispositionValues } from 'state/custom-fields/selectors';
import { setIsRefreshRequired } from 'state/nested-interactions/actions';

export default function useLogActiviti() {
  const phoneNumber = useSelector(selectPhoneNumber);
  const previousCall = useSelector(selectPreviousCall);
  const userId = useSelector(selectUserId);
  const contact = useSelector(selectTwilioContact);
  const account = useSelector(selectTwilioAccount);
  const dispositionValues = useSelector(getActivityDispositionValues);
  const { callNotes, disposition, custom, subject, mentionsIDs } = useSelector(selectFormValues);
  const ticketId = useSelector(selectTicketId);
  const isRecordingEnabled = useSelector(selectIsRecordingEnabled);
  const dispatch = useDispatch();

  const createActivity = async (payload) => {
    try {
      const activityPayload = serializeData(payload);
      if (!isRecordingEnabled) activityPayload.disable_recordings = '1';
      await createTwilioActivity(activityPayload);
      dispatch(resetTwilioState());
    } catch (error) {
      dispatch(errorAlert(error.statusText));
    }
  };

  const generateDescription = (name, dispositionValue) => {
    let description = dispositionValues.find((d) => d.value === dispositionValue).body;
    description = description.replace('<ContactName>', name);

    if (phoneNumber && contact) {
      const phoneType = getPhoneTypeFromNumber(phoneNumber, contact);
      description += `\n\n${phoneType.toUpperCase()} Phone: ${phoneNumber}`;
    } else if (phoneNumber && account) {
      description += `\n\nPhone: ${phoneNumber}`;
    }

    if (!callNotes) return description;

    return `${callNotes}\n\n${description}`;
  };

  const generateSubject = (name, dispositionValue) => {
    if (subject) return subject;
    const activitySubject = dispositionValues.find((d) => d.value === dispositionValue).subject;
    return activitySubject.replace('<ContactName>', name);
  };

  const handleLeftVoicemail = async () => {
    const payload = {
      userId,
      ownerId: !ticketId ? previousCall.caller.resource_id : ticketId,
      ownerType: !ticketId ? previousCall.caller.resource_type : 'Ticket',
      subject: generateSubject(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'left_voicemail'
      ),
      description: generateDescription(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'left_voicemail'
      ),
      mentionsIDs,
      result: 'result_none',
      disposition: 'left_voicemail',
      callStatus: getCallStatus(dispositionValues, 'left_voicemail'),
      interactedAt: new Day().format(),
      call_sid: previousCall.callSid,
      custom,
    };
    await createActivity(payload);
    dispatch(setIsRefreshRequired(true));
  };

  const handleConnected = async () => {
    const payload = {
      userId,
      ownerId: !ticketId ? previousCall.caller.resource_id : ticketId,
      ownerType: !ticketId ? previousCall.caller.resource_type : 'Ticket',
      subject: generateSubject(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'outbound_call'
      ),
      description: generateDescription(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'outbound_call'
      ),
      mentionsIDs,
      result: 'result_none',
      disposition: 'outbound_call',
      callStatus: getCallStatus(dispositionValues, 'outbound_call'),
      interactedAt: new Day().format(),
      call_sid: previousCall.callSid,
      custom,
    };
    await createActivity(payload);
    dispatch(setIsRefreshRequired(true));
  };

  const handleNotConnected = async () => {
    const payload = {
      userId,
      ownerId: !ticketId ? previousCall.caller.resource_id : ticketId,
      ownerType: !ticketId ? previousCall.caller.resource_type : 'Ticket',
      subject: generateSubject(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'no_connect'
      ),
      description: generateDescription(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'no_connect'
      ),
      mentionsIDs,
      result: 'result_none',
      callStatus: getCallStatus(dispositionValues, 'no_connect'),
      disposition: 'no_connect',
      interactedAt: new Day().format(),
      call_sid: previousCall.callSid,
      custom,
    };

    await createActivity(payload);
    dispatch(setIsRefreshRequired(true));
  };

  const handleIncomingCall = async () => {
    const payload = {
      userId,
      ownerId: previousCall.caller.resource_id,
      ownerType: previousCall.caller.resource_type,
      subject: generateSubject(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'inbound_call'
      ),
      description: generateDescription(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'inbound_call'
      ),
      mentionsIDs,
      result: 'result_none',
      callStatus: getCallStatus(dispositionValues, 'inbound_call'),
      disposition: 'inbound_call',
      interactedAt: new Day().format(),
      call_sid: previousCall.callSid,
      custom,
    };

    await createActivity(payload);
    dispatch(setIsRefreshRequired(true));
  };

  const logActivity = () => {
    switch (disposition) {
      case 'left_voicemail':
        handleLeftVoicemail();
        break;
      case 'no_connect':
        handleNotConnected();
        break;
      case 'outbound_call':
        handleConnected();
        break;
      default:
        handleIncomingCall();
    }
  };

  return { logActivity };
}
