import { combineReducers } from '@reduxjs/toolkit';
import interactions from './interactions/reducer';
import contacts from './contacts/reducer';
import accounts from './accounts/reducer';
import opportunities from './opportunities/reducer';
import reminders from './reminders/reducer';
import customObjects from './custom-objects/reducer';
import campaigns from './campaigns/reducer';
import assistantCampaigns from './campaign-contacts/reducer';
import addContacts from './add-contacts/reducer';
import nestedInteractions from './nested-interactions/reducer';
import users from './users/reducer';
import importReducer from './import/reducer';
import assistant from './assistant/reducer';
import nestedFilters from './nested-filters/reducer';
import quotes from './quotes/reducer';
import products from './products/reducer';

export default combineReducers({
  interactions,
  contacts,
  accounts,
  opportunities,
  reminders,
  customObjects,
  campaigns,
  assistantCampaigns,
  addContacts,
  nestedInteractions,
  users,
  import: importReducer,
  assistant,
  nestedFilters,
  quotes,
  products,
});
