import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const mentionInputClassName = 'spiro-mention-input';
export const floatingLabelClassName = 'spiro-mention-input-floating-label';

const fontSize = 16;

const getPlaceholderStyle = (isSuperscript) => ({
  top: isSuperscript ? '9px' : '18px',
  fontSize: isSuperscript ? 0.75 * fontSize : fontSize,
});

const getFloatingLabelStyles = (palette, props, transitions) => {
  let color = palette.text.secondary;
  if (props.error) {
    color = palette.error.main;
  } else if (props.disabled) {
    color = palette.text.disabled;
  }
  return {
    ...getPlaceholderStyle(true),
    left: '12px',
    color,
    fontWeight: 'normal',
    position: 'absolute',
    pointerEvents: 'none',
    transition: transitions.create('all', {
      duration: transitions.duration.shorter,
      easing: transitions.easing.easeInOut,
    }),
  };
};
export const useStyles = makeStyles(({ palette, transitions }) => {
  return createStyles({
    root: (props) => ({
      width: '100%',
      font: `normal normal normal 16px "Roboto", Helvetica, sans-serif !important`,
      paddingTop: '26px',

      [`& .${mentionInputClassName}__control textarea`]: {
        minHeight: '30px',
        top: '1px !important',
      },
      [`& .${mentionInputClassName}__input`]: {
        paddingBottom: '9px !important',
        paddingLeft: '12px',
        border: 0,
        borderBottom: `1px solid #949494`,
        color: palette.text.primary,
        transition: transitions.create('border-bottom-color', {
          duration: transitions.duration.shorter,
          easing: transitions.easing.easeInOut,
        }),
        '&:focus': {
          outline: 'none',
        },
        '&:disabled': {
          color: palette.text.disabled,
          borderBottom: `1px dotted ${palette.divider}`,
        },
        '&:hover:not(:disabled):not(:focus)': {
          borderBottom: `1px solid black`,
        },
      },

      [`& .${mentionInputClassName}__highlighter`]: {
        paddingLeft: '12px',
        paddingTop: '2px',
        '& strong': {
          marginLeft: '-2px !important',
          padding: '0 1px 0 1px 0 !important',
        },
      },
      [`& .${mentionInputClassName}__suggestions`]: {
        zIndex: '100 !important',
      },
      [`& .${mentionInputClassName}__suggestions__list`]: {
        background: 'white',
        maxHeight: '200px',
        overflowY: 'auto',
        position: 'fixed',
        boxShadow: '0px 12px 12px rgba(0, 0, 0, 0.08)',
        borderRadius: '4px',
      },
      [`& .${floatingLabelClassName}`]: getFloatingLabelStyles(palette, props, transitions),
      '&:focus-within': {
        [`& .${floatingLabelClassName}`]: {
          ...getPlaceholderStyle(true),
          color: palette.primary.main,
        },
      },
    }),
  });
});

export const defaultMentionStyle = {
  backgroundColor: '#00A9E0',
  opacity: 0.3,
  padding: 1,
  marginLeft: -1,
  borderRadius: 4,
};
