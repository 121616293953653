import isEmpty from 'lodash/isEmpty';

export function filterActivities(data, whitelist) {
  if (!whitelist.length) {
    return data;
  }
  return data.filter((item) => whitelist.includes(item.value));
}

export function serializeData(data) {
  const payload = {
    subject: data.subject,
    interacted_at: data.interactedAt,
    call_status: data.callStatus || 0,
    description: data.description,
    disposition: data.disposition,
    owner_id: data.ownerId,
    owner_type: data.ownerType || 'Contact',
    user_id: data.userId,
    result: data.result,
    mentioned_user_ids: data.mentionsIDs,
  };

  if (data.call_sid) {
    payload.call_sid = data.call_sid;
  }

  if (!isEmpty(data.custom)) {
    payload.custom = data.custom;
  }

  return payload;
}

export function getCallStatus(dispositionValues, disposition) {
  return dispositionValues.find((dispostionValue) => dispostionValue.value === disposition)
    .call_status;
}
