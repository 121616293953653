import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniq } from 'lodash';
import TextField from 'lib/TextField';
import { selectUsers } from 'app/state/users/selectors';
import { getMentionsIDsFromText } from 'app/helpers/utils';
import { setFormValues } from '../state/actions';
import { selectFormValues } from '../state/selectors';

function CallNotes() {
  const formValues = useSelector(selectFormValues);
  const dispatch = useDispatch();
  const [mentions, setMentions] = useState([]);
  const usersForMentions = useSelector(selectUsers).map((u) => ({
    id: u.id,
    display: u.fullName,
  }));

  const handleChange = (e) => {
    dispatch(
      setFormValues({
        ...formValues,
        callNotes: e.target.value,
      })
    );
  };

  const mentionUser = (id, display) => {
    setMentions([...mentions, { id, markup: `@[${display}](${id})` }]);
  };

  const onBlur = () => {
    const mentionsIDs = uniq(getMentionsIDsFromText(mentions, formValues.callNotes));
    dispatch(setFormValues({ ...formValues, mentionsIDs }));
  };

  return (
    <TextField
      label="Add note"
      multiline
      value={formValues.callNotes}
      onChange={handleChange}
      mentionable
      mentionData={usersForMentions}
      onMentionAdd={mentionUser}
      onBlur={onBlur}
    />
  );
}

export default CallNotes;
