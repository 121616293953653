import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import styles from './CallerInfo.module.scss';
import CallerInfoImage from './CallerInfoImage';
import CallerInfoText from './CallerInfoText';

export default function CallerInfo({ mainTextColor, subTextColor, fullInfo }) {
  return (
    <Box className={fullInfo ? styles['full-caller-info'] : styles['caller-info']}>
      <CallerInfoImage />
      <CallerInfoText
        fullInfo={fullInfo}
        mainTextColor={mainTextColor}
        subTextColor={subTextColor}
      />
    </Box>
  );
}

CallerInfo.defaultProps = {
  mainTextColor: 'white',
  subTextColor: 'white',
  fullInfo: true,
};

CallerInfo.propTypes = {
  mainTextColor: PropTypes.string,
  subTextColor: PropTypes.string,
  fullInfo: PropTypes.bool,
};
