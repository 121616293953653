import Moment from 'moment';
import alertService from '../../services/alerts';
import { get as getReminder } from '../../services/reminders';
import { get as getNote } from '../../services/notes';
import constants from './constants';
import { error as errorAlert } from '../notifications/actions';
import { forEachError } from '../../../helpers/errorHelper';

function getAlertsRequest() {
  return {
    type: constants.GET_ALERTS_REQUEST,
  };
}

export function getAlertsSuccess(data, meta) {
  return {
    type: constants.GET_ALERTS_SUCCESS,
    data,
    meta,
  };
}

export function getAlertsFailure(errors) {
  return {
    type: constants.GET_ALERTS_FAILURE,
    errors,
  };
}

export function readAlertRequest(alertID) {
  return {
    type: constants.UPDATE_ALERT_REQUEST,
    alertID,
  };
}

export function readAlertSuccess(alertID) {
  return {
    type: constants.UPDATE_ALERT_SUCCESS,
    alertID,
  };
}

export function readAlertFailure() {
  return {
    type: constants.UPDATE_ALERT_FAILURE,
  };
}

export function readAllAlertsRequest() {
  return {
    type: constants.BATCH_UPDATE_ALERTS_REQUEST,
  };
}

export function readAllAlertsSuccess() {
  return {
    type: constants.BATCH_UPDATE_ALERTS_SUCCESS,
  };
}

export function readAllAlertsFailure() {
  return {
    type: constants.BATCH_UPDATE_ALERTS_FAILURE,
  };
}

export function addAlert(alert) {
  return {
    type: constants.ADD_ALERT,
    alert,
  };
}

export function toggleAlertsPopup() {
  return {
    type: constants.TOGGLE_ALERTS_POPUP,
  };
}

export function getAlerts(params) {
  return (dispatch, getState) => {
    const stateParams = getState().alerts.params;

    dispatch(getAlertsRequest());

    return alertService
      .getAlerts({ ...stateParams, ...params })
      .then((json) => {
        dispatch(getAlertsSuccess(json.alerts, json.meta));
      })
      .catch((errors) => {
        dispatch(getAlertsFailure(errors));
      });
  };
}

function getOwner(alert, successCallback, failureCallback) {
  const ownerType = alert.resource_type.toLowerCase();
  const fetchOwner = {
    Reminder: getReminder,
    Note: getNote,
  }[alert.resource_type];

  fetchOwner(alert.resource_id)
    .then((response) => {
      successCallback(response[ownerType]);
    })
    .catch((errors) => {
      failureCallback(errors);
    });
}

export function readAlert(alert, successCallback) {
  return (dispatch) => {
    dispatch(readAlertRequest(alert.id));

    alertService
      .updateAlert(alert.id, { read_at: Moment().format() })
      .then(() => {
        getOwner(
          alert,
          (response) => {
            dispatch(readAlertSuccess(alert.id));
            successCallback(response);
          },
          (errors) => {
            dispatch(readAlertSuccess(alert.id));
            console.error(errors.data);
          }
        );
      })
      .catch((errors) => {
        dispatch(readAlertFailure());
        forEachError(errors.data, (e) => dispatch(errorAlert(e)));
      });
  };
}

export function readAllAlerts() {
  return (dispatch) => {
    dispatch(readAllAlertsRequest());

    alertService
      .batchUpdateAlerts({ read_at: Moment().format() })
      .then(() => {
        dispatch(readAllAlertsSuccess());
      })
      .catch((errors) => {
        dispatch(readAllAlertsFailure());
        forEachError(errors.data, (e) => dispatch(errorAlert(e)));
      });
  };
}
