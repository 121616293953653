import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ component: Component, userLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      userLoggedIn ? <Redirect to={{ pathname: '/' }} /> : <Component {...props} />
    }
  />
);

PublicRoute.propTypes = {
  component: PropTypes.object.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    userLoggedIn: state.session.loggedIn,
  };
}

export default connect(mapStateToProps)(PublicRoute);
