import { createSlice } from '@reduxjs/toolkit';
import { fetchSupportDesk, updateSupportDesk } from './actions';

const initialState = {
  data: null,
  fetching: false,
};

export const supportDeskSlice = createSlice({
  name: 'support-desk',
  initialState,
  extraReducers: {
    [fetchSupportDesk.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [fetchSupportDesk.fulfilled]: (state, { payload }) => {
      return { ...state, fetching: false, data: payload.support_desk };
    },
    [fetchSupportDesk.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [updateSupportDesk.fulfilled]: (state, { payload }) => {
      return { ...state, data: payload.support_desk };
    },
  },
});

export default supportDeskSlice.reducer;
