import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { withRouter } from 'react-router-dom';
import { error as errorAlert } from 'state/notifications/actions';
import { requestOneTimeInvitation } from '../services/auth';
import { parseTokenFromEngine } from '../helpers/auth';
import { createUserSession, destroyUserSession } from '../state/session/actions';
import { getUser } from '../state/user/actions';
import { forEachError } from '../../helpers/errorHelper';

class InvitationHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenFetched: false,
    };
  }

  componentDidMount() {
    const { search } = this.props.location;
    const parsedQueryString = queryString.parse(search);
    const invitationToken = parsedQueryString.invitation_token;

    if (invitationToken) {
      this.props.destroyUserSession();

      requestOneTimeInvitation(invitationToken)
        .then((response) => {
          const auth = parseTokenFromEngine(response);
          this.props.createUserSession(auth);
          this.props.getUser(auth.userId);
          this.setState({ tokenFetched: true });
          this.props.history.push('/', { search: '' });
        })
        .catch((err) => {
          this.setState({ tokenFetched: true });
          this.props.history.push('/login');
          this.props.forEachError(err.data, (e) => this.props.errorAlert(e));
        });
    }
  }

  render() {
    const { search } = this.props.location;
    const urlContainsInvitationToken = !!queryString.parse(search).invitation_token;

    if (urlContainsInvitationToken && !this.state.tokenFetched) return null;

    return this.props.children;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createUserSession,
      destroyUserSession,
      getUser,
      errorAlert,
      forEachError,
    },
    dispatch
  );
}

InvitationHandler.propTypes = {
  children: PropTypes.node.isRequired,
  createUserSession: PropTypes.func.isRequired,
  destroyUserSession: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  errorAlert: PropTypes.func.isRequired,
  forEachError: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(withRouter(InvitationHandler));
