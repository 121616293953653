import { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PageLoader from 'ui/Loaders/NewPageLoader';
import PublicRoute from 'components/PublicRoute';
import PrivateRoute from 'components/PrivateRoute';
import retry from './helpers/retry';
import MainLayout from './layouts/Main';

const AuthLayout = lazy(() =>
  retry(() => import(/* webpackChunkName: "AuthLayout" */ './layouts/Auth'))
);
const Onboarding = lazy(() =>
  retry(() => import(/* webpackChunkName: "OnboardingLayout" */ './routes/Onboarding'))
);
const ErrorPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "ErrorPage" */ './components/ErrorPage'))
);
const NoMatch = lazy(() =>
  retry(() => import(/* webpackChunkName: "NoMatch" */ './routes/NoMatch'))
);
const SubscriptionExpired = lazy(() =>
  retry(() => import(/* webpackChunkName: "SubscriptionExpired" */ './routes/SubscriptionExpired'))
);

const Routes = () => (
  <Suspense fallback={<PageLoader />}>
    <Switch>
      <Redirect from="/home" to="/" />
      <PrivateRoute path="/" exact component={MainLayout} />
      <PrivateRoute path="/reminders" exact component={MainLayout} />
      <PrivateRoute path="/companies" exact component={MainLayout} />
      <Redirect from="/company/:id" to="/companies/:id" />
      <PrivateRoute path="/companies/:id" component={MainLayout} />
      <PrivateRoute path="/contacts" exact component={MainLayout} />
      <Redirect from="/contact/:id" to="/contacts/:id" />
      <PrivateRoute path="/contacts/:id" component={MainLayout} />
      <PrivateRoute path="/opportunities/priority" exact component={MainLayout} />
      <PrivateRoute path="/opportunities/pipeline" exact component={MainLayout} />
      <PrivateRoute path="/opportunities" exact component={MainLayout} />
      <Redirect from="/opportunity/:id" to="/opportunities/:id" />
      <PrivateRoute path="/opportunities/:id" component={MainLayout} />
      <PrivateRoute path="/campaigns" exact component={MainLayout} />
      <PrivateRoute path="/campaigns/:id" component={MainLayout} />
      <PrivateRoute path="/activities" component={MainLayout} />
      <PrivateRoute path="/assistant" component={MainLayout} />
      <PrivateRoute path="/calendar" component={MainLayout} />
      <PrivateRoute path="/lists" exact component={MainLayout} />
      <PrivateRoute path="/lists/:id" component={MainLayout} />
      <PrivateRoute path="/assistant-rules" exact component={MainLayout} />
      <PrivateRoute path="/assistant-rules/:id" component={MainLayout} />
      <Redirect from="/refer" to="/refer-to-friend" />
      <PrivateRoute path="/refer-to-friend" component={MainLayout} />
      <PrivateRoute path="/import" component={MainLayout} />
      <PrivateRoute path="/settings" component={MainLayout} />
      <PrivateRoute path="/tickets" exact component={MainLayout} />
      <PrivateRoute path="/tickets/:id" exact component={MainLayout} />
      <PrivateRoute path="/quotes" exact component={MainLayout} />
      <PrivateRoute path="/quotes/:id" exact component={MainLayout} />
      <Redirect from="/recommendation" to="/recommendations" />
      <PrivateRoute path="/recommendations" component={MainLayout} />
      <PrivateRoute path="/logs/:tab" component={MainLayout} />
      <PrivateRoute path="/fields" component={MainLayout} />
      <PrivateRoute path="/onboarding" component={Onboarding} />
      <PrivateRoute path="/subscription-expired" component={SubscriptionExpired} />
      <PublicRoute path="/login" component={AuthLayout} />
      <Redirect from="/reset_password" to="/reset-password" />
      <PublicRoute path="/reset-password" component={AuthLayout} />
      <Route path="/page-not-found" component={NoMatch} />
      <Route path="/error" component={ErrorPage} />
      <Route component={NoMatch} />
    </Switch>
  </Suspense>
);

export default Routes;
