import PropTypes from 'prop-types';
import GroupList from './GroupList';
import styles from './ChatGroup.module.scss';

export default function ChatGroup({ name, messages, ...props }) {
  return (
    <div>
      <div className={styles.date}>{name}</div>
      <GroupList messages={messages} {...props} />
    </div>
  );
}

ChatGroup.propTypes = {
  name: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
};
