import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { selectUserStatus, selectUserFetched } from 'state/user/selectors';
import { shouldDoOnboarding } from 'helpers/user';
import { setupTwilioDevice } from 'components/CallDialog/state/actions';
import Main from './Main';

function mapStateToProps(state) {
  const userStatus = selectUserStatus(state);

  return {
    userFetched: selectUserFetched(state),
    userShouldDoOnboarding: shouldDoOnboarding(userStatus),
    conversationVisible: state.conversation.visible,
    twilioEnabled: state.twilioCall.twilioEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setupTwilioDevice,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
