import { getPhoneTypeFromNumber } from 'helpers/contacts';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Day from 'dayjs';
import { createActivity } from 'services/twilioService';
import { error as errorAlert, success as successAlert } from 'state/notifications/actions';
import { selectUserId } from 'state/user/selectors';
import { getCallStatus } from 'helpers/activities';
import { getActivityDispositionValues } from 'state/custom-fields/selectors';
import { resetTwilioStatePartially } from '../state/actions';
import {
  selectFormValues,
  selectIsIncomingCall,
  selectPhoneNumber,
  selectPreviousCall,
  selectTwilioCallers,
  selectTwilioConnection,
} from '../state/selectors';

export default function useCallDialogModal() {
  const previousCall = useSelector(selectPreviousCall);
  const connection = useSelector(selectTwilioConnection);
  const phoneNumber = useSelector(selectPhoneNumber);
  const { callNotes, custom, disposition } = useSelector(selectFormValues);
  const isIncomingCall = useSelector(selectIsIncomingCall);
  const callers = useSelector(selectTwilioCallers);
  const userId = useSelector(selectUserId);
  const dispositionValues = useSelector(getActivityDispositionValues);
  const { contact, account, callSid, type, caller } = previousCall;
  const dispatch = useDispatch();

  const generateDefaultText = () => {
    switch (disposition) {
      case 'left_voicemail':
        return 'I left VM for <ContactName>';
      case 'no_connect':
        return `I called <ContactName>, but it didn't connect`;
      default:
        return 'I got <ContactName> on the phone';
    }
  };

  const generateDescription = () => {
    const defaultText = generateDefaultText();
    let description = defaultText.replace(
      '<ContactName>',
      contact ? contact?.firstName : account?.name
    );

    if (phoneNumber && !contact && !account) {
      description = defaultText.replace('<ContactName>', callers[0].name);
      description += `\n\nPhone: ${phoneNumber}`;
    }

    if (phoneNumber && contact) {
      const phoneType = getPhoneTypeFromNumber(phoneNumber, contact);
      description += `\n\n${phoneType.toUpperCase()} Phone: ${phoneNumber}`;
    } else if (phoneNumber && account) {
      description += `\n\nPhone: ${phoneNumber}`;
    }

    if (callNotes) {
      description += `\n\nNotes:\n\n${callNotes}`;
    }

    return description;
  };

  const generateSubject = () => {
    if (!contact && !account) return `Spoke with ${callers[0].name}`;
    switch (disposition) {
      case 'left_voicemail':
        return `Left voicemail for ${contact ? contact.firstName : account.name}`;
      case 'no_connect':
        return `Called ${contact ? contact.firstName : account.name} but didn't connect`;
      default:
        return `Spoke with ${contact ? contact.firstName : account.name}`;
    }
  };

  useEffect(async () => {
    if (
      previousCall.callSid &&
      isIncomingCall &&
      connection &&
      previousCall.callSid !== connection.parameters.CallSid &&
      callers.length > 0
    ) {
      const payload = {
        call_sid: callSid,
        description: generateDescription(),
        disposition: disposition || type,
        subject: generateSubject(),
        owner_type: caller?.resource_type || callers[0].resource_type,
        owner_id: caller?.resource_id || callers[0].resource_id,
        interacted_at: new Day().format(),
        user_id: userId,
        custom,
        call_status: getCallStatus(dispositionValues, disposition || type),
      };
      try {
        await createActivity(payload);
        dispatch(successAlert('Your notes are being auto-saved.'));
        dispatch(resetTwilioStatePartially());
      } catch (error) {
        dispatch(errorAlert(error.statusText));
      }
    }
  }, [connection]);
}
