const GET_ALERTS_REQUEST = 'GET_ALERTS_REQUEST';
const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
const GET_ALERTS_FAILURE = 'GET_ALERTS_FAILURE';
const UPDATE_ALERT_REQUEST = 'UPDATE_ALERT_REQUEST';
const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS';
const UPDATE_ALERT_FAILURE = 'UPDATE_ALERT_FAILURE';
const BATCH_UPDATE_ALERTS_REQUEST = 'BATCH_UPDATE_ALERT_REQUEST';
const BATCH_UPDATE_ALERTS_SUCCESS = 'BATCH_UPDATE_ALERTS_SUCCESS';
const BATCH_UPDATE_ALERTS_FAILURE = 'BATCH_UPDATE_ALERTS_FAILURE';
const ADD_ALERT = 'ADD_ALERT';
const TOGGLE_ALERTS_POPUP = 'TOGGLE_ALERTS_POPUP';

export default {
  GET_ALERTS_REQUEST,
  GET_ALERTS_SUCCESS,
  GET_ALERTS_FAILURE,
  UPDATE_ALERT_REQUEST,
  UPDATE_ALERT_SUCCESS,
  UPDATE_ALERT_FAILURE,
  BATCH_UPDATE_ALERTS_REQUEST,
  BATCH_UPDATE_ALERTS_SUCCESS,
  BATCH_UPDATE_ALERTS_FAILURE,
  ADD_ALERT,
  TOGGLE_ALERTS_POPUP,
};
