import EngineService from '../core/engine-service';

const ENDPOINT = '/assistant_rules';

export function getEngineRules(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function getEngineRule(ruleId) {
  return EngineService.get(`${ENDPOINT}/${ruleId}`).then((json) => json);
}

export function createEngineRule(payload) {
  return EngineService.post(ENDPOINT, { assistant_rule: payload }).then((json) => json);
}

export function updateEngineRule(ruleId, payload) {
  return EngineService.patch(`${ENDPOINT}/${ruleId}`, { assistant_rule: payload }).then(
    (json) => json
  );
}

export function getConditionsForRule(ruleId, params) {
  return EngineService.get(`${ENDPOINT}/${ruleId}/conditions`, params).then((json) => json);
}

export function getConditionDefinitions(params = {}) {
  return EngineService.get(`${ENDPOINT}/definitions`, params).then((json) => json);
}

export function createRuleCondition(ruleId, payload) {
  return EngineService.post(`${ENDPOINT}/${ruleId}/conditions`, { condition: payload }).then(
    (json) => json
  );
}

export function updateRuleCondition(ruleId, conditionId, payload) {
  return EngineService.put(`${ENDPOINT}/${ruleId}/conditions/${conditionId}`, {
    condition: payload,
  }).then((json) => json);
}

export function deleteRuleCondition(ruleId, conditionId) {
  return EngineService.delete(`${ENDPOINT}/${ruleId}/conditions/${conditionId}`).then(
    (json) => json
  );
}

export function getResourcesForAssistantRule(ruleId, params = {}) {
  return EngineService.get(`${ENDPOINT}/${ruleId}/resources`, params).then((json) => json);
}

export function deleteRule(ruleId) {
  return EngineService.delete(`${ENDPOINT}/${ruleId}`).then((json) => json);
}

export function getAssistantRulesResourceCount(ruleId) {
  return EngineService.get(`${ENDPOINT}/${ruleId}/resources/count`).then((json) => json);
}

export function cloneRule(ruleId) {
  return EngineService.post(`${ENDPOINT}/${ruleId}/clone`).then((json) => json);
}

export function ignoreRule(ruleId, payload) {
  return EngineService.post(`${ENDPOINT}/${ruleId}/ignored_resources`, {
    ignored_resource: payload,
  }).then((json) => json);
}

export function getConfigurations() {
  return EngineService.get(`${ENDPOINT}/configurations`).then((json) => json);
}

export function getCalculationFields(resource) {
  return EngineService.get(`${ENDPOINT}/fields?resource=${resource}`).then((json) => json);
}
