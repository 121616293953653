import { groupInteractionsByDate } from 'app/helpers/interactions';
import { groupItemsByDate } from 'app/helpers/utils';

export const selectIsOpen = (state) => state.appNotifications.isOpen;
export const selectTexts = (state) => state.appNotifications.texts;
export const selectGroupedTexts = (state) => groupInteractionsByDate(state.appNotifications.texts);
export const selectHasUnreadNotifications = (state) => state.appNotifications.texts.length > 0;
export const selectShouldRefetch = (state) => state.appNotifications.shouldRefetch;

export const selectMissedCalls = (state) => state.appNotifications.missedCalls;
export const selectGroupedMissedCalls = (state) => {
  return groupInteractionsByDate(state.appNotifications.missedCalls);
};
export const selectShouldRefetchMissedCalls = (state) => {
  return state.appNotifications.shouldRefetchMissedCalls;
};

export const selectAlerts = (state) => state.appNotifications.alerts;
export const selectGroupedAlerts = (state) => groupItemsByDate(selectAlerts(state), 'created_at');
export const selectShouldRefetchAlerts = (state) => {
  return state.appNotifications.shouldRefetchAlerts;
};

export const selectHasNewNotifications = (state) => {
  return state.appNotifications.unreadNotifications.length > 0;
};
