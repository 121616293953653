import pick from 'lodash/pick';
import orderBy from 'lodash/orderBy';

export function blank() {
  return {
    id: null,
    name: '',
    pipelineId: null,
    index: 0,
    isClosed: false,
    isWon: false,
    isActive: true,
  };
}

export function parse(data = {}) {
  return {
    ...blank(),
    id: data.id,
    name: data.name,
    pipelineId: data.pipeline_id,
    index: data.index,
    isClosed: data.is_closed,
    isWon: data.is_won,
    isActive: data.is_active,
  };
}

export function parseAll(data = []) {
  if (!data.length) return [];
  return data.map((item) => parse(item));
}

export function serialize(stage, keys) {
  const s = {
    id: stage.id,
    name: stage.name,
    pipeline_id: stage.pipelineId,
    index: stage.index,
    is_closed: stage.isClosed,
    is_won: stage.isWon,
    is_active: stage.isActive,
  };

  if (keys) {
    return pick(s, keys);
  }
  return s;
}

export function serializeAll(data = [], keys) {
  return data.map((item) => serialize(item, keys));
}

export function sortStages(stages, order = 'asc') {
  return orderBy(stages, ['index'], [order]);
}

export function repairSortValues(stages) {
  const activeStages = stages.filter((stage) => stage.isActive);
  return sortStages(activeStages).map((stage, key) => ({
    ...stage,
    index: key + 1,
  }));
}

export function filterByPipeline(stages, pipelineId) {
  if (pipelineId === undefined) {
    return stages;
  }
  return [...stages].filter((stage) => stage.pipelineId === pipelineId);
}

export function countByPipeline(stages, pipelineId) {
  return filterByPipeline(stages, pipelineId).length;
}

export function getStageValue({ isClosed, isWon }) {
  if (!isClosed) return 'Open';
  if (isWon) return 'Won';
  return 'Lost';
}

export function setStageValue(stage = {}, valueName) {
  const params = { isClosed: false, isWon: false };
  if (valueName.toLowerCase() === 'lost') {
    params.isClosed = true;
  }
  if (valueName.toLowerCase() === 'won') {
    params.isClosed = true;
    params.isWon = true;
  }
  return { ...stage, ...params };
}

export function crmToSpiroStage(salesStages, crmStage) {
  console.warn('crmToSpiroStage() is deprecated!');
  return salesStages.find((stage) => stage.crmStage === crmStage).spiroStage;
}

export const dropDownOptions = [
  { value: 'Open', label: 'Open' },
  { value: 'Won', label: 'Won' },
  { value: 'Lost', label: 'Lost' },
];

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export const moveStages = (array, from, to) => {
  const newArray = array.slice();
  arrayMoveMutate(newArray, from, to);
  return newArray.map((s, i) => ({
    ...s,
    index: i + 1,
  }));
};

export default {
  blank,
  parse,
  parseAll,
  serialize,
  serializeAll,
  sortStages,
  repairSortValues,
  dropDownOptions,
  moveStages,
};
