import numeral from 'numeral';
import 'numeral/locales';
import { showModal } from 'modals/state/actions';
import { getUser as getUserRemote, updateUser as updateUserRemote } from '../../services/users';
import {
  FETCHING_USER,
  STORE_USER,
  SET_NOTIFICATIONS_STATUS,
  UPDATE_IN_PROGRESS,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  UPDATE_ENABLE_PARENT_ACCOUNT,
} from './constants';
import { parseUserFromEngine } from '../../helpers/user';
import { storeTokens } from '../tokens/actions';
import { storeCredentials } from '../credentials/actions';
import { storeCalendar } from '../calendar/actions';
import { storeTwilioProfile } from '../twilio-profile/actions';
import { storeOrganization } from '../organization/actions';
import { setSubscription } from '../subscription/actions';
import { getUsers, getAllUsers } from '../users/actions';
import { getSubUsers } from '../sub-users/actions';
import { getCurrencies } from '../currencies/actions';
import { getCustomFields } from '../custom-fields/actions';
import { getDisplayFields } from '../display-fields/actions';
import { getAlerts } from '../alerts/actions';
import { getCounters } from '../counters/actions';
import { getAssistantRules } from '../../assistant/Assistant/state/actions';
import i18n from '../../../i18n';
import { fetchSpiroViews } from '../views/actions';

export function fetchingUserData() {
  return {
    type: FETCHING_USER,
  };
}

export function storeUser(data) {
  return {
    type: STORE_USER,
    data: parseUserFromEngine(data),
  };
}

export function setNotificationsStatus(status) {
  return {
    type: SET_NOTIFICATIONS_STATUS,
    status,
  };
}

export function editEnableParentAccount(data) {
  return {
    type: UPDATE_ENABLE_PARENT_ACCOUNT,
    data,
  };
}

export function updateCurrentUser(data, params) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_IN_PROGRESS });

    return updateUserRemote(getState().user.id, data, params)
      .then((res) => {
        dispatch(storeUser(res));
        dispatch(storeTwilioProfile(res.twilio_profile));
        dispatch({ type: UPDATE_SUCCESS });
      })
      .catch((e) => {
        dispatch({ type: UPDATE_FAIL });
        throw e;
      });
  };
}

export function getUser(id) {
  return (dispatch) =>
    // dispatch({ type: UPDATE_IN_PROGRESS });
    getUserRemote(id)
      .then((json) => {
        const { user } = json;
        const locale = user.organization.locale || 'en';
        i18n.changeLanguage(locale);
        // numeral.locale(locale === 'en-CA' ? 'en' : locale);
        numeral('en');
        dispatch(storeUser(user));

        if (user.calendar) {
          dispatch(
            storeCalendar({
              uid: user.calendar.uid,
              name: user.calendar.title,
            })
          );
        }
        dispatch(storeTokens(user.tokens));
        dispatch(storeCredentials(user.credentials));
        dispatch(storeOrganization(user.organization));
        dispatch(
          setSubscription({
            customerStatus: user.customer_status,
            customerDaysLeft: user.customer_days_left,
            customerExpiresAt: user.customer_expires_at,
            customerSince: user.customer_since,
            trialDaysLeft: user.trial_days_left,
            trialExpiresAt: user.trial_expires_at,
          })
        );
        dispatch(storeTwilioProfile(user.twilio_profile));

        dispatch(
          updateCurrentUser({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
        ).catch((e) => {
          console.error(e);
        });

        dispatch(getUsers());
        dispatch(getSubUsers());
        dispatch(getCustomFields());
        // fetch views on refresh
        dispatch(fetchSpiroViews());
        dispatch(getDisplayFields());
        dispatch(getCurrencies());
        dispatch(getAlerts());
        dispatch(getAllUsers({ include_deactivated: true }));
        dispatch(getAssistantRules({ user_group_only: true }));

        if (user.twilio_profile) {
          dispatch(getCounters());
        }

        try {
          heap.identify(user.id);
          heap.addUserProperties({
            email: user.email,
            CompanyId: user.organization.id,
            CompanyName: user.organization.name,
            FullName: user.full_name,
          });
        } catch (e) {
          console.error(e);
        }

        try {
          Appcues.identify(user.id, {
            name: user.full_name,
            email: user.email,
            admin: user.is_admin,
            spiroVoiceUser: !!user.twilio_profile,
            createdAt: user.created_at,
            disableAppCues: user.appcues_disabled,
          });
        } catch (e) {
          console.error(e);
        }
        if (!user.voip_popup_disabled && user.twilio_profile && user.status === 'active') {
          dispatch(showModal('VOIP_ENABLEMENT_MODAL', {}));
        }

        return Promise.resolve(user);
      })
      .catch((e) => {
        // history.push('/error');
        Promise.reject(e);
      });
}

export default {
  fetchingUserData,
  storeUser,
  updateCurrentUser,
  setNotificationsStatus,
};
