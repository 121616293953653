import { useState } from 'react';
import { useSelector } from 'react-redux';
import http from 'app/core/engine-service';
import { selectUserId } from 'app/state/user/selectors';
import { parseMetaFromResponse } from 'app/helpers/meta';
import { groupItemsByDate } from 'app/helpers/utils';

export default function useTexts() {
  const userId = useSelector(selectUserId);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [texts, setTexts] = useState([]);
  const [meta, setMeta] = useState({ currentPage: 1, nextPage: null });
  const [params, setParams] = useState({
    page: 1,
    per_page: 20,
    user_id: userId,
    interaction_type: 'sms',
  });

  const fetchTexts = (requestParams = {}) => {
    const newParams = { ...params, ...requestParams };
    setIsLoading(true);

    return http.get('/interactions', newParams).then((response) => {
      setIsLoading(false);
      setParams(newParams);
      setMeta(parseMetaFromResponse(response.meta));

      const newTexts = response.interactions;

      setTexts((prevTexts) => (newParams.page > 1 ? [...prevTexts, ...newTexts] : newTexts));

      return response.interactions;
    });
  };

  const clearTexts = () => setTexts([]);

  const confirmText = (id) => {
    return http.patch(`/sms_messages/${id}`, { sms_message: { confirmed: true } });
  };

  const confirmAllTexts = () => {
    setIsConfirming(true);

    return http.post('/clear_text_notifications').then(() => setIsConfirming(false));
  };

  return {
    isLoading,
    isConfirming,
    texts: groupItemsByDate(texts, 'interacted_at'),
    meta,
    params,
    fetchTexts,
    confirmText,
    confirmAllTexts,
    clearTexts,
  };
}
