import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import { parseAll } from 'helpers/users';
import { fetchUsers, updateUser } from './actions';

const initialState = {
  users: [],
  selectedUser: null,
  fetching: false,
  meta: nullMeta,
  params: {
    page: 1,
    per_page: 15,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    q: undefined,
    include_deactivated: true,
  },
  isBuyLicencesModalVisible: false,
  isChangeOwnerModalVisible: false,
  isUserInvitationModalVisible: false,
  isUserEditModalVisible: false,
  isUserReassignModalVisible: false,
  isAssignLicenseModalVisible: false,
  selectedLicenceType: '',
};

export const userAdministrationSlice = createSlice({
  name: 'userAdministration',
  initialState,
  reducers: {
    setSelectedUser: (state, { payload }) => {
      return { ...state, selectedUser: payload };
    },
    setBuyLicencesModalVisible: (state, { payload }) => {
      return { ...state, isBuyLicencesModalVisible: payload };
    },
    setChangeOwnerModalVisible: (state, { payload }) => {
      return { ...state, isChangeOwnerModalVisible: payload };
    },
    setUserInvitationModalVisible: (state, { payload }) => {
      return { ...state, isUserInvitationModalVisible: payload };
    },
    setUserEditModalVisible: (state, { payload }) => {
      return { ...state, isUserEditModalVisible: payload };
    },
    setUserReassignModalVisible: (state, { payload }) => {
      return {
        ...state,
        isUserReassignModalVisible: payload,
      };
    },
    setAssignLicenseModalVisible: (state, { payload }) => {
      return { ...state, isAssignLicenseModalVisible: payload };
    },
    setSelectedLicenseType: (state, { payload }) => {
      return { ...state, selectedLicenceType: payload };
    },
  },
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        users: parseAll(payload.users),
        fetching: false,
        meta: {
          ...state.meta,
          ...parseMetaFromResponse(payload.meta),
        },
        params: {
          ...state.params,
          ...payload.params,
        },
      };
    },
    [fetchUsers.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [updateUser.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        users: payload.data,
        fetching: false,
      };
    },
    [updateUser.rejected]: (state) => {
      return { ...state, fetching: false };
    },
  },
});

export const {
  setSelectedUser,
  setBuyLicencesModalVisible,
  setChangeOwnerModalVisible,
  setUserInvitationModalVisible,
  setUserEditModalVisible,
  setUserReassignModalVisible,
  setSelectedLicenseType,
  setAssignLicenseModalVisible,
} = userAdministrationSlice.actions;

export default userAdministrationSlice.reducer;
