import EngineService from '../core/engine-service';

const ENDPOINT = '/missed_interactions_counter';

// GET: /counters
export async function getCounters(params) {
  const json = await EngineService.get(ENDPOINT, params);
  return json;
}

export default {
  getCounters,
};
