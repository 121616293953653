import { getCounters as getCountersRemote } from '../../services/counters';

import {
  COUNTERS_REQUEST_STARTED,
  COUNTERS_REQUEST_SUCCESS,
  MESSAGES_INCREMENT_COUNTER,
  CALLS_INCREMENT_COUNTER,
} from './constants';

export function requestStarted() {
  return {
    type: COUNTERS_REQUEST_STARTED,
  };
}

export function requestSuccess(json) {
  return {
    type: COUNTERS_REQUEST_SUCCESS,
    missedCalls: json.counters.missed_calls_count,
    unreadSMS: json.counters.unread_sms_count,
  };
}

export function incrementMessagesCounter(counter) {
  return {
    type: MESSAGES_INCREMENT_COUNTER,
    counter,
  };
}

export function incrementCallsCounter(counter) {
  return {
    type: CALLS_INCREMENT_COUNTER,
    counter,
  };
}

export function getCounters() {
  return async (dispatch) => {
    dispatch(requestStarted());

    const json = await getCountersRemote();
    return dispatch(requestSuccess(json));
  };
}
