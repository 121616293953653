import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from 'lib/Button';
import styles from './UnauthorizedAccess.module.scss';

function UnauthorizedAccess({ message }) {
  return (
    <Grid container justifyContent="center" className="text-center">
      <Grid item xs={12}>
        <Paper elevation={0} square className={styles.wrapper}>
          <ErrorOutlineIcon fontSize="large" className={styles.icon} />
          <Typography variant="h6">Unauthorized Access!</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {message}
          </Typography>
          <Button
            component={Link}
            to="/"
            variant="contained"
            color="primary"
            className={styles.button}
          >
            Return home
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

UnauthorizedAccess.defaultProps = {
  message: 'This page is only for Administrators!',
};

UnauthorizedAccess.propTypes = {
  message: PropTypes.string,
};

export default UnauthorizedAccess;
