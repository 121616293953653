import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { IconButton } from '@mui/material';
import { setupTwilioDevice, toggleCallModal } from 'components/CallDialog/state/actions';
import {
  selectToggleCallModal,
  selectIsIncomingCall,
  selectActiveCallModalOpen,
} from 'components/CallDialog/state/selectors';
import styles from './CallStatus.module.scss';

export default function CallStatus({ callsEnabled }) {
  const isCallModalOpen = useSelector(selectToggleCallModal);
  const incomingCall = useSelector(selectIsIncomingCall);
  const isActiveCallModalOpen = useSelector(selectActiveCallModalOpen);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const setCallsEnabled = () => {
    if (!callsEnabled) {
      setLoading(true);
      dispatch(setupTwilioDevice()).then(() => setLoading(false));
    } else {
      dispatch(toggleCallModal(!isCallModalOpen));
    }
  };

  return (
    <div className={`${styles['phone-wrapper']} ${loading ? styles.animation : ''}`}>
      <IconButton
        aria-label="Phone"
        onClick={setCallsEnabled}
        disabled={incomingCall || isActiveCallModalOpen}
        data-cy="navbar-toggle-twilio-button"
        size="large"
      >
        <PhoneOutlinedIcon
          style={{ color: callsEnabled ? '#A4D131' : '#F44336' }}
          data-cy="navbar-phone-icon"
        />
      </IconButton>
    </div>
  );
}

CallStatus.propTypes = {
  callsEnabled: PropTypes.bool.isRequired,
};
