import { nullMeta } from 'helpers/meta';
import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  REPLACE_OPPORTUNITY,
  SET_PARAMS,
  RESET_DATA,
  ADD_OPPORTUNITY_TO_LIST,
  STORE_OPPORTUNITIES,
  STORE_NEW_DEALS,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  CREATE_FAILURE,
} from './constants';
import { immutableSplice } from '../../../../helpers/immutableHelper';

const initialState = {
  requestStarted: false,
  data: [],
  requestSuccess: false,
  requestFailed: false,
  newDealsFetched: false,
  newDeals: [],
  createInProgress: false,
  meta: nullMeta,
  params: {
    page: 1,
    per_page: 25,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    crm_stage: undefined,
    q: undefined,
  },
};

function requestStartedHandler(state) {
  return {
    ...state,
    requestStarted: true,
    requestFailed: false,
    requestSuccess: false,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: false,
    requestSuccess: true,
    data: action.data,
    meta: action.meta,
  };
}

function requestFailedHandler(state) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: true,
    requestSuccess: false,
  };
}

function replaceOpportunityHandler(state, action) {
  const index = state.data.findIndex((o) => o.id === action.opportunity.id);

  return {
    ...state,
    data: immutableSplice(state.data, index, 1, action.opportunity),
  };
}

function addOpportunityToListHandler(state, action) {
  return {
    ...state,
    data: [action.opportunity, ...state.data],
  };
}

function storeOpportunitiesHandler(state, action) {
  return {
    ...state,
    data: action.opportunities,
  };
}

function storeNewDealsHandler(state, action) {
  return {
    ...state,
    newDeals: action.opportunities,
    newDealsFetched: true,
  };
}

export function setParamsHandler(state, action) {
  return {
    ...state,
    params: {
      ...state.params,
      ...action.data,
    },
  };
}

export function resetParamsHandler(state) {
  return {
    ...state,
    params: initialState.params,
  };
}

export function resetDataHandler(state) {
  return {
    ...state,
    data: [],
  };
}

export function createInProgressHandler(state) {
  return {
    ...state,
    createInProgress: true,
  };
}

export function createSuccessHandler(state) {
  return {
    ...state,
    createInProgress: false,
  };
}

export function createFailureHandler(state) {
  return {
    ...state,
    createInProgress: false,
  };
}

const ACTION_HANDLERS = {
  [REQUEST_STARTED]: requestStartedHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REQUEST_FAILED]: requestFailedHandler,
  [REPLACE_OPPORTUNITY]: replaceOpportunityHandler,
  [SET_PARAMS]: setParamsHandler,
  [CREATE_IN_PROGRESS]: createInProgressHandler,
  [CREATE_SUCCESS]: createSuccessHandler,
  [CREATE_FAILURE]: createFailureHandler,
  [RESET_DATA]: resetDataHandler,
  [ADD_OPPORTUNITY_TO_LIST]: addOpportunityToListHandler,
  [STORE_OPPORTUNITIES]: storeOpportunitiesHandler,
  [STORE_NEW_DEALS]: storeNewDealsHandler,
};

export default function opportunitiesDataReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
