import { useEffect, Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Day from 'dayjs';
import { selectShouldRefetchAlerts } from 'app/state/app-notifications/selectors';
import { hideNotificationsSlider } from 'app/state/app-notifications/actions';
import Button from 'app/lib/Button';
import { ReactComponent as Icon } from './alerts-icon.svg';
import DateHeader from '../DateHeader';
import useAlerts from './useAlerts';
import styles from './Alerts.module.scss';

const OWNER_TYPES = {
  Account: 'companies',
  Contact: 'contacts',
  Opportunity: 'opportunities',
  Ticket: 'tickets',
};

function Alerts() {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    alerts,
    isLoading,
    isConfirming,
    meta,
    fetchAlerts,
    confirmAlert,
    confirmAllAlerts,
    fetchOwner,
  } = useAlerts();
  const shouldRefetch = useSelector(selectShouldRefetchAlerts);

  const handleClick = (alert) => async () => {
    if (alert.read_at === null) await confirmAlert(alert);
    const owner = await fetchOwner(alert);
    if (owner) {
      dispatch(hideNotificationsSlider());
      if (alert.resource_type === 'Note') {
        history.push(
          `/${OWNER_TYPES[owner.owner_type]}/${owner.owner_id}?activityID=${owner.id}&kind=note`
        );
      } else {
        history.push(`/${OWNER_TYPES[owner.owner_type]}/${owner.owner_id}`);
      }
    }
  };

  const handleMarkAllAsSeen = () => {
    confirmAllAlerts().then(() => {
      fetchAlerts({ page: 1 });
    });
  };

  const handleLoadMore = () => {
    const { scrollTop, clientHeight, scrollHeight } = ref.current;
    const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

    if (scrolledToBottom && !isLoading && meta.nextPage) {
      fetchAlerts({ page: meta.nextPage });
    }
  };

  useEffect(() => {
    fetchAlerts({ page: 1 });
  }, [shouldRefetch]);

  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.alerts} onScroll={handleLoadMore}>
        {Object.keys(alerts).map((group, index) => (
          <Fragment key={index}>
            <DateHeader date={group} />
            {alerts[group].map((alert) => (
              <div
                role="button"
                tabIndex="0"
                key={alert.id}
                className={styles.alert}
                onClick={handleClick(alert)}
              >
                <Icon />
                <span className={styles.time}>{new Day(alert.created_at).format('hh:mm A')}</span>
                <div className={styles.info}>
                  {!alert.read_at && <div className={styles.new}>New</div>}
                  <div className={styles.message}>{alert.message}</div>
                </div>
              </div>
            ))}
          </Fragment>
        ))}
        {isLoading && <div>Loading ...</div>}
      </div>
      <div className={styles.actions}>
        <Button
          color="primary"
          variant="contained"
          disabled={isConfirming}
          onClick={handleMarkAllAsSeen}
        >
          Mark all as seen
        </Button>
      </div>
    </div>
  );
}

export default Alerts;
