import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { showModal, hideModal } from 'modals/state/actions';
import useConnectedEmail from 'hooks/useConnectedEmail';
import { ReactComponent as EmailDisconnected } from '../../icons/email-disconnected.svg';

const EmailIndicator = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const openSendEmailModal = () => {
    dispatch(showModal('SEND_EMAIL_MODAL', { show: true, hasNoContact: true }));
  };

  const openConnectEmailModal = () => {
    dispatch(
      showModal('CONFIRMATION_MODAL', {
        title: `Your email is not connected`,
        message: `Connect your email account.`,
        visible: true,
        confirmButtonText: 'Connect Email',
        onConfirmClick: () => {
          history.push('/settings/my-connections');
          dispatch(hideModal());
        },
        onCancelClick: () => {
          dispatch(hideModal());
        },
      })
    );
  };

  const [connectedEmail] = useConnectedEmail();

  return (
    <>
      {connectedEmail ? (
        <IconButton aria-label="Email" color="inherit" onClick={openSendEmailModal} size="large">
          <EmailOutlinedIcon />
        </IconButton>
      ) : (
        <IconButton
          aria-label="Diconnected Email"
          color="inherit"
          onClick={openConnectEmailModal}
          size="large"
        >
          <EmailDisconnected />
        </IconButton>
      )}
    </>
  );
};

export default EmailIndicator;
