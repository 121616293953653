import PropTypes from 'prop-types';
import cs from 'classnames';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from 'lib/Checkbox';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
  },
});

// TODO: Implement options filtering
// TODO: Implement clearValueOnOptionsChange for render_on fields
export default function MultipleSelect({
  name,
  className,
  label,
  value,
  options,
  fullWidth,
  onChange,
  ...props
}) {
  const classes = useStyles();

  function renderValue(selectedValues) {
    return selectedValues
      .map((v) => {
        const option = options.find((o) => String(o.value) === String(v));
        return option?.label || '';
      })
      .join(',');
  }

  function handleChange(e) {
    onChange({ target: { name, value: e.target.value.join(',') } });
  }

  function isChecked(option) {
    const newVal = value.split(',');
    return newVal.includes(option);
  }

  return (
    <FormControl className={cs(classes.root, className)} variant="filled" fullWidth={fullWidth}>
      <InputLabel id="demo-mutiple-checkbox-label">{label}</InputLabel>
      <Select
        multiple
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        renderValue={renderValue}
        onChange={handleChange}
        value={value.length > 0 ? value.split(',') : []}
        {...props}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={String(option.value)}
            disabled={option.disabled || false}
          >
            <Checkbox checked={isChecked(String(option.value))} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultipleSelect.defaultProps = {
  value: '',
  options: [],
  fullWidth: true,
  className: '',
};

MultipleSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  options: PropTypes.array,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};
