import { createSlice } from '@reduxjs/toolkit';
import { fetchSingleTicket, deleteTicket, replyOnTicket, updateTicket } from './actions';

const initialState = {
  data: null,
  fetching: false,
  isModalVisible: false,
};

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    setTicketReplyModalVisible: (state, { payload }) => {
      return { ...state, isModalVisible: payload };
    },
  },
  extraReducers: {
    [fetchSingleTicket.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [fetchSingleTicket.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: payload.ticket,
        fetching: false,
      };
    },
    [fetchSingleTicket.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [deleteTicket.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [deleteTicket.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: payload.id,
        fetching: false,
      };
    },
    [deleteTicket.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [replyOnTicket.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [replyOnTicket.fulfilled]: (state) => {
      return { ...state, fetching: false, isModalVisible: false };
    },
    [replyOnTicket.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [updateTicket.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: payload.ticket,
      };
    },
  },
});

export const { setTicketReplyModalVisible } = ticketSlice.actions;

export default ticketSlice.reducer;
