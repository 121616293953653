import {
  STORE_REMINDERS,
  FETCH_REMINDERS_STARTED,
  UPDATE_REMINDER_STARTED,
  UPDATE_REMINDER,
  ADD_REMINDER,
  REMOVE_REMINDER,
  SET_PARAMS,
  OPEN_EDIT_REMINDER_MODAL,
  CLOSE_EDIT_REMINDER_MODAL,
  OPEN_CREATE_REMINDER_MODAL,
  CLOSE_CREATE_REMINDER_MODAL,
  CLEAR_STATE,
} from './constants';
import { nullMeta } from '../../helpers/meta';
import {
  immutableInsert,
  immutablePush,
  immutableFindAndDelete,
} from '../../../helpers/immutableHelper';

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  meta: nullMeta,

  params: {
    page: 1,
    per_page: 10,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    q: undefined,
  },

  editModalVisible: false,
  createModalVisible: false,
  reminder: null,

  updating: false,
  updated: false,
};

function fetchRemindersStartedHandler(state) {
  return { ...state, fetching: true, fetched: false };
}

function storeRemindersHandler(state, action) {
  return {
    ...state,
    items: action.data,
    meta: action.meta,
    fetching: false,
    fetched: true,
  };
}

export function setParamsHandler(state, action) {
  return { ...state, params: { ...state.params, ...action.data } };
}

export function updateReminderStartedHandler(state) {
  return { ...state, updating: true, updated: false };
}

export function updateReminderHandler(state, action) {
  return {
    ...state,
    updating: false,
    updated: true,
    items: immutableInsert(
      state.items,
      action.data,
      state.items.findIndex((rem) => rem.id === action.data.id)
    ),
  };
}

export function addReminderHandler(state, action) {
  return { ...state, items: immutablePush(state.items, action.data) };
}

export function removeReminderHandler(state, action) {
  return {
    ...state,
    items: immutableFindAndDelete(state.items, (r) => r.id === action.reminderId),
  };
}

export function openEditReminderModalHandler(state, action) {
  return {
    ...state,
    editModalVisible: true,
    reminder: action.reminder,
  };
}

export function closeEditReminderModalHandler(state) {
  return { ...state, editModalVisible: false };
}

export function openCreateReminderModalHandler(state) {
  return { ...state, createModalVisible: true };
}

export function closeCreateReminderModalHandler(state) {
  return { ...state, createModalVisible: false };
}

export function clearRemindersHandler() {
  return initialState;
}

const ACTION_HANDLERS = {
  [FETCH_REMINDERS_STARTED]: fetchRemindersStartedHandler,
  [STORE_REMINDERS]: storeRemindersHandler,
  [UPDATE_REMINDER_STARTED]: updateReminderStartedHandler,
  [UPDATE_REMINDER]: updateReminderHandler,
  [ADD_REMINDER]: addReminderHandler,
  [REMOVE_REMINDER]: removeReminderHandler,
  [SET_PARAMS]: setParamsHandler,
  [OPEN_EDIT_REMINDER_MODAL]: openEditReminderModalHandler,
  [CLOSE_EDIT_REMINDER_MODAL]: closeEditReminderModalHandler,
  [OPEN_CREATE_REMINDER_MODAL]: openCreateReminderModalHandler,
  [CLOSE_CREATE_REMINDER_MODAL]: closeCreateReminderModalHandler,
  [CLEAR_STATE]: clearRemindersHandler,
};

export default function customFieldsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
