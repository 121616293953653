import EngineService from '../core/engine-service';

const ENDPOINT = '/opportunities';

// GET: /opportunities
export function getOpportunities(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

// GET: /opportunities/:id
export function getOpportunity(opportunityId) {
  return EngineService.get(`${ENDPOINT}/${opportunityId}`).then((json) => json);
}

// POST: /opportunities
export function createOpportunity(payload) {
  return EngineService.post(ENDPOINT, {
    opportunity: payload,
  }).then((json) => json);
}

// PATCH: /opportunities/:id
export function updateOpportunity(opportunityId, payload) {
  return EngineService.patch(`${ENDPOINT}/${opportunityId}`, {
    opportunity: payload,
  }).then((json) => json);
}

// DELETE: /opportunities/:id
export function deleteOpportunity(opportunityId) {
  return EngineService.delete(`${ENDPOINT}/${opportunityId}`).then((json) => json);
}

// GET: /opportunities/:id/custom_objects
export function getCustomObjects(id) {
  return EngineService.get(`${ENDPOINT}/${id}/custom_objects`).then((json) => json);
}

// GET: /unconfirmed_opportunities
export function getUnconfirmedOpportunities() {
  return EngineService.get('/unconfirmed_opportunities').then((json) => json);
}

// POST: /unconfirmed_opportunities/batch_update
export function unconfirmedOpportunitiesBatchUpdate(data) {
  return EngineService.post('/unconfirmed_opportunities/batch_update', {
    data,
  }).then((json) => json);
}

// GET: /search/opportunities/columns
export function getOpportunityFilterProperties(params = {}) {
  return EngineService.get('/filters/opportunities/columns', params).then((json) => json);
}

// POST: /managers/opportunities/:id/assign_opportunities
export function updateOpportunityOwner(opportunityId, payload) {
  return EngineService.post(`/managers/opportunities/${opportunityId}/assign_opportunities`, {
    opportunity: payload,
  });
}

export function getOpportunityAmountByPriority(params = {}) {
  return EngineService.get('/opportunity_amounts_by_priority', params).then((json) => json.amounts);
}

export function getOpportunityAmountByStages(params = {}) {
  return EngineService.get('/opportunity_amounts_by_stage', params).then((json) => json.amounts);
}

export function batchDeleteOpportunities(opportunities) {
  return EngineService.post('/opportunities/batch_delete', { opportunities }).then((json) => json);
}

export function cloneOpportunity(opportunityId) {
  return EngineService.post(`${ENDPOINT}/${opportunityId}/clone`).then((json) => json);
}

export default {
  getOpportunities,
  getOpportunity,
  createOpportunity,
  updateOpportunity,
  deleteOpportunity,
  getUnconfirmedOpportunities,
  unconfirmedOpportunitiesBatchUpdate,
  getOpportunityFilterProperties,
  batchDeleteOpportunities,
  cloneOpportunity,
};
