import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Skeleton from '@mui/material/Skeleton';
import TwilioOnly from 'components/TwilioOnly';
import styles from './UserInfo.module.scss';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(253, 239, 233, 0.4)',
    height: '17px',
  },
});

export default function UserInfo({ name, handleClick, open, twilioEnabled }) {
  const classes = useStyles();

  return (
    <div className={styles['user-info']} role="button" tabIndex="0" onClick={handleClick}>
      {!name && (
        <div className={styles.skeleton}>
          <Skeleton data-testid="skeleton" variant="text" className={classes.root} />
          <Skeleton variant="text" className={classes.root} />
        </div>
      )}
      {name && (
        <div>
          <p className={styles.username} data-cy="navbar-user-full-name-label">
            {name}
          </p>
          <TwilioOnly>
            <p className={styles.calls} data-cy="navbar-calls-status-label">
              Calls {twilioEnabled ? 'Available' : 'Unavailable'}
            </p>
          </TwilioOnly>
        </div>
      )}
      <ExpandMoreIcon disabled className={open ? styles['rotate-icon'] : ''} />
    </div>
  );
}

UserInfo.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  twilioEnabled: PropTypes.bool.isRequired,
};
