import PropTypes from 'prop-types';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
  },
});

export default function CurrencyField({ label, currency, ...props }) {
  const classes = useStyles();

  return (
    <FormControl className={classes.root} fullWidth variant="filled">
      <InputLabel htmlFor="filled-adornment-amount">{label}</InputLabel>
      <FilledInput
        {...props}
        type="number"
        startAdornment={<InputAdornment position="start">{currency}</InputAdornment>}
      />
    </FormControl>
  );
}

CurrencyField.defaultProps = {
  currency: '$',
};

CurrencyField.propTypes = {
  label: PropTypes.string.isRequired,
  currency: PropTypes.string,
};
