import PropTypes from 'prop-types';
import cs from 'classnames';
import MuiTextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import MentionInput from 'lib/MentionInput';

const useStylesCompact = makeStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiFilledInput-input': {
      padding: '13px 8px',
    },
  },
});

const useStyles = makeStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
    '& input.Mui-disabled': {
      '-webkit-text-fill-color': 'inherit',
    },
  },
});

export default function TextField({
  mentionable,
  mentionData,
  onMentionAdd,
  className,
  variant,
  fullWidth,
  isCompact,
  required,
  ...props
}) {
  const classes = isCompact ? useStylesCompact() : useStyles();

  if (mentionable) {
    return (
      <MentionInput
        mentionData={mentionData}
        onMentionAdd={onMentionAdd}
        className={className}
        fullWidth={fullWidth}
        {...props}
      />
    );
  }
  return (
    <MuiTextField
      {...props}
      fullWidth={fullWidth}
      variant={variant}
      autoComplete="off"
      className={cs(classes.root, className)}
      required={required}
    />
  );
}

TextField.defaultProps = {
  fullWidth: true,
  variant: 'filled',
  className: '',
  mentionable: false,
  mentionData: [],
  onMentionAdd: () => {},
  required: false,
};

TextField.propTypes = {
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  mentionable: PropTypes.bool,
  mentionData: PropTypes.array,
  onMentionAdd: PropTypes.func,
  isCompact: PropTypes.bool.isRequired,
  required: PropTypes.bool,
};
