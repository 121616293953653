import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Day from 'dayjs';
import { getReminders, clearState, updateReminder } from 'state/nested-reminders/actions';
import {
  selectReminders,
  selectMeta,
  selectParams,
  selectIsLoading,
} from 'state/nested-reminders/selectors';
import { nullFilter } from 'helpers/filters';
import { setCurrentFilters } from 'state/filters/reminders/actions';

export default function useReminders(defaultParams = { per_page: 10 }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const reminders = useSelector(selectReminders);
  const meta = useSelector(selectMeta);
  const params = useSelector(selectParams);

  const [searchQuery, setSearchQuery] = useState('');

  const fetchReminders = (requestParams = {}) => {
    return dispatch(getReminders({ ...defaultParams, ...requestParams }));
  };

  const handleUpdateReminder = (id, payload) => {
    const reminder = reminders.find((r) => r.id === id);
    return dispatch(updateReminder(reminder, payload));
  };

  const markAsDone = (id) => {
    handleUpdateReminder(id, { done_at: new Day().format() }).then(() =>
      fetchReminders({ page: 1 })
    );
  };

  const getPage = useCallback((page) => {
    fetchReminders({ page });
  }, []);

  const sortReminders = useCallback((sortBy, sortOrder) => {
    fetchReminders({ sort: sortBy, order: sortOrder, page: 1 });
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleSearchEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        fetchReminders({ q: searchQuery ? `reason:*${searchQuery}*` : undefined, page: 1 });
      }
    },
    [searchQuery]
  );

  const handleSearchClear = useCallback(() => {
    setSearchQuery('');
    fetchReminders({ q: '', page: 1 });
  }, []);

  const clearAppliedFilters = useCallback(() => {
    fetchReminders({ q: undefined, page: undefined });
    dispatch(setCurrentFilters(nullFilter));
  }, []);

  useEffect(() => {
    fetchReminders();
  }, []);

  useEffect(
    () => () => {
      dispatch(clearState());
    },
    []
  );

  return {
    isLoading,
    reminders,
    meta,
    params,
    getPage,
    sortReminders,
    handleSearchChange,
    handleSearchEnterPress,
    handleSearchClear,
    searchQuery,
    fetchReminders,
    clearAppliedFilters,
    handleUpdateReminder,
    markAsDone,
  };
}
