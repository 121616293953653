export const GET_CAMPAIGN_REQUEST_STARTED = 'campaign: get_campaign_request_started';
export const GET_CAMPAIGN_REQUEST_SUCCESS = 'campaign: get_campaign_request_success';
export const GET_CONTACTS_REQUEST_STARTED = 'campaign: get_contacts_request_started';
export const GET_CONTACTS_REQUEST_SUCCESS = 'campaign: get_contacts_request_success';
export const DELETE_CONTACTS_REQUEST_STARTED = 'campaign: delete_contacts_request_started';
export const DELETE_CONTACTS_REQUEST_SUCCESS = 'campaign: delete_contacts_request_success';
export const GET_EMAIL_CAMPAIGNS_REQUEST_STARTED = 'campaign: get_email_campaigns_request_started';
export const GET_EMAIL_CAMPAIGNS_REQUEST_SUCCESS = 'campaign: get_email_campaigns_request_success';
export const UPDATE_EMAIL_CAMPAIGN_LOCAL = 'campaign: update_email_campaign_local';
export const DELETE_EMAIL_CAMPAIGN = 'campaign: delete_email_campaign';
export const REPLACE_EMAIL_CAMPAIGN = 'campaign: replace_email_campaign';
export const UPDATE_CAMPAIGN_LOCAL = 'campaign: update_campaign_local';
export const ADD_EMAIL_CAMPAIGN = 'campaign: add_email_campaign';
export const SET_CONTACTS_PARAMS = 'campaign: set_contacts_params';
export const CLEAR_STATE = 'campaign: clear_state';
export const STORE_CONTACT_LIST_DISPLAY_FIELDS = 'campaign: store_contact_list_display_fields';
export const REPLACE_CONTACT = 'campaign: replace_contact';
export const SET_SELECTION_MODEL = 'campaign: set_selection_model';
export const RESET_SELECTION_MODEL = 'campaign: reset_selection_model';
export const RESET_CAMPAIGN_CONTACTS = 'campaign: reset_campaign_contacts';
export const UPDATE_EMAIL_CAMPAIGN_REQUEST_STARTED =
  'campaign: update_email_campaign_request_started';
export const UPDATE_EMAIL_CAMPAIGN_REQUEST_SUCCESS =
  'campaign: update_email_campaign_request_success';
export const RESET_EMAIL_CAMPAIGNS_DATA = 'campaign: reset_email_campaigns_data';
