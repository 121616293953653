import { useDispatch, useSelector } from 'react-redux';
import TextField from 'lib/TextField';
import { setFormValues } from '../state/actions';
import { selectFormValues } from '../state/selectors';

export default function CallSubject() {
  const formValues = useSelector(selectFormValues);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(
      setFormValues({
        ...formValues,
        subject: e.target.value,
      })
    );
  };

  return <TextField label="Subject" value={formValues.subject} onChange={handleChange} />;
}
