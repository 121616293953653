import SidebarItem from './SidebarItem';
import styles from './SideBar.module.scss';
import ListsMenu from '../ListsMenu/ListsMenu';

export default function SideBar() {
  const fields = [
    { name: 'assistant' },
    { name: 'reminders' },
    { name: 'opportunities' },
    { name: 'contacts' },
    { name: 'companies' },
    { name: 'activities' },
    { name: 'campaigns' },
    { name: 'reports' },
    { name: 'tickets' },
    { name: 'quotes' },
  ];

  return (
    <div className={styles['side-bar']}>
      {fields.map((field) => (
        <SidebarItem key={field.name} field={field} />
      ))}
      <ListsMenu />
    </div>
  );
}
