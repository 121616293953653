import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Button from 'lib/Button';
import CallNotes from 'components/CallDialog/CallNotes';
import CallSubject from 'components/CallDialog/CallSubject';
import Select from 'lib/Select';
import { error as errorAlert } from 'state/notifications/actions';
import { getInteractionCustomFields } from 'state/custom-fields/selectors';
import {
  selectFormValues,
  selectIsOutgoingCall,
  selectLogActivityAfterCall,
  selectTwilioCaller,
  selectTwilioCallers,
  selectTwilioConnection,
} from 'components/CallDialog/state/selectors';
import {
  getCustomFieldIdForCustomObjectLookup,
  getPickerKeyForCustomObjectLookup,
} from 'helpers/custom-fields';
import { setActiveCallModalOpen, setFormValues } from 'components/CallDialog/state/actions';
import { customFieldsToArray } from 'components/CustomFields/helper';
import InputRenderer from 'components/InputRenderer';
import { getCFOptions } from 'helpers/display-fields';
import useCallerInfo from '../CallerInfo/useCallerInfo';
import useLogActivity from './useLogActivity';
import { useStyles } from './useStyle';

const LogActivityForm = ({ show }) => {
  const { isConnectionOpen } = useCallerInfo();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logActivity } = useLogActivity();
  const classes = useStyles();
  const caller = useSelector(selectTwilioCaller);
  const callers = useSelector(selectTwilioCallers);
  const connection = useSelector(selectTwilioConnection);
  const logActivityAfterCall = useSelector(selectLogActivityAfterCall);
  const isOutgoingCall = useSelector(selectIsOutgoingCall);
  const interactionCustomFields = useSelector((state) => getInteractionCustomFields(state));
  const customFieldsArray = customFieldsToArray(interactionCustomFields);
  const formValues = useSelector(selectFormValues);

  const onCustomFieldChange = (event) => {
    const { value, name } = event.target;
    dispatch(
      setFormValues({
        ...formValues,
        custom: {
          ...formValues.custom,
          [name]: value,
        },
      })
    );
  };

  const customFields = customFieldsArray.length
    ? [
        ...customFieldsArray.map((field) => ({
          show: true,
          name: field.name,
          component: () => (
            <InputRenderer
              field={{
                type: field.type,
                name: field.name,
                label: field.label,
                value: formValues.custom[field.name],
                options: getCFOptions(field, formValues.custom),
                onChange: onCustomFieldChange,
                compactLookup: false,
                customFieldId: getCustomFieldIdForCustomObjectLookup(
                  field,
                  interactionCustomFields
                ),
                pickerKey: getPickerKeyForCustomObjectLookup(field, interactionCustomFields),
              }}
            />
          ),
        })),
      ]
    : [];

  const handleOnChange = (e) => {
    dispatch(
      setFormValues({
        ...formValues,
        disposition: e.target.value,
      })
    );
  };

  if (!show) return null;

  const options = [
    {
      label: t('localization.activity.disposition.connected'),
      value: 'outbound_call',
    },
    {
      label: t('localization.activity.disposition.left_voicemail'),
      value: 'left_voicemail',
    },
    {
      label: t('localization.activity.disposition.didnt_connect'),
      value: 'no_connect',
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    if (!logActivityAfterCall) {
      dispatch(setActiveCallModalOpen(false));
    } else if (logActivityAfterCall && !formValues.disposition && isOutgoingCall) {
      dispatch(errorAlert('Select disposition value!'));
    } else {
      logActivity();
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Box className={classes.root}>
        {!connection && isOutgoingCall && caller && (
          <FormGroup>
            <Select
              name="disposition"
              value={formValues.disposition}
              label="What happened?"
              removable={false}
              options={options}
              native={false}
              onChange={handleOnChange}
            />
          </FormGroup>
        )}
        {!connection && caller && (
          <FormGroup>
            <CallSubject />
          </FormGroup>
        )}
        <FormGroup className={classes.noteField}>
          <CallNotes />
        </FormGroup>
        {!connection && caller && (
          <>
            {customFields.map((f, index) => (
              <FormGroup key={index}>{f.component()}</FormGroup>
            ))}
          </>
        )}
      </Box>
      <Button
        disabled={
          (!caller && callers.length > 1) || (!caller && !callers.length) || isConnectionOpen()
        }
        className={classes.button}
        type="submit"
        value="Submit"
        color="primary"
      >
        Save and close
      </Button>
      {!caller && callers.length > 1 && !connection && (
        <Typography color="error" className={classes.alertMessage} variant="caption">
          Please select or add contact!
        </Typography>
      )}
      {!caller && !callers.length && !connection && (
        <Typography color="error" className={classes.alertMessage} variant="caption">
          Please add contact!
        </Typography>
      )}
    </form>
  );
};

LogActivityForm.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default LogActivityForm;
