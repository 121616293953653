import { useSelector, useDispatch } from 'react-redux';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import IconButtonWithLabel from 'lib/IconButtonWithLabel';
import { success as successAlert } from 'state/notifications/actions';
import {
  selectCallModalMinimized,
  selectCallMuted,
  selectTwilioConnection,
} from '../state/selectors';
import { muteCall } from '../state/actions';
import styles from './MuteCallButton.module.scss';

const MuteCallButton = () => {
  const dispatch = useDispatch();

  const connection = useSelector(selectTwilioConnection);
  const callMuted = useSelector(selectCallMuted);
  const isMinimized = useSelector(selectCallModalMinimized);

  const toggleMute = () => {
    connection.mute(!callMuted);
    dispatch(muteCall(!callMuted));
    dispatch(successAlert(callMuted ? 'Call unmuted.' : 'Call muted.'));
  };

  const showLabel = () => {
    if (isMinimized) return null;
    return callMuted ? 'Muted' : 'Mute';
  };

  const toggleColor = () => {
    if (isMinimized) return callMuted ? 'white-minimized' : 'purple-minimized';
    return callMuted ? 'dark-purple' : 'purple';
  };

  return (
    <div className={styles.container}>
      <IconButtonWithLabel
        icon={<MicOffOutlinedIcon />}
        classes={styles.button}
        color={toggleColor()}
        iconSize={isMinimized ? 'medium' : 'large'}
        handleOnClick={toggleMute}
        label={showLabel()}
      />
    </div>
  );
};

export default MuteCallButton;
