import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { selectCallDuration, selectCallTimer } from '../state/selectors';
import { incrementCallDurationBySecond } from '../state/actions';

const CallDurationTimer = () => {
  const dispatch = useDispatch();

  const callDuration = useSelector(selectCallDuration);
  const isTimerStarted = useSelector(selectCallTimer);

  useEffect(() => {
    let interval = null;

    if (isTimerStarted) {
      interval = setInterval(() => {
        dispatch(incrementCallDurationBySecond());
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isTimerStarted]);

  return (
    <Typography variant="caption">
      {callDuration >= 3600 ? (
        <span>{`0${Math.floor((callDuration / 3600) % 60)}`.slice(-2)}:</span>
      ) : null}
      <span>{`0${Math.floor((callDuration / 60) % 60)}`.slice(-2)}:</span>
      <span>{`0${Math.floor((callDuration / 1) % 60)}`.slice(-2)}</span>
    </Typography>
  );
};

export default CallDurationTimer;
