import { connect } from 'react-redux';
import { selectUserId } from 'state/user/selectors';
import { resourceTypes } from 'helpers/utils';
import { getCustomFields } from 'state/custom-fields/selectors';
import { getContactDisplayFieldsForCreate } from 'state/display-fields/selectors';
import { selectActiveUsers } from 'state/users/selectors';
import NewContactModal from './NewContactModal';

function mapStateToProps(state) {
  return {
    users: selectActiveUsers(state),
    userId: selectUserId(state),
    customFields: getCustomFields(resourceTypes.CONTACT)(state),
    displayFields: getContactDisplayFieldsForCreate(state),
    createInProgress: state.nestedContacts.createInProgress || state.contacts.createInProgress,
    usersCountryCode: state.user.countryCode,
  };
}

export default connect(mapStateToProps)(NewContactModal);
