import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import IconButtonWithLabel from 'lib/IconButtonWithLabel';
import useCallActions from '../CallMainActions/useCallActions';
import styles from './CallActions.module.scss';

export default function CallActions({ color }) {
  const {
    setReminder,
    sendEmail,
    sendText,
    contactHasPhoneNumber,
    contact,
    caller,
  } = useCallActions();

  return (
    <Box className={styles.container}>
      <IconButtonWithLabel
        icon={<AccessAlarmsIcon />}
        classes={styles.button}
        color={color}
        handleOnClick={setReminder}
        label="Reminder"
        disabled={!caller}
      />
      <IconButtonWithLabel
        icon={<MailOutlineIcon />}
        classes={styles.button}
        color={color}
        handleOnClick={sendEmail}
        label="Send Email"
        disabled={!caller || !contact?.email}
      />
      <IconButtonWithLabel
        icon={<TextsmsOutlinedIcon />}
        classes={styles.button}
        color={color}
        handleOnClick={sendText}
        label="Send Text"
        disabled={!caller || !contactHasPhoneNumber}
      />
    </Box>
  );
}

CallActions.defaultProps = {
  color: 'white',
};

CallActions.propTypes = {
  color: PropTypes.string,
};
