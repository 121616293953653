import { SHOW_MODAL_DIALOG, HIDE_MODAL_DIALOG } from './constants';

export function showModal(name, props) {
  return {
    type: SHOW_MODAL_DIALOG,
    name,
    props,
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL_DIALOG,
  };
}
