import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getTicket,
  deleteTicket as del,
  updateTicket as updateTicketRemote,
} from 'services/ticket';
import { error } from 'state/notifications/actions';
import { sendEmail } from 'services/support-desk-messages';
import { forEachError } from '../../../../helpers/errorHelper';
import { serializeForm } from '../../../../helpers/formHelper';
import { replaceTicket } from '../../Tickets/state/data/reducer';

export const fetchSingleTicket = createAsyncThunk('ticket-get', async (ticketId, { dispatch }) => {
  try {
    const res = await getTicket(ticketId);
    return res;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const deleteTicket = createAsyncThunk('ticket-delete', async (ticketId, { dispatch }) => {
  try {
    return await del(ticketId);
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const replyOnTicket = createAsyncThunk('ticket-reply', async (payload, { dispatch }) => {
  try {
    return await sendEmail(payload);
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const updateTicket = createAsyncThunk('ticket-update', async (payload, { dispatch }) => {
  try {
    const res = await updateTicketRemote(payload.id, serializeForm(payload.value));
    dispatch(replaceTicket(res));
    return Promise.resolve(res);
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});
