import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { convertNameToInitials } from 'helpers/utils';
import { selectTwilioCaller, selectTwilioCallers } from 'components/CallDialog/state/selectors';
import useCallerInfo from '../useCallerInfo';
import styles from './CallerInfoImage.module.scss';

export default function CallerInfoImage() {
  const { isConnectionPending } = useCallerInfo();
  const callers = useSelector(selectTwilioCallers);
  const caller = useSelector(selectTwilioCaller);

  return (
    <Box className={styles.avatar}>
      {caller && caller.photo_url ? (
        <img src={caller.photo_url} className={styles['caller-avatar']} alt="Contact Avatar" />
      ) : (
        caller && <div className={styles['known-caller']}>{convertNameToInitials(caller.name)}</div>
      )}
      {!caller && callers.length === 0 && (
        <div className={styles['unknown-caller']}>
          <PermIdentityOutlinedIcon />
        </div>
      )}
      {callers.length > 1 && !caller && (
        <div className={isConnectionPending() ? styles['unknown-caller'] : styles.multiple}>
          <GroupOutlinedIcon />
        </div>
      )}
    </Box>
  );
}
