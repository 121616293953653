import { parseMetaFromResponse } from 'helpers/meta';
import {
  fetchFilterProperties,
  fetchSavedFilters,
} from 'state/filters/nested-interactions/actions';
import { serializeFiltersToString } from 'helpers/filters';
import activityService from 'services/activities';
import noteService from 'services/notes';
import attachmentService from 'services/attachments';
import {
  FETCH_INTERACTIONS_STARTED,
  STORE_INTERACTIONS,
  SET_PARAMS,
  SET_IS_REFRESH_REQUIRED,
  CLEAR_INTERACTIONS,
  SHOW_MODAL,
  HIDE_MODAL,
} from './constants';
import http from '../../core/engine-service';
import { forEachError } from '../../../helpers/errorHelper';
import { error as errorAlert } from '../notifications/actions';

export function storeInteractions(interactions, meta) {
  return {
    type: STORE_INTERACTIONS,
    data: interactions,
    meta: parseMetaFromResponse(meta),
  };
}

export function setParams(data) {
  return {
    type: SET_PARAMS,
    data,
  };
}

export function setIsRefreshRequired(isRefreshRequired) {
  return {
    type: SET_IS_REFRESH_REQUIRED,
    isRefreshRequired,
  };
}

export function fetchInteractionsStarted() {
  return {
    type: FETCH_INTERACTIONS_STARTED,
  };
}

export function clearInteractions() {
  return {
    type: CLEAR_INTERACTIONS,
  };
}

function getParamsFromState(state) {
  const params = state.nestedInteractions.params;
  const currentFilters = state.filters.nestedInteractions.currentFilters;

  return {
    ...params,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export function getInteractions(params = {}) {
  return async (dispatch, getState) => {
    dispatch(fetchInteractionsStarted());
    dispatch(fetchFilterProperties());
    dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      return http
        .get('/interactions', payload)
        .then((json) => {
          dispatch(storeInteractions(json.interactions, json.meta));
          dispatch(setParams(payload));
          return Promise.resolve(json);
        })
        .catch((err) => Promise.reject(err));
    });
  };
}

export function deleteInteraction(interactionID) {
  return (dispatch) => {
    const handleSuccess = () => dispatch(getInteractions());
    const handleError = (error) => console.error(error);

    return http.delete(`/interactions/${interactionID}`).then(handleSuccess).catch(handleError);
  };
}

export function showModal(modalName) {
  return {
    type: SHOW_MODAL,
    modalName,
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}

function handleCreateSuccess(json, dispatch) {
  dispatch(getInteractions());
  return Promise.resolve(json);
}

function handleCreateFailure(err, dispatch) {
  if (err.status !== 422) {
    forEachError(err.data, (e) => dispatch(errorAlert(e)));
  }
  return Promise.reject(err);
}

export function createNote(payload) {
  return (dispatch) =>
    noteService
      .create(payload)
      .then((json) => {
        handleCreateSuccess(json, dispatch);
      })
      .catch((err) => handleCreateFailure(err, dispatch));
}

export function createAttachment(payload) {
  return (dispatch) =>
    attachmentService
      .create(payload)
      .then((json) => {
        handleCreateSuccess(json, dispatch);
      })
      .catch((err) => handleCreateFailure(err, dispatch));
}

export function createActivity(payload) {
  return (dispatch) =>
    activityService
      .create(payload)
      .then((json) => {
        handleCreateSuccess(json, dispatch);
      })
      .catch((err) => handleCreateFailure(err, dispatch));
}
