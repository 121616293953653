import { useEffect, Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Day from 'dayjs';
import Button from 'lib/Button';
import { selectShouldRefetch } from 'state/app-notifications/selectors';
import { hideNotificationsSlider } from 'state/app-notifications/actions';
import { getCounters } from 'state/counters/actions';
import { ReactComponent as Icon } from './received-text-icon.svg';
import DateHeader from '../DateHeader';
import useTexts from './useTexts';
import styles from './Texts.module.scss';

function Texts() {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const {
    texts,
    isLoading,
    isConfirming,
    meta,
    fetchTexts,
    confirmText,
    confirmAllTexts,
  } = useTexts();
  const shouldRefetch = useSelector(selectShouldRefetch);

  const handleClick = (text) => () => {
    dispatch(hideNotificationsSlider());

    if (!text.confirmed) {
      confirmText(text.id).then(() => {
        dispatch(getCounters());
      });
    }
  };

  const handleMarkAllAsSeen = () => {
    confirmAllTexts()
      .then(() => {
        fetchTexts({ page: 1 });
      })
      .then(() => {
        dispatch(getCounters());
      });
  };

  const handleLoadMore = () => {
    const { scrollTop, clientHeight, scrollHeight } = ref.current;
    const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

    if (scrolledToBottom && !isLoading && meta.nextPage) {
      fetchTexts({ page: meta.nextPage });
    }
  };

  useEffect(() => {
    fetchTexts({ page: 1 });
  }, [shouldRefetch]);

  return (
    <div className={styles.container}>
      <div className={styles.texts} ref={ref} onScroll={handleLoadMore}>
        {Object.keys(texts).map((group, index) => (
          <Fragment key={index}>
            <DateHeader date={group} />
            {texts[group].map((text) => (
              <Link
                to={`/activities?activityID=${text.id}&kind=${text.kind}`}
                key={text.id}
                className={styles.text}
                onClick={handleClick(text)}
              >
                <Icon />
                <span className={styles.time}>{new Day(text.interacted_at).format('hh:mm A')}</span>
                <div className={styles.info}>
                  {!text.confirmed && <div className={styles.new}>New</div>}
                  <div className={styles.displayName}>{text.owner?.name}</div>
                  <div className={styles.subject}>{text.owner?.account?.name}</div>
                </div>
              </Link>
            ))}
          </Fragment>
        ))}
        {isLoading && <div className={styles.loader}>Loading ...</div>}
      </div>
      <div className={styles.actions}>
        <Button
          color="primary"
          variant="contained"
          disabled={isConfirming}
          onClick={handleMarkAllAsSeen}
        >
          Mark all as seen
        </Button>
      </div>
    </div>
  );
}

export default Texts;
