import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

const ListBox = forwardRef(function ListBoxBase(props, ref) {
  const { children, ...rest } = props;

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  return (
    // eslint-disable-next-line jsx-a11y/aria-role
    <ul {...rest} ref={innerRef} role="list-box" style={{ paddingTop: 0 }}>
      {children}
    </ul>
  );
});

ListBox.defaultProps = {
  children: [],
};

ListBox.propTypes = {
  children: PropTypes.array,
};

export default ListBox;
