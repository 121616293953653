import get from 'lodash/get';
import Moment from 'moment';

export const errorCodes = {
  JWT_TOKEN_EXPIRED: 31205,
};

export function getPhoneNumberFromConnection(connection) {
  return get(connection, 'parameters.From') || get(connection, 'message.phoneNumber');
}

export function removePhoneNumberFormatting(phoneNumber) {
  return phoneNumber
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(' ', '')
    .replace(' ', '');
}

export function parseBlockedPhone(phoneNumber) {
  return {
    id: phoneNumber.id,
    name: phoneNumber.name || '',
    number: phoneNumber.number,
    userId: phoneNumber.user_id,
    createdAt: new Moment(phoneNumber.created_at),
  };
}

export function mapCallerFromContact(contact) {
  const payload = {
    name:
      contact.name ||
      `${contact.firstName || contact.first_name} ${contact.lastName || contact.last_name}`,
    account_name: contact.accountName || contact.account_name,
    photo_url: contact.photoUrl || contact.photo_url,
    resource_id: contact.id,
    resource_type: 'Contact',
  };
  return payload;
}

export function mapCallerFromTicket(ticket) {
  const payload = {
    name: ticket.contact.full_name,
    account_name: ticket.contact.account_name,
    photo_url: null,
    resource_id: ticket.contact.id,
    resource_type: 'Contact',
  };
  return payload;
}

export function mapCallerFromAccount(account) {
  const payload = {
    name: account.name,
    account_name: account.name,
    photo_url: null,
    resource_id: account.id,
    resource_type: 'Account',
  };
  return payload;
}

export default {
  errorCodes,
  removePhoneNumberFormatting,
  getPhoneNumberFromConnection,
  mapCallerFromContact,
  mapCallerFromTicket,
  mapCallerFromAccount,
};
