import PropTypes from 'prop-types';
import cs from 'classnames';
import ImageLink from './ImageLink';
import SpiroLinkify from '../SpiroLinkify';
import styles from './ChatContent.module.scss';

export default function ChatItem({ text, position, mediaUrls, children, time, ...props }) {
  return (
    <div
      className={cs(styles.container, {
        [styles['content-left']]: position === 'left',
        [styles['content-right']]: position === 'right',
      })}
    >
      <div
        className={cs(styles.content, {
          [styles['content-contact']]: position === 'left',
          [styles['content-user']]: position === 'right',
        })}
      >
        <SpiroLinkify>
          {children}
          <p>{text}</p>
        </SpiroLinkify>
        {mediaUrls.map((url) => (
          <ImageLink key={url} url={url} {...props} />
        ))}
        <div className={styles.chat__time}>
          <p
            className={
              position === 'left' ? styles['chat__time--left'] : styles['chat__time--right']
            }
          >
            {time}
          </p>
        </div>
      </div>
    </div>
  );
}

ChatItem.defaultProps = {
  mediaUrls: [],
  text: '',
  children: null,
  time: '',
};

ChatItem.propTypes = {
  position: PropTypes.string.isRequired,
  text: PropTypes.string,
  mediaUrls: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  time: PropTypes.string,
};
