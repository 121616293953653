import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '@mui/icons-material/ErrorOutlineTwoTone';
import Button from 'lib/Button';

const DetailsLinkButton = ({ children, ...props }) => (
  <Button startIcon={<Icon />} component={Link} {...props}>
    {children}
  </Button>
);

DetailsLinkButton.defaultProps = {
  children: 'Details',
};

DetailsLinkButton.propTypes = {
  children: PropTypes.string,
};

export default DetailsLinkButton;
