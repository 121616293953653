import { REQUEST_STARTED, REQUEST_SUCCESS, REPLACE_OPPORTUNITY } from './constants';

const initialState = {
  requestStarted: false,
  requestSuccess: false,
  data: null,
};

function requestStartedHandler(state) {
  return {
    ...state,
    requestStarted: true,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    data: action.data,
  };
}

function replaceOpportunityHandler(state, action) {
  return {
    ...state,
    data: action.data,
  };
}

const ACTION_HANDLERS = {
  [REQUEST_STARTED]: requestStartedHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REPLACE_OPPORTUNITY]: replaceOpportunityHandler,
};

export default function opportunityReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
