import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import Reminder from 'models/Reminder';
import { getReminders, updateReminder } from './actions';

const initialState = {
  data: [],
  meta: nullMeta,
  fetching: false,
  params: {
    page: 1,
    sort: undefined,
    order: undefined,
    assistant_rule_id: undefined,
  },
};

export const remindersSlice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {},
  extraReducers: {
    [getReminders.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [getReminders.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        fetching: false,
        data: payload.reminders.map((item) => new Reminder(item)),
        meta: parseMetaFromResponse(payload.meta),
        params: payload.params,
      };
    },
    [getReminders.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [updateReminder.fulfilled]: (state, { payload }) => {
      const newData = state.data.map((r) => {
        if (r.id === payload.reminder.id) {
          return new Reminder(payload.reminder);
        }
        return r;
      });
      return { ...state, data: newData };
    },
  },
});

export default remindersSlice.reducer;
