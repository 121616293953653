import MuiTab from '@mui/material/Tab';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    color: '#999999',
  },
  selected: {
    color: '#5D43CF',
    opacity: 1,
  },
});

export default function Tab(props) {
  const classes = useStyles();
  return <MuiTab classes={classes} {...props} />;
}
