import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    width: '80%',
    margin: 'auto',
    height: '540px',
    overflowY: 'auto',
    overflowX: 'hidden',

    '& .MuiFilledInput-input': {
      textAlign: 'left',
    },
  },
  button: {
    position: 'absolute',
    bottom: '30px',
    right: '38px',
  },
  alertMessage: {
    position: 'absolute',
    bottom: '20px',
    right: '44px',
  },
  noteField: {
    marginBottom: '8px',
  },
}));

export { useStyles };
