import EngineService from '../core/engine-service';

const ENDPOINT = '/accounts';

export function getAll(params = {}) {
  return EngineService.get(ENDPOINT, params);
}

export function get(accountId, params = {}) {
  return EngineService.get(`${ENDPOINT}/${accountId}`, params);
}

export function update(accountId, payload) {
  return EngineService.patch(`${ENDPOINT}/${accountId}`, { account: payload }).then(
    (response) => response
  );
}

export function destroy(accountId) {
  return EngineService.delete(`${ENDPOINT}/${accountId}`);
}

export function create(payload) {
  return EngineService.post(`${ENDPOINT}`, { account: payload });
}

export function getCustomObjects(id) {
  return EngineService.get(`${ENDPOINT}/${id}/custom_objects`);
}

export function getAccountFilterProperties(params = {}) {
  return EngineService.get('/filters/accounts/columns', params).then((json) => json);
}

export function batchDeleteAccounts(accounts) {
  return EngineService.post('/accounts/batch_delete', { accounts }).then((json) => json);
}

export default {
  getAll,
  get,
  update,
  destroy,
  create,
  getAccountFilterProperties,
  batchDeleteAccounts,
};
