import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import { parseCalculationFields } from '../EngineRuleCalculation/helper';
import {
  fetchConditionDefinitions,
  fetchEngineRule,
  fetchGroups,
  fetchRuleConditions,
  handleCreateRuleCondition,
  handleUpdateRuleCondition,
  handleUpdateEngineRule,
  fetchCalculationFields,
} from './actions';

const initialState = {
  fetching: false,
  fetchingGroups: false,
  data: null,
  deleteModalVisible: false,
  deleting: false,
  criteriaFields: [],
  conditions: [],
  fetchingConditions: false,
  groups: [],
  conditionDefinitions: null,
  fetchingConditionDefenitions: false,
  creatingRuleCondition: false,
  modalVisible: false,
  formData: { name: '', note: '', params: {} },
  selectedCriteriaId: null,
  deleteCriteriaModalVisible: false,
  conditionsMeta: nullMeta,
  conditionsParams: {
    page: 1,
    per_page: 15,
  },
  configurations: {},
  calculationFields: [],
};

export const engineRuleSlice = createSlice({
  name: 'engine-rule',
  initialState,
  reducers: {
    setCriteriaFields: (state, { payload }) => {
      return { ...state, criteriaFields: payload };
    },
    setModalVisible: (state, { payload }) => {
      return {
        ...state,
        modalVisible: payload,
        formData: payload ? state.formData : initialState.formData,
        selectedCriteriaId: payload ? state.selectedCriteriaId : null,
      };
    },
    setFormData: (state, { payload }) => {
      return { ...state, formData: payload };
    },
    setSelectedCriteriaId: (state, { payload }) => {
      return { ...state, selectedCriteriaId: payload };
    },
    toggleDeleteCriteriaModal: (state, { payload }) => {
      return { ...state, deleteCriteriaModalVisible: payload };
    },
    setRuleConditions: (state, { payload }) => {
      return { ...state, conditions: payload };
    },
    setEngineRule: (state, { payload }) => {
      return { ...state, data: payload };
    },
    clearConditionDefinitionsState: (state) => {
      return { ...state, conditionDefinitions: null };
    },
  },
  extraReducers: {
    [fetchEngineRule.pending]: (state) => {
      return { ...state, fetching: true, conditions: [], criteriaFields: [] };
    },
    [fetchEngineRule.fulfilled]: (state, { payload }) => {
      return { ...state, fetching: false, data: payload.assistant_rule };
    },
    [fetchEngineRule.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [fetchRuleConditions.pending]: (state) => {
      return { ...state, fetchingConditions: true };
    },
    [fetchRuleConditions.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        fetchingConditions: false,
        conditions: payload.conditions,
        conditionsMeta: parseMetaFromResponse(payload.meta),
      };
    },
    [fetchRuleConditions.rejected]: (state) => {
      return { ...state, fetchingConditions: false };
    },
    [fetchGroups.pending]: (state) => {
      return { ...state, fetchingGroups: true };
    },
    [fetchGroups.fulfilled]: (state, { payload }) => {
      return { ...state, groups: payload, fetchingGroups: false };
    },
    [fetchGroups.rejected]: (state) => {
      return { ...state, fetchingGroups: false };
    },
    [fetchConditionDefinitions.pending]: (state) => {
      return { ...state, fetchingConditionDefenitions: true };
    },
    [fetchConditionDefinitions.fulfilled]: (state, { payload }) => {
      return { ...state, conditionDefinitions: payload, fetchingConditionDefenitions: false };
    },
    [fetchConditionDefinitions.rejected]: (state) => {
      return { ...state, fetchingConditionDefenitions: false };
    },
    [handleCreateRuleCondition.pending]: (state) => {
      return { ...state, creatingRuleCondition: true };
    },
    [handleCreateRuleCondition.fulfilled]: (state) => {
      return { ...state, creatingRuleCondition: false };
    },
    [handleCreateRuleCondition.rejected]: (state) => {
      return { ...state, creatingRuleCondition: false };
    },
    [handleUpdateRuleCondition.pending]: (state) => {
      return { ...state, creatingRuleCondition: true };
    },
    [handleUpdateRuleCondition.fulfilled]: (state) => {
      return { ...state, creatingRuleCondition: false };
    },
    [handleUpdateRuleCondition.rejected]: (state) => {
      return { ...state, creatingRuleCondition: false };
    },
    [handleUpdateEngineRule.fulfilled]: (state, { payload }) => {
      return { ...state, data: payload.assistant_rule };
    },
    [fetchCalculationFields.fulfilled]: (state, { payload }) => {
      return { ...state, calculationFields: parseCalculationFields(payload) };
    },
  },
});

export const {
  setCriteriaFields,
  setModalVisible,
  setFormData,
  setSelectedCriteriaId,
  toggleDeleteCriteriaModal,
  setRuleConditions,
  setEngineRule,
  clearConditionDefinitionsState,
} = engineRuleSlice.actions;
export default engineRuleSlice.reducer;
