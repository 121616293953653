import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import {
  REPLACE_OPPORTUNITY,
  SET_PARAMS,
  RESET_DATA,
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  FETCH_SUMS,
  STORE_SUMS,
  SELECT_PIPELINE,
  STORE_PIPELINES,
} from './constants';

const initialState = {
  requestInProgress: {},
  data: {},
  errors: [],
  sums: {},
  meta: {},
  params: {
    page: undefined,
    order: undefined,
    user_id: undefined,
    crm_stage: undefined,
    q: undefined,
    sort: 'account',
    scope: 'all',
    query: '',
  },
  selectedPipelineId: null,
  selectedPipeline: null,
  pipelines: [],
};

function requestStartedHandler(state, action) {
  return {
    ...state,
    requestInProgress: { ...state.requestInProgress, [action.key]: true },
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestInProgress: { ...state.requestInProgress, [action.key]: false },
    data: { ...state.data, [action.key]: (state.data[action.key] || []).concat(action.data) },
    meta: { ...state.meta, [action.key]: action.meta },
  };
}

function totalsForNewSalesStage(oldOpportynity, newOpportunity, state) {
  const oldStageId = oldOpportynity.opportunity_stage.id;
  const newStageId = newOpportunity.opportunity_stage.id;

  return {
    ...state.sums,
    [oldStageId]: parseFloat(state.sums[oldStageId] || 0) - parseFloat(oldOpportynity.amount),
    [newStageId]: parseFloat(state.sums[newStageId] || 0) + parseFloat(newOpportunity.amount),
  };
}

function pipelineTotalForNewAmount(oldOpportynity, newOpportunity, state) {
  const newStageId = newOpportunity.opportunity_stage.id;

  return {
    ...state.sums,
    [newStageId]:
      parseFloat(state.sums[newStageId] || 0) +
      (parseFloat(newOpportunity.amount) - parseFloat(oldOpportynity.amount)),
  };
}

function newSums(oldOpportunity, newOpportunity, state) {
  const oldStageId = oldOpportunity.opportunity_stage.id;
  const newStageId = newOpportunity.opportunity_stage.id;

  if (oldStageId !== undefined && oldStageId !== newStageId) {
    return totalsForNewSalesStage(oldOpportunity, newOpportunity, state);
  }
  if (oldOpportunity.amount !== newOpportunity.amount) {
    return pipelineTotalForNewAmount(oldOpportunity, newOpportunity, state);
  }
  return state.sums;
}

function replaceOpportunityHandler(state, action) {
  let oldOpportunity;
  const data = mapValues(state.data, (array) =>
    array.filter((o) => {
      if (o.id === action.opportunity.id) {
        oldOpportunity = o;
      }
      return o.id !== action.opportunity.id;
    })
  );
  const sums = newSums(oldOpportunity, action.opportunity, state);
  const key = action.opportunity.opportunity_stage.id;

  return {
    ...state,
    sums,
    data: {
      ...data,
      [key]: sortBy([...(data[key] || []), action.opportunity], ['accountName', 'id']),
    },
  };
}

export function setParamsHandler(state, action) {
  return {
    ...state,
    params: {
      ...state.params,
      ...action.params,
    },
  };
}

export function resetDataHandler(state) {
  return {
    ...state,
    data: {},
    meta: {},
    params: initialState.params,
  };
}

function fetchSumsHandler(state) {
  return {
    ...state,
    fetchingSums: true,
  };
}

function storeSumsHandler(state, action) {
  return {
    ...state,
    fetchingSums: false,
    sums: action.sums,
  };
}

function selectPipelineHandler(state, action) {
  return {
    ...state,
    selectedPipeline: action.selectedPipeline,
  };
}

function storePipelinesHandler(state, action) {
  return {
    ...state,
    pipelines: action.pipelines,
    selectedPipeline: action.defaultPipeline || state.selectedPipeline,
  };
}

const ACTION_HANDLERS = {
  [REQUEST_STARTED]: requestStartedHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REPLACE_OPPORTUNITY]: replaceOpportunityHandler,
  [SET_PARAMS]: setParamsHandler,
  [RESET_DATA]: resetDataHandler,
  [FETCH_SUMS]: fetchSumsHandler,
  [STORE_SUMS]: storeSumsHandler,
  [SELECT_PIPELINE]: selectPipelineHandler,
  [STORE_PIPELINES]: storePipelinesHandler,
};

export default function opportunitiesPipelineViewDataReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
