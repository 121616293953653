import { useEffect } from 'react';

export default function useAppcues(location) {
  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.start();
    }
  }, []);

  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.start();
    }
  }, [location]);
}
