import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import HeightIcon from '@mui/icons-material/Height';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import {
  selectTwilioCaller,
  selectTwilioConnection,
  selectVerticalAlignmentTop,
} from '../state/selectors';
import MuteCallButton from '../MuteCallButton';
import RejectCall from '../RejectCall';
import AcceptCall from '../AcceptCall/AcceptCall';
import InCallContactDetails from '../InCallContactDetails';
import CallDurationTimer from '../CallDurationTimer';
import { displayAnimationForActiveCall } from '../state/actions';
import DisableRecordingButton from '../DisableRecordingButton';
import styles from './ActiveCallMinimized.module.scss';

export default function ActiveCallMinimized({ onClick, handleOnClose }) {
  const connection = useSelector(selectTwilioConnection);
  const caller = useSelector(selectTwilioCaller);
  const verticalAlignmentTop = useSelector(selectVerticalAlignmentTop);
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(displayAnimationForActiveCall(true));
    onClick();
  };

  return (
    <Draggable zIndex={100} enableUserSelectHack={false}>
      <div
        className={verticalAlignmentTop ? styles['container-top'] : styles['container-animated']}
      >
        <div className={styles['user-info']}>
          <InCallContactDetails
            title={caller?.name || 'Unknown Caller'}
            subheader={<CallDurationTimer />}
            avatarImage={caller?.photo_url}
          />
        </div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          className={styles.actions}
        >
          {connection && (
            <>
              <Grid item>
                <MuteCallButton />
              </Grid>
              <Grid item className={styles.icon}>
                <DisableRecordingButton />
              </Grid>
            </>
          )}
          <Grid item>
            {connection ? <RejectCall iconSize="medium" /> : <AcceptCall iconSize="medium" />}
          </Grid>
        </Grid>
        <div className={styles['icons-container']}>
          <HeightIcon onClick={handleOnClick} />
          <CloseIcon onClick={handleOnClose} />
        </div>
      </div>
    </Draggable>
  );
}

ActiveCallMinimized.propTypes = {
  onClick: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
};
