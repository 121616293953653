export const SHOW_NOTIFICATIONS_SLIDER = 'notificationsBar/SHOW_NOTIFICATIONS_SLIDER';
export const HIDE_NOTIFICATIONS_SLIDER = 'notificationsBar/HIDE_NOTIFICATIONS_SLIDER';
export const STORE_TEXT_NOTIFICATIONS = 'notificationsBar/STORE_TEXT_NOTIFICATIONS';
export const ADD_TEXT_NOTIFICATION = 'notificationsBar/ADD_TEXT_NOTIFICATION';

export const STORE_MISSED_CALLS_NOTIFICATIONS = 'notificationsBar/STORE_MISSED_CALLS_NOTIFICATIONS';
export const SET_SHOULD_REFETCH_MISSED_CALLS = 'notificationsBar/SET_SHOULD_REFETCH_MISSED_CALLS';

export const STORE_ALERT_NOTIFICATIONS = 'notificationsBar/STORE_ALERT_NOTIFICATIONS';
export const SET_SHOULD_REFETCH_ALERTS = 'notificationsBar/SET_SHOULD_REFETCH_ALERTS';

export const SET_HAS_NEW_NOTIFICATIONS = 'notificationsBar/SET_HAS_NEW_NOTIFICATIONS';

export const ADD_UNREAD_NOTIFICATION = 'notificationsBar/ADD_UNREAD_NOTIFICATION';
