import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Button from 'lib/Button';
import styles from './NewVersionPage.module.scss';

const NewVersionPage = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Grid container justifyContent="center" className="text-center">
      <Grid item xs={12}>
        <Paper elevation={0} square className={styles.wrapper}>
          <RefreshOutlinedIcon fontSize="large" className={styles.icon} />
          <Typography variant="h6">There is a new version of Spiro.</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Please refresh your page.
          </Typography>
          <Button
            onClick={refreshPage}
            variant="contained"
            color="primary"
            className={styles.button}
          >
            Refresh page
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NewVersionPage;
