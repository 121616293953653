import { connect } from 'react-redux';
import { createMessage } from '../state/actions';
import { error as errorAlert } from '../../../state/notifications/actions';
import MessageForm from './MessageForm';

function mapStateToProps(state, ownProps) {
  return {
    contact: ownProps.contact || state.conversation.contact,
    createInProgress: state.conversation.createInProgress,
  };
}

export default connect(mapStateToProps, {
  createMessage,
  errorAlert,
})(MessageForm);
