export const REQUEST_STARTED = 'opportunities_fetch_all_request_started';
export const REQUEST_SUCCESS = 'opportunities_fetch_all_request_success';
export const REQUEST_FAILED = 'opportunities_fetch_all_request_failed';
export const REPLACE_OPPORTUNITY = 'opportunities_replace_opportunities';
export const SET_PARAMS = 'opportunities_set_params';
export const RESET_DATA = 'opportunities_reset_opportunities_data';
export const ADD_OPPORTUNITY_TO_LIST = 'opportunities_add_opportunity_to_list';
export const STORE_OPPORTUNITIES = 'opportunities_store_opportunities';
export const STORE_NEW_DEALS = 'opportunities_store_new_deals';
export const CREATE_IN_PROGRESS = 'opportunities_create_in_progress';
export const CREATE_SUCCESS = 'opportunities_create_success';
export const CREATE_FAILURE = 'opportunities_create_failure';
