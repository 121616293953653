import PropTypes from 'prop-types';
import Day from 'dayjs';
import ChatItem from './ChatItem';

export default function GroupList({ messages, ...props }) {
  return (
    <ul className="group-list">
      {messages.map((message) => (
        <ChatItem
          key={message.id}
          contactName={message.owner.name}
          userName={message.user.fullName || message.user.full_name}
          text={message.description}
          mediaUrls={message.mediaUrl || message.media_url || []}
          inbound={message.smsType === 'inbound' || message.kind === 'inbound_text'}
          time={Day(message.interactedAt || message.interacted_at).format('hh:mm A')}
          {...props}
        />
      ))}
    </ul>
  );
}

GroupList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.array).isRequired,
};
