import {
  COUNTERS_REQUEST_STARTED,
  COUNTERS_REQUEST_SUCCESS,
  MESSAGES_INCREMENT_COUNTER,
  CALLS_INCREMENT_COUNTER,
} from './constants';

const initialState = {
  requestStarted: false,
  missedCalls: 0,
  unreadSMS: 0,
};

function requestStartedHandler(state) {
  return {
    ...state,
    requestStarted: true,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    missedCalls: action.missedCalls,
    unreadSMS: action.unreadSMS,
  };
}

function messagesCounterHandler(state, action) {
  return {
    ...state,
    unreadSMS: action.counter.length,
  };
}

function callsCounterHandler(state, action) {
  return {
    ...state,
    missedCalls: action.counter.length,
  };
}

const ACTION_HANDLERS = {
  [COUNTERS_REQUEST_STARTED]: requestStartedHandler,
  [COUNTERS_REQUEST_SUCCESS]: requestSuccessHandler,
  [MESSAGES_INCREMENT_COUNTER]: messagesCounterHandler,
  [CALLS_INCREMENT_COUNTER]: callsCounterHandler,
};

export default function countersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
