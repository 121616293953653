import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/state/user/selectors';
import { ReactComponent as CustomLogo } from 'images/ceramic-pro.svg';
import { ReactComponent as SpiroLogo } from './icons/spiro-logo-white.svg';
import TopBarActions from './TopBarActions';
import styles from './TopBar.module.scss';

export default function TopBar() {
  const user = useSelector(selectUser);

  if (!user.id) return null;

  return (
    <header
      className={`${styles['top-bar']} ${
        user.logoType !== 'default' ? styles['black-background'] : ''
      }`}
    >
      <Link to="/">
        {user.logoType === 'default' ? (
          <SpiroLogo data-cy="navbar-spiro-logo" />
        ) : (
          <CustomLogo className={styles['custom-logo']} data-cy="navbar-spiro-logo" />
        )}
      </Link>
      <TopBarActions />
    </header>
  );
}
