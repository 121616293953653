import { parseUrl, parametrizeUrl } from '../../helpers/URLHelper';

export function parseTokenFromEngine(data) {
  return {
    accessToken: data.access_token,
    instanceUrl: data.instance_url,
    createdAt: data.created_at,
    expiresIn: data.expires_in,
    refreshToken: data.refresh_token,
    tokenType: data.token_type,
    userId: data.user_id,
    userEmail: data.user_email,
  };
}

export function getRedirectUrl() {
  if (window) {
    const currentLocation = parseUrl(window.location.href);
    return parametrizeUrl('/', currentLocation.query);
  }
  return '';
}

export default {
  parseTokenFromEngine,
};
