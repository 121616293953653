import { nullMeta } from 'helpers/meta';
import { FETCH_INTERACTIONS_STARTED, STORE_INTERACTIONS, SET_PARAMS } from './constants';

const initialState = {
  isLoading: false,
  data: [],
  meta: nullMeta,
  params: {
    page: 1,
    per_page: 25,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    q: undefined,
  },
};

const ACTION_HANDLERS = {
  [FETCH_INTERACTIONS_STARTED]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [STORE_INTERACTIONS]: (state, action) => ({
    ...state,
    isLoading: false,
    data: action.data,
    meta: {
      ...state.meta,
      ...action.meta,
    },
  }),
  [SET_PARAMS]: (state, action) => ({
    ...state,
    params: {
      ...state.params,
      ...action.data,
    },
  }),
};

export default function interactionsDataReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
