import EngineService from '../core/engine-service';

const ENDPOINT = '/alerts';

// GET: /alerts
function getAlerts(params) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

// POST: /alerts/:id
function updateAlert(alertId, payload) {
  return EngineService.patch(`${ENDPOINT}/${alertId}`, { alert: payload }).then((json) => json);
}

// POST: /alerts/batch_update
function batchUpdateAlerts(payload) {
  return EngineService.patch(`${ENDPOINT}/batch_update`, { alert: payload }).then((json) => json);
}

export default {
  getAlerts,
  updateAlert,
  batchUpdateAlerts,
};
