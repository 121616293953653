import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '2px 6px 21px -2px rgba(0,0,0,0.75)',
    borderRadius: 4,
  },
  popper: {
    zIndex: 100,
  },
}));

export { useStyles };
