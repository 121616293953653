import {
  CONVERSATION_PARENT_REQUEST_STARTED,
  CONVERSATION_PARENT_REQUEST_SUCCESS,
  CONVERSATION_REQUEST_STARTED,
  CONVERSATION_REQUEST_SUCCESS,
  CONVERSATION_TOGGLE_MODAL,
  CONVERSATION_CLEAR,
  CONVERSATION_SET_CONTACT,
  CONVERSATION_MESSAGE_CREATE_STARTED,
  CONVERSATION_MESSAGE_CREATE_SUCCESS,
  CONVERSATION_STORE_UNREAD_MESSAGE,
  CONVERSATION_CLEAR_UNREAD_MESSAGES,
} from './constants';
import { nullMeta } from '../../../helpers/meta';

const initialState = {
  parentInteraction: null,
  parentLoading: false,
  visible: false,
  contact: null,
  data: [],
  meta: nullMeta,
  isLoading: false,
  childInteractions: [],
  createInProgress: false,
  unreadMessages: [],
};

function conversationToogleModalHandler(state, action) {
  return {
    ...state,
    visible: !state.visible,
    contact: action.config.contact || null,
  };
}

function conversationSetContactHandler(state, action) {
  return {
    ...state,
    visible: true,
    contact: action.contact,
    data: [],
  };
}

function conversationParentRequestStartedHandler(state) {
  return {
    ...state,
    parentLoading: true,
  };
}

function conversationParentRequestSuccessHandler(state, action) {
  return {
    ...state,
    parentLoading: false,
    parentInteraction: action.interaction,
  };
}

function conversationRequestStartedHandler(state) {
  return {
    ...state,
    isLoading: true,
  };
}

function conversationRequestSuccessHandler(state, action) {
  const newData = action.data;

  return {
    ...state,
    isLoading: false,
    data: [...newData.reverse(), ...state.data],
    meta: action.meta,
  };
}

function messageCreateStartedHandler(state) {
  return {
    ...state,
    createInProgress: true,
  };
}

function messageCreateSuccessHandler(state, action) {
  return {
    ...state,
    createInProgress: false,
    data: [...state.data, action.message],
  };
}

function conversationClearHandler(state) {
  return { ...state, ...initialState };
}

function storeUnreadMessageHandler(state, action) {
  return {
    ...state,
    data: [...state.data, action.message],
    unreadMessages: [...state.unreadMessages, action.message],
  };
}

function clearUnreadMessagesHandler(state) {
  return {
    ...state,
    unreadMessages: [],
  };
}

const ACTION_HANDLERS = {
  [CONVERSATION_PARENT_REQUEST_STARTED]: conversationParentRequestStartedHandler,
  [CONVERSATION_PARENT_REQUEST_SUCCESS]: conversationParentRequestSuccessHandler,
  [CONVERSATION_TOGGLE_MODAL]: conversationToogleModalHandler,
  [CONVERSATION_REQUEST_STARTED]: conversationRequestStartedHandler,
  [CONVERSATION_REQUEST_SUCCESS]: conversationRequestSuccessHandler,
  [CONVERSATION_MESSAGE_CREATE_STARTED]: messageCreateStartedHandler,
  [CONVERSATION_MESSAGE_CREATE_SUCCESS]: messageCreateSuccessHandler,
  [CONVERSATION_CLEAR]: conversationClearHandler,
  [CONVERSATION_SET_CONTACT]: conversationSetContactHandler,
  [CONVERSATION_STORE_UNREAD_MESSAGE]: storeUnreadMessageHandler,
  [CONVERSATION_CLEAR_UNREAD_MESSAGES]: clearUnreadMessagesHandler,
};

export default function conversationReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
