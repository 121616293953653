import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import ImportAllowedOnly from 'components/ImportAllowedOnly';
import UnauthorizedAccess from '../UnauthorizedAccess';

function ImportAllowedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <ImportAllowedOnly fallback={<UnauthorizedAccess />}>
          <Component {...props} />
        </ImportAllowedOnly>
      )}
    />
  );
}

ImportAllowedRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default ImportAllowedRoute;
