import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import opportunitiesActions, {
  createOpportunity as handleCreateOpportunity,
} from 'state/nested-opportunities/actions';
import {
  selectOpportunities,
  selectMeta,
  selectParams,
  selectIsLoading,
  selectIsRefreshRequired,
} from 'state/nested-opportunities/selectors';
import { nullFilter } from 'helpers/filters';
import { selectCurrentFilter } from 'state/filters/nested-filters/selectors';
import { getPipelines } from 'state/pipelines/actions';

export default function useOpportunities(defaultParams = { per_page: 10 }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const opportunities = useSelector(selectOpportunities);
  const meta = useSelector(selectMeta);
  const params = useSelector(selectParams);
  const isRefreshRequired = useSelector(selectIsRefreshRequired);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchOpportunities = (requestParams = {}) => {
    return dispatch(opportunitiesActions.getOpportunities({ ...defaultParams, ...requestParams }));
  };

  const createOpportunity = (payload) => {
    return dispatch(handleCreateOpportunity(payload));
  };

  const updateOpportunity = (id, payload) => {
    return dispatch(opportunitiesActions.updateOpportunity(id, payload));
  };

  const getPage = useCallback((page) => {
    fetchOpportunities({ page });
  }, []);

  const sortOpportunities = useCallback((sortBy, sortOrder) => {
    fetchOpportunities({ sort: sortBy, order: sortOrder, page: 1 });
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleSearchEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        fetchOpportunities({ query: searchQuery, page: 1 });
      }
    },
    [searchQuery]
  );

  const handleSearchClear = useCallback(() => {
    setSearchQuery('');
    fetchOpportunities({ query: '', page: 1 });
  }, []);

  const clearAppliedFilters = useCallback(() => {
    fetchOpportunities({ q: undefined, page: undefined });
    dispatch(selectCurrentFilter(nullFilter));
  }, []);

  useEffect(() => {
    dispatch(getPipelines());
  }, []);

  useEffect(() => {
    fetchOpportunities();
  }, []);

  useEffect(() => {
    if (isRefreshRequired) {
      fetchOpportunities();
    }
  }, [isRefreshRequired]);

  useEffect(
    () => () => {
      dispatch(opportunitiesActions.reset());
    },
    []
  );

  return {
    isLoading,
    opportunities,
    sortOpportunities,
    meta,
    params,
    getPage,
    updateOpportunity,
    handleSearchChange,
    handleSearchEnterPress,
    handleSearchClear,
    searchQuery,
    fetchOpportunities,
    clearAppliedFilters,
    createOpportunity,
  };
}
