import { useDispatch, useSelector } from 'react-redux';
import { hideModal, showModal } from 'modals/state/actions';
import { createReminder } from 'state/nested-reminders/actions';
import { toggleModal as openConversation } from 'components/Conversation/state/actions';
import { getPhoneTypeFromContact } from 'helpers/contacts';
import {
  displayAnimationForActiveCall,
  minimizeCallModal,
  rejectIncomingCall,
  setVerticalAlignmentTop,
} from 'components/CallDialog/state/actions';
import {
  selectTwilioAccount,
  selectTwilioCaller,
  selectTwilioConnection,
  selectTwilioContact,
} from 'components/CallDialog/state/selectors';
import { success as successAlert } from 'state/notifications/actions';

export default function useCallActions() {
  const dispatch = useDispatch();
  const contact = useSelector(selectTwilioContact);
  const account = useSelector(selectTwilioAccount);
  const caller = useSelector(selectTwilioCaller);
  const connection = useSelector(selectTwilioConnection);

  const minimizeWindowToTheTop = () => {
    dispatch(minimizeCallModal(true));
    dispatch(setVerticalAlignmentTop(true));
    dispatch(displayAnimationForActiveCall(false));
  };

  const expandModal = () => {
    dispatch(minimizeCallModal(false));
    dispatch(setVerticalAlignmentTop(false));
  };

  const handleHideModal = () => {
    dispatch(hideModal());
    expandModal();
  };

  const handleReminderSubmit = (params) => {
    dispatch(createReminder(params));
    dispatch(successAlert('Reminder saved!'));
    return Promise.resolve();
  };

  const rejectIfCallIsPending = () => {
    if (connection?.status() === 'pending') {
      connection.reject(() => dispatch(rejectIncomingCall()));
      connection.reject();
    }
  };

  const setReminder = () => {
    minimizeWindowToTheTop();
    rejectIfCallIsPending();
    dispatch(
      showModal('ADD_REMINDER_MODAL', {
        show: true,
        parent: caller.resource_type === 'Contact' ? contact : account,
        ownerType: caller.resource_type === 'Contact' ? 'Contact' : 'Account',
        ownerId: caller.resource_id,
        onSubmit: handleReminderSubmit,
        onHide: handleHideModal,
      })
    );
  };
  const sendEmail = () => {
    rejectIfCallIsPending();
    minimizeWindowToTheTop();
    dispatch(showModal('SEND_EMAIL_MODAL', { show: true, contact }));
  };

  const contactHasPhoneNumber = !!contact?.mobilePhone || !!contact?.phone || !!contact?.home_phone;

  const sendText = () => {
    rejectIfCallIsPending();
    minimizeWindowToTheTop();
    dispatch(openConversation({ contact, phoneType: getPhoneTypeFromContact(contact) }));
  };

  return { setReminder, sendEmail, sendText, contactHasPhoneNumber, contact, caller };
}
