import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Day from 'dayjs';
import Pusher from 'pusher-js';
import { useSelector, useDispatch } from 'react-redux';
import { selectPushNotifications } from 'app/state/push-notifications/selectors';
import { addNewPushNotification } from 'app/state/push-notifications/reducer';
import PushNotification from '../PushNotification';

function bindToPusherChannel(channel, event, callback) {
  channel.bind(event, (data) => {
    callback(data);
  });
}

function PushNotifications({
  userId,
  setTwilioNumberVerificationStatus,
  storeAvailability,
  setShouldRefetchMissedCalls,
  addUnreadNotification,
  incrementCallsCounter,
  parentInteraction,
  setShouldRefetchTexts,
  incrementMessagesCounter,
  storeUnreadMessage,
  addAlert,
  alertsDisabled,
}) {
  const notifications = useSelector(selectPushNotifications);
  const pusher = new Pusher(process.env.PUSHER_APP_KEY);
  const channel = pusher.subscribe(`spiro_channel_${userId}`);
  const dispatch = useDispatch();

  const setNotifications = (arr) => dispatch(addNewPushNotification(arr));

  const subscribeToPusher = () => {
    bindToPusherChannel(channel, 'inbound_sms', (message) => {
      if (!alertsDisabled) {
        setNotifications({
          uid: new Day().format(),
          type: 'inbound_text',
          text: message.owner.name,
          activityID: message.parent_id || message.id,
        });
      }

      setShouldRefetchTexts();
      addUnreadNotification(message);
      incrementMessagesCounter([message]);

      if (!parentInteraction) return;
      if (parentInteraction.id === message.parent_id) {
        storeUnreadMessage(message);
      }
    });

    bindToPusherChannel(channel, 'missed_call', (call) => {
      if (!alertsDisabled) {
        setNotifications({
          uid: new Day().format(),
          type: 'missed_call',
          text: call.owner.name,
          activityID: call.id,
        });
      }

      setShouldRefetchMissedCalls();
      addUnreadNotification(call);
      incrementCallsCounter([call]);
    });

    bindToPusherChannel(channel, 'new_alert', (data) => {
      addAlert(data);
      addUnreadNotification(data);
    });

    bindToPusherChannel(channel, 'twilio_number_verification', (data) => {
      setTwilioNumberVerificationStatus(data);
    });

    bindToPusherChannel(channel, 'twilio_availability', (data) => {
      storeAvailability(data.status.available);
    });

    bindToPusherChannel(channel, 'external_notification', (data) => {
      if (!alertsDisabled) {
        setNotifications({
          uid: new Day().format(),
          type: 'external_notification',
          text: data?.subject,
          subject: data.message || '',
          link: data.link,
        });
      }
    });
  };

  useEffect(() => {
    subscribeToPusher();

    return () => {
      pusher.unsubscribe(`spiro_channel_${userId}`);
      pusher.disconnect();
    };
  });

  return <PushNotification notifications={notifications} />;
}

PushNotifications.defaultProps = {
  parentInteraction: null,
};

PushNotifications.propTypes = {
  setTwilioNumberVerificationStatus: PropTypes.func.isRequired,
  storeAvailability: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  storeUnreadMessage: PropTypes.func.isRequired,
  setShouldRefetchTexts: PropTypes.func.isRequired,
  setShouldRefetchMissedCalls: PropTypes.func.isRequired,
  addUnreadNotification: PropTypes.func.isRequired,
  incrementMessagesCounter: PropTypes.func.isRequired,
  incrementCallsCounter: PropTypes.func.isRequired,
  parentInteraction: PropTypes.object,
  alertsDisabled: PropTypes.bool.isRequired,
};

export default PushNotifications;
