import get from 'lodash/get';
import pick from 'lodash/pick';
import { licensesTypes } from './users';

export const USER_STATUS_CREATED = 'created';
export const USER_STATUS_INVITED = 'invited';
export const USER_STATUS_ACCEPTED_INVITATION = 'accepted_invitation';
export const USER_STATUS_PROFILE = 'profile';
export const USER_STATUS_ACTIVE = 'active';

export function parseUserFromEngine(data = {}) {
  return {
    id: data.id || null,
    firstName: data.first_name || '',
    lastName: data.last_name || '',
    fullName: data.full_name || '',
    title: data.title || '',
    username: data.username || '',
    nickname: data.nickname || '',
    gender: data.gender || '',
    photoUrl: data.photo_url || '',
    phone: data.phone || '',
    mobilePhone: data.mobile_phone || '',
    email: data.email || '',
    region: data.region || '',
    countryCode: data.country_code || 'US',
    emailClient: get(data, 'settings.email_client') || '',
    canReadAllEmails: data.settings && data.settings.can_read_all_emails === '1',
    timezone: data.timezone || '',
    startDayAt: data.start_day_at || '',
    endDayAt: data.end_day_at || '',
    currency: data.currency || '',
    currencySymbol: data.currency_symbol || '',
    profile: data.profile || '',
    crmType: data.crm_type || '',
    crmUserId: data.crm_user_id || '',
    crmOrganizationId: data.crm_organization_id || '',
    salesforceInstanceUrl: data.salesforce_instance_url || '',
    connectedUsers: data.connected_users || [],
    enableLeaderboard: data.enable_leaderboard || false,
    enableNotifications: data.enable_notifications || 0,
    emailToSalesforce: data.email_to_salesforce || '',
    presidentsClubEnabled: data.enable_pclub || false,
    primaryEmail: data.primary_email || null,
    emailSignatureHTML: data.email_signature_html || '',
    spiroScore: data.spiro_score || 0,
    isAdmin: data.is_admin || false,
    isManager: data.is_manager || false,
    isSupermanager: data.is_supermanager || false,
    passwordPresent: data.password_present || false,
    snacks: data.snacks || '',
    loggedIn: data.logged_in || false,
    profileComplete: data.profile_completed || false,
    enableParentAccount: data.enable_parent_account || false,
    status: data.status || '',
    reportsLink: data.reports_link || '',
    zohoZuid: data.settings ? data.settings.zoho_zuid || '' : '',
    zohoTicket: data.settings ? data.settings.zoho_ticket || '' : '',
    monthlyQuota: data.monthly_quota || 0,
    quarterlyQuota: data.quarterly_quota || 0,
    yearlyQuota: data.yearly_quota || 0,
    role: data.role || '',
    forwardTo: data.forward_to || null,
    recording: data.recording || false,
    appcuesDisabled: data.appcues_disabled,
    twilioOrganization: data.twilio_organization,
    custom: data.custom,
    navigationDisplayFields: data.navigation_display_fields || {},
    parentId: data.parent_id,
    zohoEmail: data.zoho_email,
    twilioProfile: data.twilio_profile || null,
    trayIOUser: data.tray_user || null,
    integrationPageEnabled: data.integration_page_enabled || false,
    canDoImport: data.settings && data.settings.can_do_import === '1',
    groupId: data.group_id,
    calendar: data.calendar,
    organization: data.organization,
    addons: data.addons || [],
    licenseType: licensesTypes[data.license_type],
    emailVisibility: data.email_visibility || '',
    logoType: data.logo_type,
    webAlertsDisabled: data.web_alerts_disabled || false,
    voipPopupDisabled: data.voip_popup_disabled || false,
  };
}

export function serialize(user, keys) {
  const s = {
    id: user.id,
    first_name: user.firstName,
    last_name: user.lastName,
    title: user.title,
    phone: user.phone,
    username: user.username,
    password: user.password,
    password_confirmation: user.passwordConfirmation,
    profile: user.profile,
    activity: user.activity,
    enable_leaderboard: user.enableLeaderboard,
    enable_pclub: user.presidentsClubEnabled,
    enable_notifications: user.enableNotifications,
    email_to_salesforce: user.emailToSalesforce,
    snacks: user.snacks,
    currency: user.currency,
    email_client: user.emailClient,
    country_code: user.countryCode,
    custom: user.custom,
    email_signature_html: user.emailSignatureHTML,
    role: user.role,
    parent_id: user.parentId,
    is_admin: user.isAdmin,
    group_id: user.groupId,
    email_visibility: user.emailVisibility,
    web_alerts_disabled: user.webAlertsDisabled,
    voip_popup_disabled: user.voipPopupDisabled,
    external_id: user.externalID,
  };

  if (keys) {
    return pick(s, keys);
  }
  return s;
}

export function shouldDoOnboarding(status) {
  return status !== USER_STATUS_ACTIVE;
}

export default {
  parseUserFromEngine,
  serializeUserForEngine: serialize,
};
