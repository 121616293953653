import MuiTabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    color: 'rgba(153, 153, 153, 1)',
    '& button': {
      padding: '0 24px',
    },
  },
  indicator: {
    backgroundColor: '#5D43CF',
  },
});

export default function Tabs(props) {
  const classes = useStyles();
  return <MuiTabs classes={classes} {...props} />;
}
