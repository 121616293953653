import { withStyles } from '@mui/styles';
import MuiButton from '@mui/material/Button';

const colors = {
  grey: '#999999',
  lightGrey: 'rgba(128, 128, 128, 0.24)',
  white: '#FFFFFF',
  purple: '#5D43CF',
  lightPurple: 'rgba(174, 161, 231, 0.24)',
  lightPurple2: '#BEB4EC',
};

const Button = withStyles({
  text: {
    color: colors.purple,
    '&:disabled': {
      color: colors.grey,
    },
    '&:hover': {
      backgroundColor: colors.lightPurple,
    },
  },
  textSecondary: {
    color: colors.grey,
    '&:disabled': {
      color: colors.lightGrey,
    },
    '&:hover': {
      backgroundColor: colors.lightPurple,
    },
  },
  outlined: {
    border: `1px solid ${colors.purple}`,
    color: colors.purple,
    backgroundColor: colors.white,
    '&:disabled': {
      color: colors.grey,
      border: `1px solid ${colors.grey}`,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      color: colors.purple,
      border: `1px solid ${colors.purple}`,
      backgroundColor: colors.lightPurple,
    },
  },
  contained: {
    padding: '5px 15px',
    backgroundColor: colors.purple,
    border: `1px solid ${colors.purple}`,
    boxShadow: 'none',
    '&:disabled': {
      color: colors.lightGrey,
      border: `1px solid ${colors.lightPurple2}`,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:hover': {
      padding: '5px 15px',
      color: colors.purple,
      border: `1px solid ${colors.purple}`,
      backgroundColor: colors.lightPurple,
      boxShadow: 'none',
    },
  },
})(MuiButton);

export default Button;
