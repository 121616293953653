import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import styles from './ModalFooter.module.scss';

function ModalFooter(props) {
  return (
    <DialogActions {...props} className={styles.footer}>
      {props.children}
    </DialogActions>
  );
}

ModalFooter.defaultProps = {
  className: '',
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ModalFooter;
