import { nullMeta } from 'helpers/meta';
import ACTIONS from './actions';

const initialState = {
  visible: false,
  searchTerm: '',
  loading: false,
  loaded: false,
  results: [],
  meta: nullMeta,
  params: {
    per_page: 50,
    page: 1,
    include: 'account,interaction',
  },
};

const setSearchTerm = (state, action) => ({
  ...state,
  searchTerm: action.searchTerm,
});

const setVisible = (state, action) => ({
  ...state,
  visible: action.visible,
});

const setLoading = (state, action) => ({
  ...state,
  loading: action.loading,
});

const setResults = (state, action) => ({
  ...state,
  results:
    state.params.only && state.params.page > 1
      ? [...state.results, ...action.results]
      : action.results,
});

const setMeta = (state, action) => ({
  ...state,
  meta: action.meta,
});

const setParams = (state, action) => ({
  ...state,
  params: action.params ? { ...state.params, ...action.params } : null,
  results: action.params.only && action.params.page === 1 ? [] : state.results,
});

const setCleanState = (state) => ({
  ...initialState,
  visible: state.visible,
});

const setInitialState = () => initialState;

const reducer = (state, action) => {
  return (
    {
      [ACTIONS.SET_SEARCH_TERM]: setSearchTerm,
      [ACTIONS.SET_VISIBLE]: setVisible,
      [ACTIONS.SET_LOADING]: setLoading,
      [ACTIONS.SET_RESULTS]: setResults,
      [ACTIONS.SET_META]: setMeta,
      [ACTIONS.SET_PARAMS]: setParams,
      [ACTIONS.SET_CLEAN_STATE]: setCleanState,
      [ACTIONS.SET_INITIAL_STATE]: setInitialState,
    }[action.type](state, action) || state
  );
};

export { initialState };
export default reducer;
