import map from 'lodash/map';

export function getFieldsValues(fields) {
  if (fields.length) return Object.values(fields[0]).flat();
  return [];
}

export function checkIfValuesAreEmpty(formData) {
  return !Object.values(formData).every((x) => x !== '');
}

const filterKey = (text) => {
  return (
    {
      account: 'Company',
      contact: 'Contact',
      opportunity: 'Opportunity',
      interaction: 'Activity',
    }[text] || text
  );
};

export function parseFieldProperties(data = []) {
  return map(data, (p, key) => {
    return {
      value: key,
      label: p.label,
      functions: p.functions,
      type: p.type,
    };
  });
}

export function parseCalculationFields(properties) {
  return Object.entries(properties).map(([key, value]) => {
    return {
      [filterKey(key)]: parseFieldProperties(value),
    };
  });
}

const flattenCalculationFields = (fields) => {
  return fields
    .map((option) => {
      const values = Object.values(option).flat();

      return values.map((val) => ({
        name: val.value,
        options: val.functions,
        type: val.type,
      }));
    })
    .flat(1);
};

export const getFunctionOptions = (fields, fieldValue) => {
  if (!fieldValue.length) return [];
  return flattenCalculationFields(fields).find((i) => i.name === fieldValue)?.options;
};

export const getFieldType = (fields, fieldValue) => {
  if (!fieldValue.length) return [];
  return flattenCalculationFields(fields).find((i) => i.name === fieldValue)?.type;
};
