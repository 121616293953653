import { useDispatch, useSelector } from 'react-redux';
import BlockIcon from '@mui/icons-material/Block';
import IconButtonWithLabel from 'lib/IconButtonWithLabel';
import { selectCallModalMinimized, selectIsRecordingEnabled } from '../state/selectors';
import { setRecording } from '../state/actions';
import styles from './DisableRecordingButton.module.scss';

export default function DisableRecordingButton() {
  const isMinimized = useSelector(selectCallModalMinimized);
  const dispatch = useDispatch();
  const isRecordingEnabled = useSelector(selectIsRecordingEnabled);

  const disableCallRecording = () => {
    if (isRecordingEnabled) dispatch(setRecording(false));
  };

  const toggleColor = () => {
    return isMinimized ? 'purple-minimized' : 'purple';
  };

  const getLabel = () => {
    if (isMinimized) return '';
    return isRecordingEnabled ? 'Disable Recording' : 'Recording Disabled';
  };

  return (
    <div className={styles.container}>
      <IconButtonWithLabel
        icon={<BlockIcon />}
        color={toggleColor()}
        handleOnClick={disableCallRecording}
        label={getLabel()}
        iconSize={isMinimized ? 'medium' : 'large'}
        disabled={!isRecordingEnabled}
      />
    </div>
  );
}
