import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Popper, ClickAwayListener } from '@mui/material';
import { selectUserFullName } from 'state/user/selectors';
import TwilioOnly from 'components/TwilioOnly';
import { selectTwilioEnabled } from 'components/CallDialog/state/selectors';
import CallStatus from './CallStatus';
import UserInfo from './UserInfo';
import UserDropdown from './UserDropdown';
import { useStyles } from './useStyles';
import styles from './UserWidget.module.scss';

export default function UserWidget() {
  const userName = useSelector(selectUserFullName);
  const twilioEnabled = useSelector(selectTwilioEnabled);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const hideDropdown = () => setAnchorEl(null);
  const classes = useStyles();

  return (
    <div className={styles['user-widget']}>
      <TwilioOnly>
        <CallStatus callsEnabled={twilioEnabled} />
      </TwilioOnly>
      <ClickAwayListener onClickAway={hideDropdown}>
        <div>
          <UserInfo
            name={userName}
            handleClick={handleClick}
            open={open}
            twilioEnabled={twilioEnabled}
          />
          <div>
            <Popper
              id="popup-popper"
              open={open}
              anchorEl={anchorEl}
              placement="bottom-end"
              className={classes.popper}
            >
              <div className={classes.paper} data-testid="user-popup">
                <UserDropdown onHide={hideDropdown} twilioEnabled={twilioEnabled} />
              </div>
            </Popper>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
}
