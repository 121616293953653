import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  SORT_OPPORTUNITIES,
  REPLACE_OPPORTUNITY,
  SET_PARAMS,
  RESET,
  ADD_OPPORTUNITY_TO_LIST,
  STORE_OPPORTUNITIES,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  CREATE_FAILURE,
} from './constants';
import { nullMeta } from '../../helpers/meta';

const initialState = {
  requestStarted: false,
  data: [],
  errors: [],
  requestFailed: false,
  requestSuccess: false,
  createInProgress: false,
  meta: nullMeta,
  params: {
    page: 1,
    per_page: 10,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    q: undefined,
  },
  isRefreshRequired: false,
};

function requestStartedHandler(state) {
  return {
    ...state,
    requestStarted: true,
    requestFailed: false,
    requestSuccess: false,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: false,
    requestSuccess: true,
    data: action.data,
    meta: action.meta,
  };
}

function requestFailedHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: true,
    requestSuccess: false,
    errors: action.errors,
  };
}

function sortOpportunitiesHandler(state, action) {
  return {
    ...state,
    sortColumn: action.sortColumn,
    sortOrder: action.sortOrder,
  };
}

function replaceOpportunityHandler(state, action) {
  return {
    ...state,
    data: state.data.map((o) => (o.id === action.opportunity.id ? action.opportunity : o)),
  };
}

function addOpportunityToListHandler(state, action) {
  return { ...state, data: [action.opportunity, ...state.data] };
}

function storeOpportunitiesHandler(state, action) {
  return { ...state, data: action.opportunities };
}

export function setParamsHandler(state, action) {
  return { ...state, params: { ...state.params, ...action.data } };
}

export function resetHandler() {
  return initialState;
}

export function createInProgressHandler(state) {
  return { ...state, createInProgress: true };
}

export function createSuccessHandler(state) {
  return { ...state, createInProgress: false };
}

export function createFailureHandler(state) {
  return { ...state, createInProgress: false };
}

export function setIsRefreshRequired(state, action) {
  return {
    ...state,
    isRefreshRequired: action.isRefreshRequired,
  };
}

const ACTION_HANDLERS = {
  [REQUEST_STARTED]: requestStartedHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REQUEST_FAILED]: requestFailedHandler,
  [SORT_OPPORTUNITIES]: sortOpportunitiesHandler,
  [REPLACE_OPPORTUNITY]: replaceOpportunityHandler,
  [SET_PARAMS]: setParamsHandler,
  [RESET]: resetHandler,
  [CREATE_IN_PROGRESS]: createInProgressHandler,
  [CREATE_SUCCESS]: createSuccessHandler,
  [CREATE_FAILURE]: createFailureHandler,
  [ADD_OPPORTUNITY_TO_LIST]: addOpportunityToListHandler,
  [STORE_OPPORTUNITIES]: storeOpportunitiesHandler,
};

export default function opportunitiesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
