import { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '../TextField/TextField';

const useStyles = makeStyles({
  deleteIcon: {
    position: 'absolute',
    right: '10px',
    top: '28px',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  container: {
    position: 'relative',
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#5D43CE',
    },
  },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
  },
});

export default function DatePicker({ defaultOpen, ...props }) {
  const classes = useStyles();
  const [open, setOpen] = useState(defaultOpen);

  return (
    <span className={`${classes.container} ${props?.className}`}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              inputFormat="MMM dd, yyyy"
              disableMaskedInput
              renderInput={(params) => (
                <TextField
                  {...params}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setOpen(!open);
                  }}
                  onBlur={() => setOpen(false)}
                />
              )}
              disabled={props.disabled}
              onChange={props.onChange}
              label={props.label}
              onClose={() => {
                setOpen(false);
                props.onClose();
              }}
              open={props.open || open}
              value={props.value}
              closeOnSelect={false}
            />
          </Stack>
        </LocalizationProvider>
        {props.isClearable && props.value && (
          <DeleteIcon
            fontSize="small"
            className={classes.deleteIcon}
            onClick={(e) => {
              e.stopPropagation();
              props.onClear();
            }}
          />
        )}
      </ThemeProvider>
    </span>
  );
}

DatePicker.defaultProps = {
  variant: 'inline',
  autoFocus: false,
  keyboard: false,
  isClearable: false,
  onClear: () => {},
  value: null,
  onClose: () => {},
  open: false,
  className: '',
  defaultOpen: false,
};

DatePicker.propTypes = {
  variant: PropTypes.string,
  autoFocus: PropTypes.bool,
  keyboard: PropTypes.bool,
  isClearable: PropTypes.bool,
  onClear: PropTypes.func,
  value: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
};
