import { selectTwilioConnection } from 'components/CallDialog/state/selectors';
import { useSelector } from 'react-redux';

export default function useCallerInfo() {
  const connection = useSelector(selectTwilioConnection);

  const isConnectionPending = () => {
    return connection?.status() === 'pending';
  };

  const isConnectionOpen = () => {
    return connection?.status() === 'open';
  };

  return { isConnectionPending, isConnectionOpen };
}
