import PropTypes from 'prop-types';
import cs from 'classnames';
import MuiPhoneNumber from 'material-ui-phone-number';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiPhoneNumber-flagButton': {
      padding: '6px',
      borderRadius: '4px',
    },
  },
});

export default function Phone({ className, variant, fullWidth, defaultCountry, ...props }) {
  const classes = useStyles();

  return (
    <MuiPhoneNumber
      {...props}
      fullWidth={fullWidth}
      defaultCountry={defaultCountry}
      variant={variant}
      autoComplete="off"
      disableAreaCodes
      className={cs(classes.root, className)}
      localization={{ Swaziland: 'Eswatini' }}
      sx={{ '& .MuiPhoneNumber-flagButton': { width: 'min-content' } }}
    />
  );
}

Phone.defaultProps = {
  fullWidth: true,
  variant: 'filled',
  className: '',
  defaultCountry: 'us',
};

Phone.propTypes = {
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  defaultCountry: PropTypes.string,
};
