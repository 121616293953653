import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  navDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: {
    backgroundColor: 'white',
    marginRight: '16px',
  },
  listItems: {
    cursor: 'pointer',
    padding: 0,
    '&:not(:last-child)': {
      marginRight: '16px',
    },
    '&:last-child': {
      marginLeft: '-4px',
    },
  },
});

export { useStyles };
