import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInteractions,
  setIsRefreshRequired,
  clearInteractions,
} from 'state/nested-interactions/actions';
import {
  selectInteractions,
  selectMeta,
  selectIsLoading,
  selectIsRefreshRequired,
} from 'state/nested-interactions/selectors';
import { nullFilter } from 'helpers/filters';
import { setCurrentFilters } from 'state/filters/interactions/actions';
import useListSearch from 'hooks/useListSearch';

export default function useInteractions(defaultParams = { per_page: 10 }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const interactions = useSelector(selectInteractions);
  const meta = useSelector(selectMeta);
  const isRefreshRequired = useSelector(selectIsRefreshRequired);

  const fetchInteractions = (params = {}) => {
    return dispatch(getInteractions({ ...defaultParams, ...params }));
  };

  const getPage = useCallback((page) => {
    fetchInteractions({ page });
  }, []);

  const clearAppliedFilters = useCallback(() => {
    fetchInteractions({ q: undefined, page: undefined });
    dispatch(setCurrentFilters(nullFilter));
  }, []);

  const {
    searchQuery,
    handleSearchChange,
    handleSearchEnterPress,
    handleSearchClear,
  } = useListSearch(fetchInteractions);

  useEffect(() => {
    fetchInteractions();
  }, []);

  useEffect(() => {
    if (isRefreshRequired) {
      fetchInteractions().then(() => {
        dispatch(setIsRefreshRequired(false));
      });
    }
  }, [isRefreshRequired]);

  useEffect(
    () => () => {
      dispatch(clearInteractions());
    },
    []
  );

  return {
    isLoading,
    interactions,
    meta,
    getPage,
    handleSearchChange,
    handleSearchEnterPress,
    handleSearchClear,
    searchQuery,
    fetchInteractions,
    clearAppliedFilters,
  };
}
