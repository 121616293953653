import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { IconButton } from '@mui/material';
import {
  rejectIncomingCall,
  hangUpCall,
  setActiveCallModalOpen,
  toggleTransferCall,
} from '../state/actions';
import { selectTwilioConnection } from '../state/selectors';
import styles from './RejectCall.module.scss';

const RejectCall = ({ iconSize }) => {
  const dispatch = useDispatch();
  const connection = useSelector(selectTwilioConnection);

  const rejectCall = () => {
    if (connection.status() === 'pending') {
      connection.reject(() => dispatch(rejectIncomingCall()));
      connection.reject();
      dispatch(setActiveCallModalOpen(false));
    } else {
      connection.disconnect((conn) => dispatch(hangUpCall(conn)));
      connection.disconnect();
      dispatch(toggleTransferCall(false));
    }
  };

  return (
    <Box className={styles.container}>
      <IconButton
        className={styles[`reject-call-${iconSize}`]}
        aria-label="Answer"
        onClick={rejectCall}
        size="large"
      >
        <PhoneOutlinedIcon className={styles.icon} />
      </IconButton>
    </Box>
  );
};

RejectCall.defaultProps = {
  iconSize: 'large',
};

RejectCall.propTypes = {
  iconSize: PropTypes.string,
};

export default RejectCall;
