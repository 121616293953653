import { useEffect } from 'react';

export default function useHelpscout(location) {
  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('event', {
        type: 'page-viewed',
        url: document.location.href,
        title: document.title,
      });
      window.Beacon('suggest');
    }
  }, [location]);
}
