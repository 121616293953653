import { nullMeta } from 'helpers/meta';
import {
  FETCH_INTERACTIONS_STARTED,
  STORE_INTERACTIONS,
  SET_PARAMS,
  SET_IS_REFRESH_REQUIRED,
  CLEAR_INTERACTIONS,
  SHOW_MODAL,
  HIDE_MODAL,
} from './constants';

const initialState = {
  isLoading: false,
  data: [],
  meta: nullMeta,
  isRefreshRequired: false,
  params: {
    page: 1,
    per_page: 10,
    sort: undefined,
    order: undefined,
    query: undefined, // param for search query
    q: undefined, // param for filters query
  },
  modal: {
    name: '',
    visible: false,
  },
};

const ACTION_HANDLERS = {
  [SHOW_MODAL]: (state, action) => ({
    ...state,
    modal: {
      name: action.modalName,
      visible: true,
    },
  }),
  [HIDE_MODAL]: (state) => ({
    ...state,
    modal: initialState.modal,
  }),
  [FETCH_INTERACTIONS_STARTED]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [STORE_INTERACTIONS]: (state, action) => ({
    ...state,
    isLoading: false,
    data: action.data,
    meta: {
      ...state.meta,
      ...action.meta,
    },
  }),
  [SET_PARAMS]: (state, action) => ({
    ...state,
    params: {
      ...state.params,
      ...action.data,
    },
  }),
  [SET_IS_REFRESH_REQUIRED]: (state, action) => ({
    ...state,
    isRefreshRequired: action.isRefreshRequired,
  }),
  [CLEAR_INTERACTIONS]: () => initialState,
};

export default function interactionsDataReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
