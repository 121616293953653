import MuiCheckbox from '@mui/material/Checkbox';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#5d43cf',
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
      backgroundColor: 'rgba(146, 88, 231, 0.08)',
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked:focus': {
      backgroundColor: 'rgba(146, 88, 231, 0.16)',
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked:active': {
      backgroundColor: 'rgba(146, 88, 231, 0.24)',
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked.Mui-disabled': {
      color: 'rgba(174, 161, 231, 0.48)',
    },
  },
});

export default function Checkbox(props) {
  const classes = useStyles();
  return (
    <MuiCheckbox
      classes={{ root: classes.root }}
      checkedIcon={<CheckBoxOutlinedIcon />}
      {...props}
    />
  );
}
