import { Device } from 'twilio-client';
import { useDispatch, useSelector } from 'react-redux';
import {
  setupTwilioDevice,
  startCall,
  setRinging,
  searchForContact,
  setTwilioCaller,
} from 'components/CallDialog/state/actions';
import { selectTwilioEnabled } from 'components/CallDialog/state/selectors';
import {
  removePhoneNumberFormatting,
  mapCallerFromContact,
  mapCallerFromTicket,
  mapCallerFromAccount,
} from 'helpers/twilio';

export default function useCall() {
  const dispatch = useDispatch();
  const twilioEnabled = useSelector(selectTwilioEnabled);

  const makeCall = (phoneNumber, resource) => {
    const params = { phoneNumber };
    if (resource?.type === 'Contact')
      dispatch(setTwilioCaller(mapCallerFromContact(resource.value)));
    if (resource?.type === 'Ticket') dispatch(setTwilioCaller(mapCallerFromTicket(resource.value)));
    if (resource?.type === 'Account')
      dispatch(setTwilioCaller(mapCallerFromAccount(resource.value)));

    const connection = Device.connect(params);

    dispatch(startCall(connection, params));

    connection.on('ringing', () => {
      dispatch(setRinging(true));
      dispatch(searchForContact(params.phoneNumber));
    });

    connection.on('accept', () => {
      dispatch(setRinging(false));
    });

    connection.on('reject', () => {
      dispatch(setRinging(false));
    });
  };

  function setupCall(phoneNumber, resource) {
    if (twilioEnabled) {
      makeCall(removePhoneNumberFormatting(phoneNumber), resource);
    } else {
      dispatch(setupTwilioDevice()).then(() => {
        setTimeout(() => {
          makeCall(removePhoneNumberFormatting(phoneNumber), resource);
        }, 1000);
      });
    }
  }

  return setupCall;
}
