export const STORE_ACCOUNTS = 'accounts: store_accounts';
export const FETCH_ACCOUNTS_STARTED = 'accounts: fetch_accounts_started';
export const UPDATE_ACCOUNT = 'accounts: update_account';
export const REMOVE_ACCOUNT = 'accounts: remove_account';
export const SET_PARAMS = 'accounts: set_params';
export const STORE_FILTER_PROPERTIES = 'accounts: store_filter_properties';
export const STORE_SAVED_FILTERS = 'accounts: store_saved_filters';
export const ADD_SAVED_FILTER = 'accounts: add_saved_filter';
export const REMOVE_SAVED_FILTER = 'accounts: remove_saved_filter';
export const UPDATE_SAVED_FILTER = 'accounts: update_saved_filter';
export const SET_CURRENT_FILTERS = 'accounts: set_current_filters';
export const RESET_CURRENT_FILTERS = 'accounts: reset_current_filters';
export const CREATE_IN_PROGRESS = 'accounts: create_in_progress';
export const CREATE_SUCCESS = 'accounts: create_success';
export const CREATE_FAILURE = 'accounts: create_failure';
export const ADD_ACCOUNT_TO_LIST = 'accounts: add_new_account_to_list';

export default {
  STORE_ACCOUNTS,
  UPDATE_ACCOUNT,
  REMOVE_ACCOUNT,
  STORE_FILTER_PROPERTIES,
  STORE_SAVED_FILTERS,
  ADD_SAVED_FILTER,
  REMOVE_SAVED_FILTER,
  UPDATE_SAVED_FILTER,
  SET_CURRENT_FILTERS,
  RESET_CURRENT_FILTERS,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  ADD_ACCOUNT_TO_LIST,
};
