import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/Modal/Modal';
import { getFullName } from 'helpers/contacts';
import ConversationChat from './ConversationChat';
// import Loader from 'ui/Loaders/BouncingDots';
import styles from './Conversation.module.scss';

function Conversation({
  visible,
  contact,
  getConversation,
  clearConversation,
  toggleModal,
  parentLoading,
}) {
  useEffect(() => {
    getConversation();

    return () => {
      clearConversation();
    };
  }, [visible]);

  if (!contact) return null;

  return (
    <Modal open={visible} onClose={toggleModal} PaperProps={{ className: styles.paper }}>
      <Modal.Header divider>{getFullName(contact)}</Modal.Header>
      <Modal.Body>
        {parentLoading && <div className={styles.loader}>Loading Chat ...</div>}
        {!parentLoading && <ConversationChat contact={contact} />}
      </Modal.Body>
    </Modal>
  );
}

Conversation.propTypes = {
  visible: PropTypes.bool.isRequired,
  contact: PropTypes.object.isRequired,
  getConversation: PropTypes.func.isRequired,
  parentLoading: PropTypes.bool.isRequired,
  clearConversation: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default Conversation;
