import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactS3Uploader from 'react-s3-uploader';
import MenuItem from '@mui/material/MenuItem';
import { Popper } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TextField from 'lib/TextField';
import useAttachmentUpload from 'components/AttachmentGridCell/useAttachmentUpload';
import AttachmentContext from 'components/AttachmentGridCell/AttachmentContext/AttachmentContext';
import useOnClickOutside from 'hooks/useOnClickOutside';
import styles from './AttachmentField.module.scss';

const AttachmentField = ({ title, onSubmit, value, autoFocus, name }) => {
  const [referenceNode, setReferenceNode] = useState(null);
  const { label, setLabel, fileUrls, setFileUrls, uploading, setEditMode } = useContext(
    AttachmentContext
  );

  const dropdownRef = useRef();

  const handleChange = (val) => {
    onSubmit(val);
  };

  useEffect(() => {
    setLabel(value?.label);
    setFileUrls(value?.value);
    return () => {
      setReferenceNode(null);
    };
  }, [value]);

  const clearState = () => {
    setLabel('');
    setFileUrls('');
  };

  const onBlur = (e) => {
    const labelIsChanged = label !== value?.label;
    const urlIsChanged = fileUrls !== value?.value;
    if (!label && urlIsChanged) {
      clearState();
      handleChange({ label: '', value: '' });
    }
    if (label && (labelIsChanged || urlIsChanged)) {
      handleChange({ value: fileUrls, label });
    }
    if (referenceNode && referenceNode.contains(e.target)) {
      setEditMode(true);
    } else {
      setEditMode(false);
      setReferenceNode(null);
    }
  };

  useOnClickOutside(dropdownRef, onBlur);

  const {
    progress,
    onUploadFinish,
    onUploadStart,
    handleRemoveAttachment,
    getSignedUrl,
    handleLabelChange,
    onUploadError,
    onUploadProgress,
    setUploader,
  } = useAttachmentUpload(dropdownRef);

  return (
    <div>
      <TextField
        onChange={handleLabelChange}
        value={label}
        autoFocus={autoFocus}
        fullWidth
        onFocus={(event) => setReferenceNode(event.currentTarget)}
        label={title}
        name={name}
      />
      {referenceNode && (
        <Popper
          open
          anchorEl={referenceNode}
          placement="bottom-start"
          style={{ zIndex: '11111111' }}
        >
          <div
            className={styles.dropdown}
            style={{ width: referenceNode?.getBoundingClientRect().width }}
            ref={dropdownRef}
          >
            <ReactS3Uploader
              ref={(node) => {
                setUploader(node);
              }}
              style={{ display: 'none' }}
              className="s3-uploader"
              id="file-input"
              getSignedUrl={getSignedUrl}
              preprocess={onUploadStart}
              onProgress={onUploadProgress}
              onError={onUploadError}
              onFinish={onUploadFinish}
              uploadRequestHeaders={{}}
            />
            <label htmlFor="file-input">
              <MenuItem className={styles.row}>
                <AddCircleOutlineIcon color="primary" />
                <Typography>Upload</Typography>
              </MenuItem>
            </label>
            <MenuItem
              onClick={(e) => handleRemoveAttachment(e)}
              className={styles.row}
              disabled={!fileUrls}
            >
              <HighlightOffIcon color="primary" />
              <Typography>Remove</Typography>
            </MenuItem>
            {uploading && (
              <div className={styles.progressBar}>
                <LinearProgress variant="determinate" value={progress} />
              </div>
            )}
          </div>
        </Popper>
      )}
    </div>
  );
};

export default AttachmentField;

AttachmentField.defaultProps = {
  autoFocus: false,
  name: '',
};

AttachmentField.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  autoFocus: PropTypes.bool,
  name: PropTypes.string,
};
