import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import '../styles/main.scss';
import '../i18n';
import App from '../App';

const ingoreEvents = [
  'Non-Error promise rejection captured with keys: message',
  'Non-Error promise rejection captured with keys: error, status',
  'Non-Error promise rejection captured with keys: config, data, headers, request, status',
  'ResizeObserver loop limit exceeded',
  'Invalid or unexpected token',
  'XSS is not defined',
  'This browser does not support audio output selection',
];

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    beforeSend(event, hint) {
      if (ingoreEvents.includes(event.exception?.values[0]?.value)) return null;
      if (hint.originalException?.name === 'ChunkLoadError') return null;
      if (hint.originalException?.message === "Unexpected token '<'") return null;
      if (hint.originalException?.message === 'ResizeObserver loop limit exceeded') return null;

      return event;
    },
  });
}

render(<App />, document.getElementById('app'));
