import makeStyles from '@mui/styles/makeStyles';

const colors = {
  grey: '#999999',
  purple: '#5D43CF',
  lightPurple: '#efecfa',
  lightPurple2: '#BEB4EC',
  white: '#FFFFFF',
  orange: '#F44336',
};

const sidebarItemActive = {
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  textDecoration: 'none',
  color: 'inherit',
  background: colors.lightPurple,
  cursor: 'pointer',
  outline: 'none',
  borderRadius: '4px 0 0 4px',
  '& $subheading': {
    color: colors.purple,
    fontSize: '16px',
  },
  '& $icon': {
    filter: `invert(40%) sepia(80%) saturate(6911%) hue-rotate(245deg) brightness(86%) contrast(87%)`,
  },
};

const sidebarItem = {
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  textDecoration: 'none',
  color: 'inherit',
  cursor: 'pointer',
  '&:hover': {
    color: 'inherit',
  },
};

const useStyles = makeStyles({
  sidebarItemActive: {
    ...sidebarItemActive,
  },
  sidebarItem: {
    ...sidebarItem,
  },
  subheading: {
    fontSize: '12px',
    paddingLeft: '19px',
  },
  icon: {
    color: colors.grey,
  },
  badge: {
    color: colors.white,
    backgroundColor: colors.orange,
    fontSize: '10px',
    fontWeight: 700,
    padding: '3.5px 5px',
    borderRadius: 4,
    marginRight: 8,
  },
  assistant: {
    display: 'flex',
    alignItems: 'center',
  },
  assistantItemActive: {
    ...sidebarItemActive,
    justifyContent: 'space-between',
  },
  assistantItem: {
    ...sidebarItem,
    justifyContent: 'space-between',
  },
});

export { useStyles };
