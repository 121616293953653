import { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import CallDialog from 'components/CallDialog';
import TwilioDevice from 'components/TwilioDevice';
import Conversation from 'components/Conversation';
import PushNotifications from 'components/PushNotifications';
import PageLoader from 'ui/Loaders/NewPageLoader';
import { useAppcues, useHelpscout } from 'hooks';
import AppNotifications from 'components/AppNotifications';
import TopBar from './TopBar';
import SideBar from './SideBar';
import Routes from './Routes';
import styles from './Main.module.scss';

function Main({
  location,
  userFetched,
  userShouldDoOnboarding,
  conversationVisible,
  twilioEnabled,
}) {
  useAppcues(location);
  useHelpscout(location);

  if (userFetched && userShouldDoOnboarding) {
    return <Redirect to="/onboarding" />;
  }

  return (
    <div className={styles['main-layout']}>
      {twilioEnabled && <TwilioDevice />}
      {twilioEnabled && <CallDialog />}
      {twilioEnabled && conversationVisible && <Conversation />}

      <PushNotifications />
      <AppNotifications />

      <TopBar />
      <SideBar />

      <Suspense fallback={<PageLoader />}>
        <div className={styles['content-container']}>
          <Routes />
        </div>
      </Suspense>
    </div>
  );
}

Main.propTypes = {
  location: PropTypes.object.isRequired,
  userFetched: PropTypes.bool.isRequired,
  userShouldDoOnboarding: PropTypes.bool.isRequired,
  conversationVisible: PropTypes.bool.isRequired,
  twilioEnabled: PropTypes.bool.isRequired,
};

export default Main;
