import { createSlice } from '@reduxjs/toolkit';
import { nullMeta } from 'helpers/meta';

const initialState = {
  assistantRules: [],
  assistantRulesMeta: nullMeta,
  assistantRulesLoading: true,
  selectedRule: {},
  resourceDetails: {},
};

export const assistantSlice = createSlice({
  name: 'assistant',
  initialState,
  reducers: {
    resetAssistantState: (state) => {
      return {
        ...initialState,
        assistantRules: state.assistantRules,
        assistantRulesLoading: state.assistantRulesLoading,
        assistantRulesMeta: state.assistantRulesMeta,
      };
    },
    clearAssistantRulesState: () => {
      return { ...initialState };
    },
    assistantRulesSuccess: (state, { payload }) => {
      return {
        ...state,
        assistantRules: payload.assistantRules,
        assistantRulesMeta: payload.meta,
        selectedRule: payload.selectedRule,
      };
    },
    setAssistantRulesLoading: (state) => {
      return { ...state, assistantRulesLoading: false };
    },
    setSelectedRule: (state, { payload }) => {
      return {
        ...state,
        selectedRule: payload,
      };
    },
    setResourceDetails: (state, { payload }) => {
      return {
        ...state,
        resourceDetails: payload,
      };
    },
    updateStateForAssistantRules: (state, { payload }) => {
      return {
        ...state,
        assistantRules: payload.assistantRules,
      };
    },
  },
});

export const {
  assistantRulesSuccess,
  resetAssistantState,
  clearAssistantRulesState,
  setSelectedTab,
  setAssistantRulesLoading,
  setSelectedRule,
  updateStateForAssistantRules,
  setResourceDetails,
} = assistantSlice.actions;

export default assistantSlice.reducer;
