import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import Icon from '@mui/icons-material/ErrorOutlineTwoTone';
import { ReactComponent as MissedCallIcon } from 'app/activities/ActivitiesDataGrid/missed-call.svg';
import { ReactComponent as InboundText } from 'app/activities/ActivitiesDataGrid/inbound-text.svg';
import { ReactComponent as ExternalNotifications } from 'images/alarm-bell.svg';
import { removePushNotification } from 'app/state/push-notifications/reducer';
import DetailsLinkButton from 'lib/DetailsLinkButton';
import Button from 'lib/Button';
import styles from './PushNotification.module.scss';

const notificationIcon = {
  missed_call: <MissedCallIcon />,
  inbound_text: <InboundText />,
  external_notification: <ExternalNotifications />,
};

const notificationSubject = {
  missed_call: 'Missed Call',
  inbound_text: 'Text Message',
};

const PushNotification = ({ notifications }) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const displayNotificationText = ({ type, text, uid, activityID, subject, link }) => {
    return (
      <div className={styles.container}>
        {notificationIcon[type]}
        <div className={styles['text-container']}>
          <div className={`text-truncate ${styles['main-text']}`}>{text}</div>
          <div className={`text-truncate ${styles['sub-text']}`}>
            {subject || notificationSubject[type]}
          </div>
        </div>
        {type === 'external_notification' && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              window.open(link, '_blank');
              dispatch(removePushNotification(uid));
              ref.current.removeNotification(uid);
            }}
            startIcon={<Icon />}
          >
            Details
          </Button>
        )}
        {type !== 'external_notification' && (
          <DetailsLinkButton
            variant="outlined"
            color="primary"
            to={`/activities?activityID=${activityID}&kind=${type}`}
            onClick={() => {
              dispatch(removePushNotification(uid));
              ref.current.removeNotification(uid);
            }}
          />
        )}
      </div>
    );
  };

  const drawNotifications = (data) => {
    data.forEach((n) => {
      ref.current.addNotification({
        level: 'success',
        onRemove: () => dispatch(removePushNotification(n.uid)),
        uid: n.uid,
        autoDismiss: 0,
        position: 'br',
        dismissible: 'button',
        children: displayNotificationText(n),
      });
    });
  };

  const getStyle = () => ({
    Containers: {
      DefaultStyle: {
        width: 542,
      },
      br: {
        right: '16px',
        bottom: '30px',
      },
    },
    NotificationItem: {
      DefaultStyle: {
        borderStyle: 'none',
        height: 71,
        fontSize: '14px',
        boxShadow: '0px 16px 16px rgba(0, 0, 0, 0.16)',
        borderRadius: 6,
        backgroundColor: '#FFFFFF',
      },
      success: {
        backgroundColor: '#FFFFFF',
        color: '#1E4620',
      },
    },
    Dismiss: {
      DefaultStyle: {
        fontSize: '26px',
        top: '28px',
        right: '20px',
      },
      success: {
        backgroundColor: '#ffffff',
        color: '#0D3C61',
      },
    },
  });

  useEffect(() => {
    if (notifications.length) {
      drawNotifications(notifications);
    }
  }, [notifications]);

  return <NotificationSystem ref={ref} style={getStyle()} />;
};

PushNotification.defaultProps = {
  notifications: [],
};

PushNotification.propTypes = {
  notifications: PropTypes.array,
};

export default PushNotification;
