import PropTypes from 'prop-types';
import cs from 'classnames';
import { convertNameToInitials } from 'helpers/utils';
import styles from './ChatHead.module.scss';

export default function ChatHead({ name, position, hidden }) {
  return (
    <div
      className={cs(styles['chat-head'], {
        [styles.left]: position === 'left',
        [styles.right]: position === 'right',
        [styles.hidden]: hidden,
      })}
    >
      {convertNameToInitials(name)}
    </div>
  );
}

ChatHead.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
};
