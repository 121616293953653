import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import SearchInput from 'lib/SearchInput';
import {
  displayAnimationForActiveCall,
  minimizeCallModal,
  setTwilioCaller,
  setVerticalAlignmentTop,
  toggleAddContactModal,
  toggleAddExistingContactModal,
} from 'components/CallDialog/state/actions';
import { selectTwilioCallers } from 'components/CallDialog/state/selectors';
import styles from './MultipleCallersMenu.module.scss';

export default function MultipleCallersMenu({ anchorEl, handleCloseMenu }) {
  const callers = useSelector(selectTwilioCallers);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCallers, setFilteredCallers] = useState(callers);
  const dispatch = useDispatch();

  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleOnClear = () => setSearchTerm('');

  const search = () => {
    setFilteredCallers(
      callers.filter((caller) => caller.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  };

  const minimizeWindowToTheTop = () => {
    dispatch(minimizeCallModal(true));
    dispatch(setVerticalAlignmentTop(true));
    dispatch(displayAnimationForActiveCall(false));
  };

  const handleSetCaller = (caller) => {
    dispatch(setTwilioCaller(caller));
    handleCloseMenu();
  };

  const createContact = () => {
    minimizeWindowToTheTop();
    dispatch(toggleAddContactModal(true));
  };

  const selectExistingContact = () => {
    minimizeWindowToTheTop();
    dispatch(toggleAddExistingContactModal(true));
  };

  useEffect(() => {
    if (callers) search();
  }, [searchTerm]);

  return (
    <Menu
      id="multiple-callers-menu"
      keepMounted
      variant="menu"
      open={Boolean(anchorEl)}
      tabIndex={0}
      onClose={handleCloseMenu}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          background: '#ffffff',
          boxShadow: '0px 12px 12px rgba(0, 0, 0, 0.08)',
          borderRadius: '8px',
        },
      }}
    >
      <Box className={styles['callers-menu']}>
        {callers?.length > 1 && (
          <>
            <div className={styles.search}>
              <SearchInput
                onKeyPress={() => {}}
                onChange={handleSearchChange}
                value={searchTerm}
                onClear={handleOnClear}
                data-cy="call-multiple-caller-search"
              />
            </div>
            {filteredCallers.map((caller, index) => {
              return (
                <MenuItem onClick={() => handleSetCaller(caller)} key={index}>
                  <Typography>{caller.name}</Typography>
                </MenuItem>
              );
            })}
            {filteredCallers.length === 0 && (
              <div className={styles.message}>No contacts found.</div>
            )}
            <Divider />
          </>
        )}
        <MenuItem className={styles['add-contact']} onClick={createContact}>
          <ListItemIcon>
            <PersonAddOutlinedIcon />
          </ListItemIcon>
          <Typography variant="body1">Create Contact</Typography>
        </MenuItem>
        <MenuItem className={styles['existing-contact']} onClick={selectExistingContact}>
          <ListItemIcon>
            <HowToRegOutlinedIcon />
          </ListItemIcon>
          <Typography variant="body1">Select Existing Contact</Typography>
        </MenuItem>
      </Box>
    </Menu>
  );
}

MultipleCallersMenu.propTypes = {
  anchorEl: PropTypes.object.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
};
