import { useEffect, Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Day from 'dayjs';
import Button from 'lib/Button';
import { selectShouldRefetchMissedCalls } from 'app/state/app-notifications/selectors';
import { hideNotificationsSlider } from 'app/state/app-notifications/actions';
import { getCounters } from 'state/counters/actions';
import { ReactComponent as Icon } from './missed-call-icon.svg';
import DateHeader from '../DateHeader';
import useMissedCalls from './useMissedCalls';
import styles from './MissedCalls.module.scss';

function MissedCalls() {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const {
    missedCalls,
    isLoading,
    isConfirming,
    meta,
    fetchMissedCalls,
    confirmMissedCall,
    confirmAllMissedCalls,
  } = useMissedCalls();
  const shouldRefetch = useSelector(selectShouldRefetchMissedCalls);

  const handleClick = (call) => () => {
    dispatch(hideNotificationsSlider());

    if (call.confirmed === false) {
      confirmMissedCall(call.id).then(() => {
        dispatch(getCounters());
      });
    }
  };

  const handleMarkAllAsSeen = () => {
    confirmAllMissedCalls()
      .then(() => {
        fetchMissedCalls({ page: 1 });
      })
      .then(() => {
        dispatch(getCounters());
      });
  };

  const handleLoadMore = () => {
    const { scrollTop, clientHeight, scrollHeight } = ref.current;
    const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

    if (scrolledToBottom && !isLoading && meta.nextPage) {
      fetchMissedCalls({ page: meta.nextPage });
    }
  };

  useEffect(() => {
    fetchMissedCalls({ page: 1 });
  }, [shouldRefetch]);

  return (
    <div className={styles.container}>
      <div className={styles.calls} ref={ref} onScroll={handleLoadMore}>
        {Object.keys(missedCalls).map((group, index) => (
          <Fragment key={index}>
            <DateHeader date={group} />
            {missedCalls[group].map((call) => (
              <Link
                to={`/activities?activityID=${call.id}&kind=missed_call`}
                key={call.id}
                className={styles.call}
                onClick={handleClick(call)}
              >
                <Icon />
                <span className={styles.time}>{new Day(call.interacted_at).format('hh:mm A')}</span>
                <div className={styles.info}>
                  {!call.confirmed && <div className={styles.new}>New</div>}
                  <div className={styles.displayName}>{call.owner.name}</div>
                  <div className={styles.subject}>{call.description}</div>
                </div>
              </Link>
            ))}
          </Fragment>
        ))}
        {isLoading && <div>Loading ...</div>}
      </div>
      <div className={styles.actions}>
        <Button
          color="primary"
          variant="contained"
          disabled={isConfirming}
          onClick={handleMarkAllAsSeen}
        >
          Mark all as seen
        </Button>
      </div>
    </div>
  );
}

export default MissedCalls;
