import EngineService from '../core/engine-service';

const ENDPOINT = '/tickets';

export async function getTickets(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export async function createTicket(payload) {
  return EngineService.post(ENDPOINT, { ticket: payload }).then((json) => json);
}

export function getTicket(ticketId) {
  return EngineService.get(`${ENDPOINT}/${ticketId}`).then((json) => json);
}
export function getTicketLastEmailInteraction(ticketId) {
  return EngineService.get(`${ENDPOINT}/${ticketId}/last_email_interaction`).then((json) => json);
}

export function getTicketsFilterProperties(params = {}) {
  return EngineService.get('/filters/tickets/columns', params).then((json) => json);
}

export function deleteTicket(ticketId) {
  return EngineService.delete(`${ENDPOINT}/${ticketId}`).then((json) => json);
}

export function updateTicket(ticketId, payload) {
  return EngineService.patch(`${ENDPOINT}/${ticketId}`, {
    ticket: payload,
  }).then((json) => json);
}
