import PropTypes from 'prop-types';
import cs from 'classnames';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
      position: 'relative',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      '&:hover fieldset': {
        borderColor: '#5D43CF',
      },
    },
  },
  loader: {
    position: 'absolute',
    bottom: 0,
    right: '40px',
    top: '50%',
    transform: 'translateY(-30%)',
  },
});

// TODO: Implement options filtering
// TODO: Implement clearValueOnOptionsChange for render_on fields
export default function Select({
  className,
  fullWidth,
  label,
  value,
  options,
  removable,
  loading,
  variant,
  required,
  ...props
}) {
  const classes = useStyles();

  return (
    <FormControl
      className={cs(classes.root, className)}
      variant={variant}
      fullWidth={fullWidth}
      required={required}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect value={value || ''} {...props}>
        {removable && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {options.length === 1 && !options[0].value && (
          <MenuItem value={value}>
            <em>{options[0].label}</em>
          </MenuItem>
        )}
        {options.length >= 1 &&
          options[0].value?.toString() &&
          options.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </MenuItem>
          ))}
      </MuiSelect>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress size={20} />
        </div>
      )}
    </FormControl>
  );
}

Select.defaultProps = {
  value: '',
  options: [],
  removable: true,
  fullWidth: true,
  className: '',
  loading: null,
  variant: 'filled',
  required: false,
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  removable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  required: PropTypes.bool,
};
