export const selectAlerts = (state) => state.alerts.data;
export const selectIsPopupVisible = (state) => state.alerts.isPopupVisible;
export const selectIsFetching = (state) => state.alerts.isFetching;
export const selectIsUpdating = (state) => state.alerts.isUpdating;
export const selectIsBatchUpdating = (state) => state.alerts.isBatchUpdating;
export const selectUpdatingID = (state) => state.alerts.updatingID;
export const selectNextPage = (state) => state.alerts.meta.next_page;

export default {
  selectAlerts,
  selectIsPopupVisible,
  selectIsFetching,
  selectIsUpdating,
  selectIsBatchUpdating,
  selectUpdatingID,
  selectNextPage,
};
