import makeStyles from '@mui/styles/makeStyles';

const colors = {
  grey: '#999999',
  purple: '#5D43CF',
  lightPurple: '#efecfa',
  lightPurple2: '#BEB4EC',
};

const useStyles = makeStyles({
  sidebarItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer',
    '&:hover': {
      color: 'inherit',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  subheading: {
    fontSize: '12px',
    paddingLeft: '19px',
  },
  icon: {
    width: '24px',
    height: '24px',
    color: colors.grey,
  },
});

export { useStyles };
