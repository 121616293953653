import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Minimize } from 'icons/close-fullscreen.svg';
import NewContactModalContainer from 'modals/NewContactModal';
import ActiveCallMinimized from 'components/CallDialog/ActiveCallMinimized';
import AddExistingResourceModal from 'modals/AddExistingResourceModal';
import Dialpad from 'components/CallDialog/Dialpad/Dialpad';
import {
  selectDisplayAnimationForActiveCall,
  selectDisplayDialpad,
  selectIsOutgoingCall,
  selectTwilioConnection,
} from 'components/CallDialog/state/selectors';
import TransferCall from 'components/CallDialog/TransferCall';
import { selectUser } from 'app/state/user/selectors';
import {
  hangUpCall,
  minimizeCallModal,
  resetTwilioState,
  setActiveCallModalOpen,
  setRecording,
  setVerticalAlignmentTop,
} from '../../state/actions';
import ActiveCallActions from './ActiveCallActions';
import CallerInfo from '../CallerInfo';
import useActiveCall from './useActiveCall';
import LogActivityForm from '../LogActivityForm';
import TransferCallButton from '../../TransferCallButton/TransferCallButton';
import styles from './ActiveCall.module.scss';

export default function ActiveCall() {
  const dispatch = useDispatch();
  const {
    selectExistingContact,
    hideAddExistingContactModal,
    addContact,
    hideAddContactModal,
    isMinimized,
    newContactModalVisible,
    addExistingContactModalVisible,
    phoneNumber,
    isTransferCallVisible,
  } = useActiveCall();

  const isDialpadDisplayed = useSelector(selectDisplayDialpad);
  const connection = useSelector(selectTwilioConnection);
  const displayAnimation = useSelector(selectDisplayAnimationForActiveCall);
  const isOutgoingCall = useSelector(selectIsOutgoingCall);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(setRecording(user.twilioProfile.recording));
  }, []);

  const handleMinimizeCallModal = () => {
    dispatch(minimizeCallModal(!isMinimized));
    dispatch(setVerticalAlignmentTop(false));
  };

  const closeActiveCallModal = () => {
    if (connection) {
      connection.disconnect((conn) => dispatch(hangUpCall(conn)));
      connection.disconnect();
    }
    dispatch(setActiveCallModalOpen(false));
    dispatch(resetTwilioState());
  };

  const handleDialpadKeyClick = (digits) => {
    connection.sendDigits(digits);
  };

  if (!isMinimized) {
    return (
      <Draggable zIndex={100} enableUserSelectHack={false}>
        <div className={displayAnimation ? styles['active-call-animation'] : styles['active-call']}>
          <div className={styles.info}>
            {!isOutgoingCall && <TransferCallButton />}
            <CallerInfo mainTextColor="orange" subTextColor="gray" fullInfo={!isDialpadDisplayed} />
            {isDialpadDisplayed ? (
              <Dialpad onKeyClick={handleDialpadKeyClick} />
            ) : (
              <ActiveCallActions />
            )}
          </div>
          <div className={styles.note}>
            <div className={styles['icons-container']}>
              <div
                className={styles.icon}
                role="button"
                tabIndex="0"
                onClick={handleMinimizeCallModal}
              >
                <Minimize />
              </div>
              <div
                className={styles.icon}
                role="button"
                tabIndex="0"
                onClick={closeActiveCallModal}
              >
                <CloseIcon />
              </div>
            </div>
            <LogActivityForm show={!isTransferCallVisible} />
            {isTransferCallVisible && <TransferCall />}
          </div>
        </div>
      </Draggable>
    );
  }
  return (
    <>
      <ActiveCallMinimized onClick={handleMinimizeCallModal} handleOnClose={closeActiveCallModal} />
      <NewContactModalContainer
        show={newContactModalVisible}
        onHide={hideAddContactModal}
        onSubmit={addContact}
        mobilePhone={phoneNumber}
      />
      <AddExistingResourceModal
        resource="Contact"
        show={addExistingContactModalVisible}
        onHide={hideAddExistingContactModal}
        onSubmit={selectExistingContact}
      />
    </>
  );
}
