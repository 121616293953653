import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchInput from 'lib/SearchInput';
import styles from './TransferCall.module.scss';
import TransferCallItem from './TransferCallItem';
import useTransferCall from './useTransferCall';

const TransferCall = () => {
  const {
    handleOnClick,
    handleSetSearchValue,
    handleClear,
    searchValue,
    filteredProfiles,
  } = useTransferCall();

  return (
    <Box className={styles.container}>
      <Box className={styles['sticky-container']}>
        <Grid container direction="row" alignItems="center" spacing={2} className={styles.heading}>
          <Grid item>
            <ArrowBackIcon className={styles['arrow-back']} onClick={handleOnClick} />
          </Grid>
          <Grid item>
            <Typography variant="h5">Transfer Call</Typography>
          </Grid>
        </Grid>
        <Box className={styles.search}>
          <SearchInput
            onKeyPress={() => {}}
            onChange={handleSetSearchValue}
            onClear={handleClear}
            value={searchValue}
            data-cy="call-transfer-search"
          />
        </Box>
      </Box>
      <Box className={styles.subheading}>
        <Typography variant="body1">Available</Typography>
      </Box>
      <TransferCallItem profiles={filteredProfiles.available} />
      {filteredProfiles.available.length === 0 && (
        <Box>
          <Typography variant="body1">No available profiles.</Typography>
        </Box>
      )}
      {filteredProfiles.unavailable.length > 0 && (
        <Box className={styles.subheading}>
          <Typography variant="body1">Unavailable</Typography>
        </Box>
      )}
      <TransferCallItem profiles={filteredProfiles.unavailable} />
    </Box>
  );
};

export default TransferCall;
