import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { selectCustomFields } from 'state/custom-fields/selectors';
import { selectDemoAddonEnabled } from 'state/user/selectors';
import { customFieldsToArray } from 'helpers/custom-fields';
import styles from './ListsMenu.module.scss';

export default function ListsMenu() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const pathnameMatchesLists = pathname.startsWith('/lists/');
  const [menuOpen, setMenuOpen] = useState(pathnameMatchesLists);
  const demoAddonEnabled = useSelector(selectDemoAddonEnabled);
  const entities = customFieldsToArray(useSelector(selectCustomFields)).filter(
    (entity) =>
      ![
        'Notification',
        'Activity',
        '.Team',
        'Reminder',
        'User',
        'Contact',
        'Ticket',
        'Opportunity',
        'Interaction',
        'Account',
      ].includes(entity.name) &&
      entity.schema_type === 'list' &&
      entity.visible_on_lists &&
      (!demoAddonEnabled || !entity.demo)
  );

  const toggleMenu = () => setMenuOpen((currentState) => !currentState);

  useEffect(() => {
    if (!pathnameMatchesLists) {
      setMenuOpen(false);
    }
  }, [pathname]);

  return (
    <>
      <div
        role="button"
        tabIndex="0"
        onClick={toggleMenu}
        className={classNames(styles.toggle, { [styles.open]: menuOpen })}
      >
        <ListAltIcon className={styles.icon} />
        <Typography className={styles.title}>{t(`localization.navigation.lists`)}</Typography>
      </div>
      {menuOpen && (
        <ul>
          {entities.map((entity) => (
            <li key={entity.id} className={styles.listItem}>
              <NavLink
                to={`/lists/${entity.id}`}
                className={styles.link}
                activeClassName={styles.activeLink}
              >
                {entity.name}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
