export function opportunityPath(opportunityId) {
  return `/opportunities/${opportunityId}`;
}

export function contactPath(contactId) {
  return `/contacts/${contactId}`;
}

export function companyPath(companyId) {
  return `/companies/${companyId}`;
}

export function targetListsPath() {
  return '/lists';
}

export function targetListPath(targetListId) {
  return `/lists/${targetListId}`;
}

export function targetListManagePath(targetListId) {
  return `/lists/${targetListId}/manage`;
}

export function campaignsPath() {
  return '/campaigns';
}

export function campaignPath(campaignId) {
  return `/campaigns/${campaignId}`;
}

export function campaignManagePath(campaignId) {
  return `/campaigns/${campaignId}/manage`;
}

export function ticketPath(ticketId) {
  return `/tickets/${ticketId}`;
}

export default {
  HOME: '/',
  OPPORTUNITIES: '/opportunities',
  OPPORTUNITIES_PRIORITY_VIEW: '/opportunities/priority',
  CONTACTS: '/contacts',
  COMPANIES: '/companies',
  TICKETS: '/tickets',
};
