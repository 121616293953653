export const CONVERSATION_PARENT_REQUEST_STARTED = 'CONVERSATION_PARENT_REQUEST_STARTED';
export const CONVERSATION_PARENT_REQUEST_SUCCESS = 'CONVERSATION_PARENT_REQUEST_SUCCESS';
export const CONVERSATION_REQUEST_STARTED = 'CONVERSATION_REQUEST_STARTED';
export const CONVERSATION_REQUEST_SUCCESS = 'CONVERSATION_REQUEST_SUCCESS';
export const CONVERSATION_ADD_MESSAGE = 'CONVERSATION_ADD_MESSAGE';
export const CONVERSATION_TOGGLE_MODAL = 'CONVERSATION_TOGGLE_MODAL';
export const CONVERSATION_CLEAR = 'CONVERSATION_CLEAR';
export const CONVERSATION_SET_CONTACT = 'CONVERSATION_SET_CONTACT';
export const CONVERSATION_MESSAGE_CREATE_STARTED = 'CONVERSATION_MESSAGE_CREATE_STARTED';
export const CONVERSATION_MESSAGE_CREATE_SUCCESS = 'CONVERSATION_MESSAGE_CREATE_SUCCESS';
export const CONVERSATION_STORE_UNREAD_MESSAGE = 'CONVERSATION_STORE_UNREAD_MESSAGE';
export const CONVERSATION_CLEAR_UNREAD_MESSAGES = 'CONVERSATION_CLEAR_UNREAD_MESSAGES';
