import {
  SHOW_NOTIFICATIONS_SLIDER,
  HIDE_NOTIFICATIONS_SLIDER,
  STORE_TEXT_NOTIFICATIONS,
  ADD_TEXT_NOTIFICATION,
  STORE_MISSED_CALLS_NOTIFICATIONS,
  SET_SHOULD_REFETCH_MISSED_CALLS,
  STORE_ALERT_NOTIFICATIONS,
  SET_SHOULD_REFETCH_ALERTS,
  ADD_UNREAD_NOTIFICATION,
} from './constants';

export const showNotificationsSlider = () => ({ type: SHOW_NOTIFICATIONS_SLIDER });
export const hideNotificationsSlider = () => ({ type: HIDE_NOTIFICATIONS_SLIDER });
export const storeTextNotifications = (texts) => ({
  type: STORE_TEXT_NOTIFICATIONS,
  payload: { texts },
});
export const addTextNotification = (text) => ({
  type: ADD_TEXT_NOTIFICATION,
  payload: { text },
});

export const storeMissedCallsNotifications = (missedCalls) => ({
  type: STORE_MISSED_CALLS_NOTIFICATIONS,
  payload: { missedCalls },
});

export const setShouldRefetchMissedCalls = () => ({
  type: SET_SHOULD_REFETCH_MISSED_CALLS,
});

export const storeAlertNotifications = (alerts) => ({
  type: STORE_ALERT_NOTIFICATIONS,
  payload: { alerts },
});

export const setShouldRefetchAlerts = () => ({
  type: SET_SHOULD_REFETCH_ALERTS,
});

export const addUnreadNotification = (notification) => ({
  type: ADD_UNREAD_NOTIFICATION,
  payload: { notification },
});
