import { lazy, Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import EngineService from './app/core/engine-service';
import store from './store';
import { createUserSession } from './app/state/session/actions';
import localStorage from './helpers/localStorage';
import Routes from './app/Routes';
import InvitationHandler from './app/layouts/InvitationHandler';
import Notifications from './app/components/Notifications';
import ErrorBoundary from './app/components/ErrorBoundary';
import { parseTokenResponseData } from './app/helpers/session';
import { getUser } from './app/state/user/actions';
import history from './history';
import retry from './app/helpers/retry';
import './styles/main.scss';

LicenseInfo.setLicenseKey(process.env.MATERIAL_UI_LICENCE_KEY);

const ModalManager = lazy(() =>
  retry(() => import(/* webpackChunkName: "ModalManager" */ './app/modals/ModalManager'))
);

const parsedQueryString = queryString.parse(history.location.search);
const urlContainsAccessToken = parsedQueryString.access_token;
const urlContainsJWT = parsedQueryString.t;
const sessionCookie = Cookies.get(process.env.APP_ID) || parsedQueryString.t;
const session = sessionCookie
  ? parseTokenResponseData(jwtDecode(sessionCookie))
  : localStorage.getItem('session');

EngineService.config({ store });

// sync redux store session with local storage session
if (session) {
  store.dispatch(createUserSession(session));

  if (!store.getState().user.fetched) {
    store.dispatch(getUser(session.userId));
  }
}

// sync redux store session with query string params
if (urlContainsAccessToken) {
  store.dispatch(createUserSession(parseTokenResponseData(parsedQueryString)));
  store.dispatch(getUser(parsedQueryString.user_id));
  history.push('/', { search: '' });
}

if (urlContainsJWT) {
  const decodedJWT = jwtDecode(parsedQueryString.t);

  Cookies.set(process.env.APP_ID, parsedQueryString.t, {
    domain: '.spiro.ai',
    path: '/',
    secure: true,
  });
  store.dispatch(createUserSession(parseTokenResponseData(decodedJWT)));
  store.dispatch(getUser(decodedJWT.user_id));
  history.push('/', { search: '' });
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#5D43CF',
    },
    icons: {
      main: '#999999',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#B9B9B9',
          minWidth: '160px',
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          color: '#B9B9B9',
          backgroundColor: '#B9B9B9',
          fontSize: '50px',
        },
      },
    },
  },
});

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DndProvider backend={Backend}>
          <Provider store={store}>
            <Router history={history}>
              <ErrorBoundary>
                <InvitationHandler>
                  <Notifications />
                  <Suspense fallback={<div />}>
                    <ModalManager />
                  </Suspense>
                  <Routes />
                </InvitationHandler>
              </ErrorBoundary>
            </Router>
          </Provider>
        </DndProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default hot(App);
