import {
  SET_DRIVE_ACCESS_TOKEN,
  SET_DRIVE_RESPONSE,
  SET_CSV_HEADERS,
  SET_ROW_COUNT,
  SET_RESOURCE_FIELDS,
  SET_DISABLED_FORM_SUBMIT,
  SET_LOADING,
  SET_SELECTED_FILE,
  SET_CAMPAIGN_VALUE,
  SET_OPPORTUNITY_VALUE,
  SET_CONTACT_RESOURCE,
  CLEAR_IMPORT_STATE,
  SET_CUSTOM_ENTITY,
  SET_SELECTED_ITEMS,
  SHOW_IMPORT_DELETE_MODAL,
  SET_PREVIOUS_PATH,
  SET_CURRENT_PATH,
  SET_MAPPED_VALUE,
  REQUEST_SUCCESS,
  SET_IMPORT_RESOURCE,
  RESET_IMPORT_DATA,
  RESET_SELECTED_ITEMS,
  SET_COMPONENT_LOADING,
  SET_META,
  SET_DOWNLOAD_LOADING,
  SET_IMPORTS_HISTORY,
  SET_LOAD_MORE_HISTORY,
  CLEAR_IMPORTS_HISTORY,
  SET_DISABLED_BROWSE,
  SET_UPLOAD_PROCESS,
  SET_DUPLICATES_DOWNLOAD_LOADING,
  SET_FILE_NAME,
} from './constants';
import { nullMeta } from '../../../helpers/meta';
import { parseMetaFromResponse } from '../../../components/CustomEntity/helpers';

const initialState = {
  driveAccessToken: null,
  driveResponse: null,
  selectedFile: null,
  csvHeaders: null,
  resourceFields: {},
  rowCount: null,
  disabledFormSubmit: true,
  loading: false,
  campaignValue: 0,
  opportunityValue: 0,
  contactResource: '',
  customEntity: {},
  selectedItems: [],
  showDeleteModal: false,
  previousPath: '',
  currentPath: '',
  params: {
    page: 1,
    per_page: 15,
    query: '',
  },
  data: [],
  meta: nullMeta,
  resource: '',
  componentLoader: false,
  downloadLoading: false,
  importsHistory: [],
  loadMoreHistory: false,
  disabledBrowse: false,
  uploadProcess: false,
  duplicatesDownloadLoading: false,
  fileName: '',
};

const setDriveAccessToken = (state, { payload }) => ({
  ...state,
  driveAccessToken: payload,
});

const setCsvHeaders = (state, { payload }) => ({
  ...state,
  csvHeaders: payload,
});

const setRowCount = (state, { payload }) => ({
  ...state,
  rowCount: payload,
});

const setResourceFields = (state, { payload }) => ({
  ...state,
  resourceFields: payload,
});

const setDisabledFormSubmit = (state, { payload }) => ({
  ...state,
  disabledFormSubmit: payload,
});

const setLoading = (state, { payload }) => ({ ...state, loading: payload });

const setDriveResponse = (state, { payload }) => ({
  ...state,
  driveResponse: payload,
});

const setSelectedFile = (state, { payload }) => ({
  ...state,
  selectedFile: payload,
});

const setCampaignValue = (state, { payload }) => ({
  ...state,
  campaignValue: payload,
});

const setOpportunityValue = (state, { payload }) => ({
  ...state,
  opportunityValue: payload,
});

const setContactResource = (state, { payload }) => ({
  ...state,
  contactResource: payload,
});

const clearImportState = () => ({
  ...initialState,
});

const setCustomEntity = (state, { payload }) => ({
  ...state,
  customEntity: payload,
});

const setSelectedItems = (state, { payload }) => ({
  ...state,
  selectedItems: payload,
});

const showImportDeleteModal = (state, { payload }) => ({
  ...state,
  showDeleteModal: payload,
});

const setPreviousPath = (state, { payload }) => ({
  ...state,
  previousPath: payload,
});

const setCurrentPath = (state, { payload }) => ({
  ...state,
  currentPath: payload,
});

const setMappedValue = (state, { payload: { key, value } }) => ({
  ...state,
  resourceFields: {
    ...state.resourceFields,
    [key]: {
      ...state.resourceFields[key],
      csv_header: value,
    },
  },
});

const requestSuccess = (state, action) => ({
  ...state,
  data: action.data,
  meta: action.meta,
});

const setImportResource = (state, action) => ({
  ...state,
  resource: action.resource,
});

const resetImportData = (state) => ({
  ...state,
  data: [],
});

const resetSelectedItems = (state) => ({
  ...state,
  selectedItems: [],
});

const setComponentLoader = (state, { payload }) => ({
  ...state,
  componentLoader: payload,
});

const setMeta = (state, { payload }) => ({
  ...state,
  meta: parseMetaFromResponse(payload),
});

const setDownloadLoading = (state, { payload }) => ({
  ...state,
  downloadLoading: payload,
});

const setDuplicatesDownloadLoading = (state, { payload }) => ({
  ...state,
  duplicatesDownloadLoading: payload,
});

const setImportsHistory = (state, { payload }) => ({
  ...state,
  importsHistory: payload,
});

const setLoadMoreHistory = (state, { payload }) => ({
  ...state,
  loadMoreHistory: payload,
});

const clearImportsHistory = (state) => ({
  ...state,
  importsHistory: initialState.importsHistory,
});

const setDisabledBrowse = (state, { payload }) => ({
  ...state,
  disabledBrowse: payload,
});

const setUploadProcess = (state, { payload }) => ({
  ...state,
  uploadProcess: payload,
});

const handleSetFileName = (state, { payload }) => ({
  ...state,
  fileName: payload,
});

const ACTION_HANDLERS = {
  [SET_DRIVE_ACCESS_TOKEN]: setDriveAccessToken,
  [SET_CSV_HEADERS]: setCsvHeaders,
  [SET_ROW_COUNT]: setRowCount,
  [SET_RESOURCE_FIELDS]: setResourceFields,
  [SET_DISABLED_FORM_SUBMIT]: setDisabledFormSubmit,
  [SET_LOADING]: setLoading,
  [SET_DRIVE_RESPONSE]: setDriveResponse,
  [SET_SELECTED_FILE]: setSelectedFile,
  [SET_CAMPAIGN_VALUE]: setCampaignValue,
  [SET_OPPORTUNITY_VALUE]: setOpportunityValue,
  [SET_CONTACT_RESOURCE]: setContactResource,
  [CLEAR_IMPORT_STATE]: clearImportState,
  [SET_CUSTOM_ENTITY]: setCustomEntity,
  [SET_SELECTED_ITEMS]: setSelectedItems,
  [SHOW_IMPORT_DELETE_MODAL]: showImportDeleteModal,
  [SET_PREVIOUS_PATH]: setPreviousPath,
  [SET_CURRENT_PATH]: setCurrentPath,
  [SET_MAPPED_VALUE]: setMappedValue,
  [REQUEST_SUCCESS]: requestSuccess,
  [SET_IMPORT_RESOURCE]: setImportResource,
  [RESET_IMPORT_DATA]: resetImportData,
  [RESET_SELECTED_ITEMS]: resetSelectedItems,
  [SET_COMPONENT_LOADING]: setComponentLoader,
  [SET_META]: setMeta,
  [SET_DOWNLOAD_LOADING]: setDownloadLoading,
  [SET_IMPORTS_HISTORY]: setImportsHistory,
  [SET_LOAD_MORE_HISTORY]: setLoadMoreHistory,
  [CLEAR_IMPORTS_HISTORY]: clearImportsHistory,
  [SET_DISABLED_BROWSE]: setDisabledBrowse,
  [SET_UPLOAD_PROCESS]: setUploadProcess,
  [SET_DUPLICATES_DOWNLOAD_LOADING]: setDuplicatesDownloadLoading,
  [SET_FILE_NAME]: handleSetFileName,
};

export default function importReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
