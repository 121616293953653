import { connect } from 'react-redux';
import TwilioDevice from '../components/TwilioDevice';
import twilioService from '../../../services/twilioService';
import {
  handleIncomingCall,
  handleIncomingCallCancel,
  hangUpCall,
  searchForContact,
} from '../../CallDialog/state/actions';
import history from '../../../../history';

function mapStateToProps(state) {
  return {
    caller: state.twilioCall.caller,
  };
}

const mapDispatchToProps = (dispatch) => ({
  handleIncomingCall: (connection, redirectPath) => {
    dispatch(handleIncomingCall(connection, redirectPath));
  },

  handleIncomingCallCancel: () => {
    dispatch(handleIncomingCallCancel());
  },

  hangUpCall: (connection) => {
    dispatch(hangUpCall(connection));
  },

  push: (path) => {
    history.push(path);
  },

  searchForContact: (phoneNumber) => {
    dispatch(searchForContact(phoneNumber));
  },

  createToken: (cb) => {
    twilioService.createToken().then((json) => {
      cb(json.token);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TwilioDevice);
