import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function SmartProductsOnly({ children, fallback }) {
  const userHasSmartProductsAsAddons = useSelector(
    (state) => !!state.user.addons.find((p) => p.name === 'Smart Quotes')
  );

  if (userHasSmartProductsAsAddons) return children;

  return fallback || null;
}

SmartProductsOnly.defaultProps = {
  children: null,
  fallback: null,
};

SmartProductsOnly.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.any,
};

export default SmartProductsOnly;
