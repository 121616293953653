import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import { fetchQuotes, updateQuote } from './actions';

const initialState = {
  fetching: false,
  data: [],
  isModalVisible: false,
  meta: nullMeta,
  params: {
    page: 1,
    per_page: 25,
    sort: undefined,
    order: undefined,
    query: undefined,
  },
};

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    setNewQuoteModalVisible: (state, { payload }) => {
      return { ...state, isModalVisible: payload };
    },
  },
  extraReducers: {
    [fetchQuotes.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [fetchQuotes.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: payload.quotes,
        meta: parseMetaFromResponse(payload.meta),
        params: payload.params,
        fetching: false,
      };
    },
    [fetchQuotes.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [updateQuote.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: state.data.map((quote) => {
          if (quote.id !== payload.id) return quote;
          return payload;
        }),
      };
    },
  },
});

export const { setNewQuoteModalVisible } = quotesSlice.actions;

export default quotesSlice.reducer;
