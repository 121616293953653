import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

const colors = {
  lightGray: '#999999',
  darkGray: '#666666',
  white: '#FFFFFF',
};

const useStyles = makeStyles(() => ({
  root: {
    background: colors.white,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 11px',
    height: 36,
    borderRadius: 4,
    border: '1px solid rgba(128, 128, 128, 0.24)',
    '& input::-webkit-search-cancel-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button, & input::-webkit-clear-button': {
      display: 'none',
    },
  },
  inputFocused: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
  },
  icon: {
    fill: colors.lightGray,
  },
  input: {
    color: colors.lightGray,
    fontSize: 16,
    fontFamily: 'Roboto',
    padding: '6px 0 7px',
    '&::placeholder': {
      opacity: 1,
    },
  },
  inputAdornment: {
    margin: 0,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
}));

const SearchInput = ({ onKeyPress, onChange, value, onClear, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TextField
      {...props}
      value={value}
      placeholder={t('localization.search.title')}
      classes={{ root: classes.textFieldRoot }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
          input: classes.input,
        },
        onChange,
        onKeyPress,
        startAdornment: (
          <InputAdornment className={classes.inputAdornment} position="start">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton className={classes.button} size="small" onClick={onClear}>
              <ClearIcon className={classes.icon} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchInput.defaultProps = {
  value: '',
};

SearchInput.propTypes = {
  onKeyPress: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default SearchInput;
