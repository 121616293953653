import { open } from '../../../../helpers/URLHelper';

export function isLinkActive(linkPathname, locationPathname) {
  if (linkPathname === '/lists') {
    return linkPathname === locationPathname;
  }

  if (locationPathname.includes('/assistant-rules')) return false;

  return locationPathname.startsWith(linkPathname);
}

export function isAssistantLink(displayFieldName) {
  return displayFieldName === 'assistant';
}

export function getPathname(name) {
  return (
    {
      opportunities: '/opportunities',
      contacts: '/contacts',
      companies: '/companies',
      activities: '/activities',
      campaigns: '/campaigns',
      lists: '/lists',
      reminders: '/reminders',
      tickets: '/tickets',
      assistant: '/assistant',
      quotes: '/quotes',
    }[name] || '/'
  );
}

export function openReportsUrl(zohoZuid, params) {
  if (zohoZuid) {
    open(`/reports/signin?email=${params.zohoEmail}`, '_blank');
  } else {
    open(`/reports/signup?email=${params.zohoEmail}`, '_blank');
  }
}
