const initialState = {
  initialized: false,
  msg: '',
  collapsed: false,
  sidebarMenuCollapsed: true,
  sidebarContentVisible: false,
  sidebarContent: 'RECOMMENDATIONS',
  sidebarContentVisiblePrevious: true,
  sidebarContentPrevious: 'RECOMMENDATIONS',
  twilioToken: '',
  assistantPath: '',
  dialpadVisible: false,
};

/**
 * Application state reducer function
 * @param  Object state  Initial state
 * @param  Object action Action object that was executed by Redux
 * @return Object        New state in redux tree
 */
export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case 'INITIALIZING':
      return {
        ...state,
        initialized: false,
      };
    case 'INITIALIZED':
      return {
        ...state,
        initialized: true,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        msg: action.msg,
      };
    case 'TOGGLE_NEW_OPTY_FORM':
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    case 'TOGGLE_SIDEBAR_MENU':
      return {
        ...state,
        sidebarMenuCollapsed: !state.sidebarMenuCollapsed,
      };
    case 'SHOW_SIDEBAR_CONTENT':
      return {
        ...state,
        sidebarContentVisiblePrevious: state.sidebarContentVisible,
        sidebarContentVisible: true,
      };
    case 'HIDE_SIDEBAR_CONTENT':
      return {
        ...state,
        sidebarContentVisiblePrevious: state.sidebarContentVisible,
        sidebarContentVisible: false,
      };
    case 'TOGGLE_SIDEBAR_CONTENT':
      return {
        ...state,
        sidebarContentVisiblePrevious: state.sidebarContentVisible,
        sidebarContentVisible: !state.sidebarContentVisible,
      };
    case 'PREVIOUS_SIDEBAR_CONTENT':
      return {
        ...state,
        sidebarContentVisible: state.sidebarContentVisiblePrevious,
        sidebarContent: state.sidebarContentPrevious,
      };
    case 'SET_SIDEBAR_CONTENT':
      return {
        ...state,
        sidebarContentPrevious: state.sidebarContent,
        sidebarContent: action.content,
      };
    case 'SET_TWILIO_TOKEN':
      return {
        ...state,
        twilioToken: action.token,
      };
    case 'TOGGLE_DIALPAD':
      return {
        ...state,
        dialpadVisible: !state.dialpadVisible,
      };
    case 'SET_ASSISTANT_PATH':
      return {
        ...state,
        assistantPath: action.path,
      };
    case 'SHOW_PAGE_LOADER':
      return {
        ...state,
        showPageLoader: true,
      };
    case 'HIDE_PAGE_LOADER':
      return {
        ...state,
        showPageLoader: false,
      };
    default:
      return state;
  }
}
