import MuiDrawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    // width: '600px',
  },
});

export default function Drawer(props) {
  const classes = useStyles();
  return <MuiDrawer classes={{ paper: classes.paper }} {...props} />;
}
