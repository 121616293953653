import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import { selectTwilioCaller } from 'components/CallDialog/state/selectors';
import CallActions from '../CallActions';
import CallerInfo from '../CallerInfo';
import CallMainActions from '../CallMainActions/CallMainActions';
import styles from './IncomingCallModal.module.scss';

const IncomingCallModal = () => {
  const caller = useSelector(selectTwilioCaller);

  return (
    <Draggable zIndex={100} enableUserSelectHack={false}>
      <div className={styles['incoming-call']}>
        <CallerInfo />
        <div className={styles.actions}>
          <CallMainActions />
          {caller && <CallActions />}
        </div>
      </div>
    </Draggable>
  );
};

export default IncomingCallModal;
