import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { convertNameToInitials } from 'helpers/utils';

import styles from './InCallContactDetails.module.scss';

const InCallContactDetails = ({ title, subheader, avatarImage }) => {
  const avatar = (
    <Avatar className={styles.avatar} src={avatarImage}>
      {convertNameToInitials(title)}
    </Avatar>
  );

  return (
    <div className={styles.container}>
      <Card elevation={0}>
        <CardHeader
          className={styles.header}
          avatar={avatar}
          title={title}
          subheader={subheader}
          titleTypographyProps={{ noWrap: true }}
          classes={{ content: styles.content }}
        />
      </Card>
    </div>
  );
};

InCallContactDetails.defaultProps = {
  title: '',
  subheader: '',
  avatarImage: null,
};

InCallContactDetails.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  avatarImage: PropTypes.node,
};

export default InCallContactDetails;
