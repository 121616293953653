import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPhoneInput from './ReactPhoneInput';
import './Phone.scss';

function Phone({
  className,
  autoFocus,
  defaultCountry,
  value,
  disabled,
  onBlur,
  onKeyPress,
  onChange,
  ref,
  name,
}) {
  return (
    <div className={className}>
      <ReactPhoneInput
        ref={ref}
        autoFocus={autoFocus}
        defaultCountry={defaultCountry}
        value={value}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
        onEnterKeyPress={onKeyPress}
        onChange={onChange}
      />
    </div>
  );
}

Phone.defaultProps = {
  className: 'spiro-phone-input',
  autoFocus: false,
  defaultCountry: 'us',
  disabled: false,
  onBlur: undefined,
  onKeyPress: () => {},
  onChange: () => {},
};

Phone.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  defaultCountry: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    defaultCountry: state.user.countryCode.toLowerCase(),
  };
}

export default connect(mapStateToProps)(Phone);
