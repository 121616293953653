import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import SmartProductsOnly from 'components/SmartProductsOnly';
import UnauthorizedAccess from '../UnauthorizedAccess';

function SmartProductsRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <SmartProductsOnly
          fallback={<UnauthorizedAccess message="You don't have permission to visit this page." />}
        >
          <Component {...props} />
        </SmartProductsOnly>
      )}
    />
  );
}

SmartProductsRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default SmartProductsRoute;
