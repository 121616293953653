import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
};

export const pushNotificationSlice = createSlice({
  name: 'push-notifications',
  initialState,
  reducers: {
    addNewPushNotification: (state, { payload }) => {
      return { ...state, notifications: [...state.notifications, payload] };
    },
    removePushNotification: (state, { payload }) => {
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.uid !== payload),
      };
    },
  },
});

export const { addNewPushNotification, removePushNotification } = pushNotificationSlice.actions;
export default pushNotificationSlice.reducer;
