import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BarChartIcon from '@mui/icons-material/BarChart';
import Typography from '@mui/material/Typography';
import { selectZohoEmail, selectZohoZuid } from 'state/user/selectors';
import { openReportsUrl } from '../helpers';
import { useStyles } from './useStyles';

function ReportsLink({ name, label }) {
  const classes = useStyles();
  const zohoZuid = useSelector(selectZohoZuid);
  const zohoEmail = useSelector(selectZohoEmail);

  function handleClick() {
    openReportsUrl(zohoZuid, { zohoEmail });
  }

  return (
    <div
      role="button"
      tabIndex="0"
      key={name}
      onClick={handleClick}
      className={classes.sidebarItem}
      data-cy="sidebar-menu-reports-button"
    >
      <BarChartIcon className={classes.icon} data-cy="sidebar-reports-icon" />
      <Typography className={classes.subheading} data-cy="sidebar-menu-reports-label">
        {label}
      </Typography>
    </div>
  );
}

ReportsLink.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ReportsLink;
