import { createSlice } from '@reduxjs/toolkit';
import { fetchResourceFields } from './actions';

const initialState = {
  form: {
    name: '',
    source_name: '',
    sync_to: '',
    sharing_emails: '',
    notification_emails: '',
    enabled: false,
    custom_field_id: null,
    mapping: {},
  },
  dataCollectorWizardStep: 0,
  stepError: null,
  selectedFile: null,
  headers: [],
  resourceFieldsKeys: [],
  resourceFieldsValues: [],
  createFlow: true,
};

export const dataCollectorSlice = createSlice({
  name: 'data-collector',
  initialState,
  reducers: {
    setDataCollectorForm: (state, { payload }) => {
      return { ...state, form: { ...state.form, ...payload } };
    },
    resetDataCollectorForm: () => {
      return { ...initialState };
    },
    setDataCollectorWizardStep: (state, { payload }) => {
      return { ...state, dataCollectorWizardStep: payload };
    },
    setStepError: (state, { payload }) => {
      return { ...state, stepError: payload };
    },
    setSelectedFile: (state, { payload }) => {
      return { ...state, selectedFile: payload };
    },
    setFileHeaders: (state, { payload }) => {
      return { ...state, headers: payload };
    },
    setMappedValue: (state, { payload: { key, value } }) => {
      return {
        ...state,
        form: {
          ...state.form,
          mapping: {
            ...state.form.mapping,
            [key]: { ...state.form.mapping[key], csv_header: value },
          },
        },
      };
    },
    setCreateFlow: (state, { payload }) => {
      return { ...state, createFlow: payload };
    },
  },
  extraReducers: {
    [fetchResourceFields.fulfilled]: (state, { payload }) => {
      const resourceFields = Object.values(payload).map((header) => {
        const headers = state.headers.find(
          (hh) => hh.toLowerCase().trim() === header.label.toLowerCase().trim()
        );
        return { ...header, csv_header: headers || '' };
      });

      let mappedValues = { ...payload };

      Object.entries(payload).forEach(([key, value]) => {
        state.headers.forEach((header) => {
          if (value.label.toLowerCase().trim() === header.toLowerCase().trim()) {
            mappedValues = {
              ...mappedValues,
              [key]: { ...mappedValues[key], csv_header: header },
            };
          }
        });
      });
      return {
        ...state,
        resourceFieldsKeys: Object.keys(payload),
        resourceFieldsValues: resourceFields,
        form: {
          ...state.form,
          mapping: mappedValues,
        },
      };
    },
  },
});

export const {
  setDataCollectorForm,
  resetDataCollectorForm,
  setDataCollectorWizardStep,
  setStepError,
  setSelectedFile,
  setFileHeaders,
  setMappedValue,
  setCreateFlow,
} = dataCollectorSlice.actions;

export default dataCollectorSlice.reducer;
