import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CallDurationTimer from 'components/CallDialog/CallDurationTimer';
import {
  selectIsRinging,
  selectPhoneNumber,
  selectTwilioCaller,
  selectTwilioCallers,
} from 'components/CallDialog/state/selectors';
import { companyPath, contactPath } from 'app/helpers/paths';
import MultipleCallersMenu from '../MultipleCallersMenu';
import useCallerInfo from '../useCallerInfo';
import styles from './CallerInfoText.module.scss';

export default function CallerInfoText({ fullInfo, mainTextColor, subTextColor }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isConnectionPending } = useCallerInfo();
  const phoneNumber = useSelector(selectPhoneNumber);
  const callers = useSelector(selectTwilioCallers);
  const caller = useSelector(selectTwilioCaller);
  const isRinging = useSelector(selectIsRinging);
  const ref = useRef();

  const handleOpenMenu = () => {
    if (!isConnectionPending()) {
      setAnchorEl(ref.current);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={fullInfo ? styles['call-info'] : styles.text}>
      <Box className={`${styles['main-text']} ${styles[`text-${mainTextColor}`]}`}>
        <Typography
          className={`${styles.name} ${anchorEl ? `${styles['name-background']}` : ''}`}
          ref={ref}
          aria-controls="multiple-callers-menu"
          aria-haspopup="true"
          noWrap
        >
          {callers.length === 0 && !caller && (
            <span className="text-truncate" onClick={handleOpenMenu} role="button" tabIndex="0">
              Unknown Caller
            </span>
          )}
          {callers.length > 1 && !caller && (
            <span className="text-truncate" onClick={handleOpenMenu} role="button" tabIndex="0">
              Multiple Contacts
            </span>
          )}
          {callers.length >= 1 && caller && (
            <span className="text-truncate">
              <Link
                to={
                  caller.resource_type === 'Contact'
                    ? contactPath(caller.resource_id)
                    : companyPath(caller.resource_id)
                }
              >
                {caller.name}
              </Link>
            </span>
          )}
          {!isConnectionPending() && <ExpandMoreIcon onClick={handleOpenMenu} />}
        </Typography>
        {anchorEl && <MultipleCallersMenu anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} />}
        {callers?.length >= 1 && fullInfo && (
          <Typography noWrap>{caller?.account_name || callers[0].account_name}</Typography>
        )}
      </Box>
      <Box className={`${styles['sub-text']} ${styles[`text-${subTextColor}`]}`}>
        {fullInfo && (
          <>
            {phoneNumber && (
              <Typography>
                {parsePhoneNumberFromString(phoneNumber)?.formatInternational()}
              </Typography>
            )}
            {isConnectionPending() && <Typography variant="caption">Incoming Call</Typography>}
            {!isConnectionPending() && !isRinging && <CallDurationTimer />}
            {isRinging && (
              <Typography variant="caption" className={styles.dialing}>
                Dialing
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </Typography>
            )}
          </>
        )}
        {!fullInfo && (
          <Box className={styles['not-full-info-sub-text']}>
            <CallDurationTimer />
          </Box>
        )}
      </Box>
    </Box>
  );
}

CallerInfoText.propTypes = {
  fullInfo: PropTypes.bool.isRequired,
  mainTextColor: PropTypes.string.isRequired,
  subTextColor: PropTypes.string.isRequired,
};
