import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContact } from 'services/contacts';
import { get as getAccount } from 'services/accounts';
import Contact from 'models/Contact';
import { selectToggleCallModal, selectTwilioCaller } from 'components/CallDialog/state/selectors';
import { setTwilioAccount, setTwilioContact } from './state/actions';
import MakeACallDialog from './MakeACallDialog';
import CallDialogModal from './CallDialogModal';

export default function CallDialog() {
  const isCallModalOpen = useSelector(selectToggleCallModal);
  const caller = useSelector(selectTwilioCaller);
  const dispatch = useDispatch();

  useEffect(async () => {
    if (caller) {
      if (caller.resource_type === 'Contact') {
        const res = await getContact(caller.resource_id);
        dispatch(setTwilioContact(new Contact(res.contact)));
        dispatch(setTwilioAccount(null));
      } else {
        const res = await getAccount(caller.resource_id);
        dispatch(setTwilioAccount(res.account));
        dispatch(setTwilioContact(null));
      }
    }
  }, [caller]);

  return (
    <div>
      {isCallModalOpen && <MakeACallDialog />}
      <CallDialogModal />
    </div>
  );
}
