import { createSlice } from '@reduxjs/toolkit';
import { fetchSupportDesks, handleCreateSupportDesk, handleDeleteSupportDesk } from './actions';

const initialState = {
  fetching: false,
  data: [],
  createInProgress: false,
  addNewSupportDeskModalVisible: false,
};

export const supportDeskSlice = createSlice({
  name: 'support-desks',
  initialState,
  reducers: {
    setAddNewSupportDeskModalVisible: (state, { payload }) => {
      return { ...state, addNewSupportDeskModalVisible: payload };
    },
    setSupportDesks: (state, { payload }) => {
      return { ...state, data: payload };
    },
  },
  extraReducers: {
    [fetchSupportDesks.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [fetchSupportDesks.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: payload.support_desks,
        fetching: false,
      };
    },
    [fetchSupportDesks.rejected]: (state) => {
      return { ...state, fetching: false };
    },
    [handleCreateSupportDesk.pending]: (state) => {
      return { ...state, isCreateInProgress: true };
    },
    [handleCreateSupportDesk.fulfilled]: (state) => {
      return { ...state, isCreateInProgress: false, addNewSupportDeskModalVisible: false };
    },
    [handleCreateSupportDesk.rejected]: (state) => {
      return { ...state, isCreateInProgress: false };
    },
    [handleDeleteSupportDesk.pending]: (state) => {
      return { ...state, fetching: true };
    },
    [handleDeleteSupportDesk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        data: payload.id,
        fetching: false,
      };
    },
    [handleDeleteSupportDesk.rejected]: (state) => {
      return { ...state, fetching: false };
    },
  },
});

export const { setAddNewSupportDeskModalVisible, setSupportDesks } = supportDeskSlice.actions;

export default supportDeskSlice.reducer;
